const ja = {
  translations: {
    // Route
    'route.home': 'ホームページ',
    'route.login': 'ログインする',
    'route.registration': '登録',
    'route.logout': 'ログアウト',

    'route.accountManagement': 'アカウント管理',
    'route.accountInvite': 'アカウント招待',
    'route.updateAccount': 'アカウント情報更新',
    'route.accountType': 'アカウントタイプ管理',
    'route.registerAccountType': 'アカウントタイプの登録',
    'route.changePassword': 'パスワード変更',
    'route.verify_mobile_account': 'モバイルアカウントを確認',

    'route.productManagement': '商品管理',
    'route.product': '商品管理',
    'route.product_detail': '商品詳細',
    'route.vehicles': '車両',
    'route.vehicle_detail': '車両詳細',
    'route.delivery': '配達',
    'route.delivery_detail': '商品',
    'route.delivery_add': '新規登録',
    'route.cancellation_fee': '情報マスター',
    'route.caution': '注意マスター',
    'route.price_sales_calendar': '販売カレンダー',
    'route.congestionStatus': '混雑状況マスタ',
    'route.congestionStatusDetail': '混雑状況ステータス編集',
    'route.cancellation_fee_add': '取消料登録',
    'route.cancellation_fee_update': '取消料変更',
    'route.cancellation_fee_detail': '取消料詳細',
    'route.information_fee_create': 'キャンセル料を作成',

    'route.service_pack_price': 'サービスパッケージの価格調整s',
    'route.business_plan': 'プラン情報',
    'route.business_partner': '紹介コード設定',
    'route.business_plan_create': '計画作成',
    'route.business_plan_details': 'プランの詳細',
    'route.business_plan_add_ticket': 'メンバーにチケットを追加',
    'route.ticket_distribution': 'チケット配布',
    'route.ticket_distribution_history': 'チケット配布履歴',
    'route.setting_fee_taxi': '料金タクシー情報の設定',
    'route.companyManagement': '会社管理',
    'route.companyManagement.information': '会社情報',
    'route.companyManagement.detail': '会社詳細',
    'route.companyManagement.add': '会社登録',
    'route.businessManagement': '事業者管理',
    'route.businessManagement.information': '事業者情報',
    'route.businessManagement.detail': '事業者詳細',
    'route.businessManagement.add': '事業者登録',
    'route.facilityManagement': '営業所・店舗情報',
    'route.facility.detail': '営業所・店舗詳細',
    'route.facility.add': '営業所/店舗登録',
    'route.driverManagement': 'ドライバー登録',
    'route.driver.detail': 'ドライバー詳細',
    'route.vehicleManagement': '車両登録',
    'route.vehicle_add': '車両登録',
    'route.driverVehicleAssociation': 'ドライバー＆車両紐づけ',
    'route.driverVehicleConfig': 'ドライバーと車両の設定',
    'route.driverVehicleConfigDetails': 'ドライバーと車両の設定更新',
    'route.driver_schedule.register': 'ドライバースケジュール登録',
    'route.imageMaster': '画像マスタ',
    'route.imageMasterDetail': '画像マスター詳細',
    'route.imageMasterAdd': '画像マスター追加',
    'route.partnerInformation': 'ショップサブスクリプション情報',
    'route.partnerDetails': 'パートナーの詳細',
    'route.partnerAdd': 'パートナー登録',
    'route.settingFeeOnDemandInformation': 'ジオフェンスにより金額設定情報',
    'route.settingFeeOnDemandDetails': 'ジオフェンスにより金額設定詳細',
    'route.settingFeeOnDemandRegistration': 'ジオフェンスにより金額設定作成',
    'route.waypointInformation': 'ウェイポイント情報',
    'route.waypointDetails': 'ウェイポイントの詳細',
    'route.waypointCreate': 'ウェイポイント登録',

    'route.memberManagement': '会員管理',
    'route.memberManagementCreate': 'メンバー管理作成',
    'route.updateMember': '会員情報更新',
    'route.memberFavoriteWaypoint': 'お気に入りのwaypoint',
    'route.memberManagementReferrer': '紹介一覧',
    'route.memberIdentityVerification': '身分証審査',

    'route.reservationManagement': '予約管理',
    'route.reservationManagementSearch': '予約検索',
    'route.reservationManagement_detail': '予約詳細',
    'route.busReservationSearch': 'バス予約管理',
    'route.busReservationDetail': 'バス予約詳細',
    'route.registerSubscription': 'サブスクリプション登録',
    'route.business_subscription': 'ユーザーサブスクリプション情報',
    'route.business_subscription_create': 'サブスクリプションの作成',
    'route.business_subscription_details': 'サブスクリプションの詳細',
    'route.qrPayment': 'QR支払いエラーの管理',
    'route.qrPaymentDetail': 'QR支払いエラーの詳細の管理',
    'route.jit_setting': 'JIT設定',
    'route.jit': 'JIT',
    'route.jit_routes': 'JITルート',
    'route.jit_route_detail': 'JITルート詳細',
    'route.jit_route_register': 'JITルートレジスタ',

    'route.deliverySetting': 'デリバリー時間設定',
    'route.deliverySettingInformation': 'デリバリー時間設定',

    'route.simulationManagement': 'シミュレーション管理',
    'route.simulationManagement_detail': 'シミュレーション詳細',
    'route.simulationManagement_create': 'シミュレーション作成',
    'route.simulationManagement_clone': 'シミュレーションクローン',
    'route.serviceGroupManagement': 'サービスグループ管理',
    'route.serviceGroupAdd': 'サービスグループ追加',
    'route.serviceGroupUpdate': 'サービスグループ更新',
    'route.checking_booking_management': '予約管理',
    'route.checking_booking_detail': '予約の詳細',

    'route.commonSetting': '共通設定',
    'route.bankManagement': '銀行管理',
    'route.mailManagement': 'メールアドレスの設定',
    'route.mailSettingAddress': 'メールアドレスの設定',
    'route.bankAdd': '銀行追加',
    'route.bankUpdate': '銀行更新',
    'route.recommendCreate': 'ポップアップ推奨作成',
    'route.recommendDetails': 'ポップアップ推奨の詳細',
    'route.recommendInformation': 'ポップアップ推奨情報',
    'route.recommend': 'ポップアッ',
    'route.shuttleManagement': 'シャトル管理',
    'route.menuAppManagement': 'メニューアプリ管理',
    'route.menuAppDetail': 'メニューアプリ詳細',
    'route.newsManagement': 'ニュース管理',
    'route.newsDetail': 'ニュース詳細',
    'route.createNews': 'ニュース追加',
    'route.templateSettingSearch': 'テンプレート設定',
    'route.templateSettingCreate': 'テンプレート登録',
    'route.templateSettingDetail': 'テンプレート詳細',
    'route.areaGeofenceManagement': 'ジオフェンスエリア管理',
    'route.subCost': 'サブコスト管理',
    'route.csv_import': 'CSVインポート',
    'route.buyTrainTicket': 'QR乗車券購入',
    'route.register_railway_ticket': '鉄道切符を登録する',
    'route.qrTicket': 'ユーザー鉄道チケット情報',
    'route.gps_tracking': 'GPS追跡',
    'route.driverTrackingGPS': 'ドライバー追跡GPS',
    'route.driverTrackingHistory': 'ドライバー追跡履歴',
    'route.couponManagement': 'クーポン管理',
    'route.coupon': 'クーポン情報',

    'route.autoBusBookingManagement': '自動運転車両管理',
    'route.autoBusBookingRegister': '自動運転車両登録',


    // Button common
    'common.btnResend': '再送信',
    'common.btnReset': 'リセット',
    'common.btnAgree': 'はい',
    'common.btnAdd': '追加',
    'common.btnUpdate': '更新する',
    'common.btnUpdateMemo': 'メモを更新する',
    'common.btnDelete': '削除',
    'common.btnSend': '送信',
    'common.btnLogin': 'ログイン',
    'common.btnLogout': 'ログアウト',
    'common.btnRegister': '登録',
    'common.btnReturn': '戻る',
    'common.btnConfirm': '確認',
    'common.btnCancel': 'キャンセル',
    'common.btnResetSearch': '検索条件リセット',
    'common.btnSearch': '検索',
    'common.btnReopen.Stop': '停止／再開',
    'common.btnUploadCsv': 'CSV出カ',
    'common.btnDetail': '詳細',
    'common.btnEdit': '変更',
    'common.btnClose': '閉じる',
    'common.btnBulkRegistration': '一括登録',
    'common.noData': '検索結果はありません',
    'common.btnSelect': '選択',
    'common.btnUpload': 'アップロード',
    'common.btnUnsubscribe': '退会',
    'common.btnManage': '管理する',
    'common.btnYes': 'はい',
    'common.btnNo': 'いいえ',
    'common.btnOk': 'はい',
    'common.btnApply': '適用する',
    'common.btnSetting': '設定',
    'common.btnSync': '同期',
    'common.btnRemove': '削除する',
    'common.btnClear': '消去',
    'common.all': 'すべて',
    'common.btnClone': 'クローン',
    'common.btnHistory': '歴史',
    'common.btnCopy': 'コピー',
    'common.linkMap': 'Googleマップをリンクする',
    'common.btnReSearch': '再検索',
    'common.action': 'アクション',
    'common.noResult': '結果なし·',
    'common.true': '有効',
    'common.false': '無効',
    'common.historyPayment': '履歴支払い',
    'common.chooseFile': 'ファイルを選択',
    'common.noFilesSelected': '書択されていません',
    'common.reload': 'リロード',
    'common.next': '次のページ',
    'common.trackingGps': 'GPSの追跡',
    'common.trackingHistory': '追跡履歴',
    'common.btnRetry': 'リトライ',
    'common.btnNow': '現在時刻',
    'common.btnMemberInfor': '会員情報',
    'common.btnDoNotSend': '送信しない',

    // Common field
    'common.enable': '有効',
    'common.disable': '無効',
    'common.email': 'メール',
    'common.password': 'パスワード',
    'common.phoneNumber': '電話番号',
    'common.searchResult': '検索結果',
    'common.case': '件',
    'common.address': '住所',
    'common.location': '位置',
    'common.photo': '写真',
    'common.name': '名称',
    'common.geofence': 'ジオフェンス',
    'common.area': 'エリア',
    'common.country': '国',
    'common.paymentMethod': '支払方法',
    'common.paymentStatus': '入金ステータス',
    'common.id': 'ID',
    'common.from': '(から)',
    'common.to': '(まで)',
    'common.minute': '分',
    'common.hour': '時間',
    'common.second': '秒',
    'common.user': 'ユーザ',
    'common.admin': '管理者',
    'common.status': '状態',
    'common.stt': 'プランID',
    'common.planType': 'プランタイプ',
    'common.no': '番号',
    'common.success': '成功',
    'common.fail': '失敗',
    'common.route': 'ルート',
    'common.active': 'Active',
    'common.inactive': 'Inactive',
    'common.none': 'None',
    'common.description': '詳細',
    'common.cancellationDateTime': '解約日時',
    'common.district': '都道府県',
    'common.province': '市区町村',
    'common.showInList': '一覧に表示',
    'common.notShowInList': '一覧に表示しない',
    'common.associated': '紐づけ済',
    'common.unassociated': '未紐づけ',

    'common.ja': '日本語',
    'common.en': '英語',
    'common.vi': 'ベトナム語',

    // common validation
    'validation.duplicate': '3通に1通のメールを複製することはできません',
    'validation.required': '{{field}}を入力してください。',
    'validation.email': '入力されたメールの形式が正しくありません',
    'validation.required.choose': '{{field}}を選択してください',
    'validation.invalid.field': '無効な{{field}}',
    'validation.invalid.time': '無効な日付です',
    'validation.invalid.timeRange': '無効な時間範囲',
    'validation.time.more': '無効な日付です',
    'validation.phone': '電話番号の形式が不正です',
    'validation.phone.max': '電話番号は10桁から15桁で入力してください',
    'validation.max': '最大は{{value}}です',
    'validation.maxString': '最大は{{value}}文字です',
    'validation.number': '数字で入力してください',
    'validation.number.positive': '正の数を入力してください',
    'validation.invalid.tejimai.htw': '無効な時間',
    'validation.date_time_range': '{{start}}は{{end}}未満である必要があります',
    'validation.required.select_array': '1 {{element}}以上を指定してください',
    'validation.time_equal_greater': '{{field1}}は{{field2}}以上である必要があります',
    'validation.existed.field': '{{field}}が存在します',
    'validation.between': '{{field}} に{{min}}以上で{{max}}以下の値を入力してください',
    'validation.max.label': '({{value}}文字まで)',
    'validation.limit.password': 'パスワードは８桁から３２桁で入力してください',
    'validation.space.not_allowed': 'スペースは許可されていません',
    'validation.invalid.value': '{{value}}が無効な{{field}}',
    'validation.creditCardNotExist': 'メンバーはクレジットカードを追加していません',
    'validation.required.update': '{{field}}更新してください',
    'validation.required.select_at_least_one': '1{{field}}以上を指定してください',
    'validation.latitude': '緯度の形式が無効です',
    'validation.longitude': '経度の形式が無効です',
    'validation.range': '待機時間は{{min}}秒から{{max}}秒までです。',
    'validation.base64_first_content': '画像を追加するには入力欄上部の画像アイコンをクリックしてURLを入力してください。',
    'validation.base64_second_content': '画像マスタに追加した画像のURLは「写真」ボタンをクリックして確認してください。',

    // placeholder
    'placeholder.required': '{{field}}を入力してください',
    'placeholder.required_select': '{{field}}を選択してください',
    'placeholder.language': '日本語/英語/ベトナム語',

    // Country
    'VN': 'ベトナム',
    'JP': '日本',
    'SG': 'シンガポール',
    'PHI': 'フィリピン人',
    'TW': '台湾',
    'MY': 'Malaysia',
    'GU': 'グアム',
    'US': 'ハワイ',
    'ID': 'バリ',

    // Common message code
    'messageCode.createConfirmPlan': 'プランを登録します。よろしいですか。',
    'messageCode.updateConfirmPlan': 'プランを更新します。よろしいですか。',
    'messageCode.common.updateConfirm': '更新しますか？',
    'messageCode.updateConfirm': 'この{{field}}を更新しますか？',
    'messageCode.deleteConfirm': 'この{{field}}を削除しますか？',
    'messageCode.changePasswordConfirm': 'パスワードを更新します。よろしいですか？',
    'messageCode.accountInviteConfirm': 'メールを送信します。よろしいですか？',
    'messageCode.updateAccountType': 'アカウントの種類を変更します。\r\n nよろしいですか?',
    'messageCode.deleteConfirmProduct': 'この商品を削除します。よろしいですか.',
    'messageCode.deleteConfirmSupplier': ' 選択した事業者を削除します。\r\nよろしいですか?',
    'messageCode.deleteConfirmDriver': 'ドライバーを削除しますか?',
    'messageCode.deleteConfirmImageMaster': 'イメージマスターを削除しますか？',
    'messageCode.updateConfirmDriver': 'ドライバーの情報を変更します。\r\nよろしいですか。',
    'messageCode.createConfirmDriver': 'ドライバー情報の登録が完了しました。以下メールアドレスにドライバーコードを送信します \r\n {{email}}',
    'messageCode.updateConfirmVehicle': '車両の情報を変更します。よろしいですか。',
    'messageCode.createConfirmVehicle': '車両を登録します。よろしいですか。',
    'messageCode.deleteConfirmVehicle': '車両を削除しますか。',
    'messageCode.isCheckNotDriverDelete': 'このmobiの運行中の為 削除できません',
    'messageCode.isCheckNotVehicleDelete': 'この車両の運行中の為削除できません',
    'messageCode.updateConfirmDelivery': '登録しますか？',
    'messageCode.updateConfirmDeliveryTime': 'デリバリー時間設定を更新しますか？',
    'messageCode.verifyEmailToken': 'ご登録いただいたメールアドレスの認証に成功しました',
    'messageCode.confirmSendEmail': 'ドライバー情報の登録が完了しました。\r\nドライバーコードは{{field}}に送信されます。',
    'messageCode.direct.saleCalendar': '配達を作成しました!\r\nリダイレクトページ販売カレンダーが必要な場合',
    'messageCode.selectNote': '注意内容を選択してください',
    'messageCode.exportAlert': 'ダウンロード件数が1000件以上です。ダウンロードに時間がかかりますが、よろしいですか？',
    'messageCode.exportAlertLimit': '検索結果が多すぎます。検索条件を絞り込んで再検索してください。',
    'messageCode.deleteConfirmShuttle': 'シャトルを削除しますか？',
    'messageCode.cantTransferAndNoneCreditCard': 'このユーザーはクレジットカードを持っておらず、このプランは送金で支払うことはできません',
    'messageCode.updateMenuApp': 'あなたがメニューのアプリケーションを更新しますか？',
    'messageCode.deleteConfirmCompany': '選択した会社を削除します。\r\n大丈夫ですか？',
    'messageCode.complete_reservation': 'この予約を終了しますか？',
    'messageCode.can_not_complete_reservation': 'この予約を終了できません。',
    'messageCode.confirm_delete_credit_card': 'このクレジットカードを削除してもよろしいですか？',
    'messageCode.confirm_delete_news_template': 'このテンプレートを削除します。大丈夫ですか？',
    'wrong.num.of.passenger': '座席数を超える予約です。',
    'messageCode.confirmToChange': '本当に変更しますか？',
    'messageCode.createConfirmAutoBusBooking': '自動運転車両を登録します。よろしですか？',
    'messageCode.confirmDeleteAutoBusBooking': '自動運転車両を削除しますか？',

    // Common error field message
    'errorFields.invalidDateMessage': '無効な日付形式',
    'errorFields.maxDateMessage': '日付は最大日付の後にしないでください',
    'errorFields.minDateMessage': '日付は最小日付より前であってはなりません',
    'errorFields.passwordRequired': 'パスワードを入力してください。',
    'errorFields.emailRequired': 'メールを入力してください。',
    'errorFields.emailFormat': '入力されたメールの形式が正しくありません',
    'errorFields.textLength': '{{value}}文字以内で入力してください',
    'errorFields.cannotChange': '変更不可',
    'errorFields.birthdayRequired': '誕生日を入力して下さい。',
    'errorFields.accountNameRequired': 'アカウント名を入力してください。',
    'errorFields.rePasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.phoneNumberRequired': '電話番号を入力してください。',
    'errorFields.confirmPasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.oldPasswordRequired': '現在のパスワードを入力してください。',
    'errorFields.newPasswordRequired': '新パスワードを入力してください。',
    'errorFields.passwordMin': '小文字を含む6文字以上で入力してください。',
    'errorFields.confirmPasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.personChargeRequired': '担当者名を入力してください。',
    'errorFields.firstNameRequired': '姓を入力してください。',
    'errorFields.lastNameRequired': '名を入力してください。',
    'errorFields.firstNameRequired_furigana': 'せいを入力してください。',
    'errorFields.lastNameRequired_furigana': 'めいを入力してください。',
    'errorFields.addressRequired': '住所を入力してください。',
    'errorFields.rePasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.businessRequired': '会社名を入力してください。',
    'errorFields.mobileRequired': '電話番号を入力してください',
    'errorFields.checkPhoneNumberMatch': '電話番号が一致しません。もう一度やり直してください。',
    'errorFields.sizeFile': '画像サイズは200KBより大きく、500KBを超えてはなりません。',
    'errorFields.csvFile': 'CSVファイルを選択してください',

    // Common constant
    'paymentMethod.CREDIT_CARD': 'クレカ',
    'paymentMethod.CASH': '現地決済',
    'paymentMethod.TRANSFER': '振込',
    'paymentMethod.COMPLETE_WITHOUT_PAYMENT': '未決済で完了',
    'paymentMethod.simulation.COMPLETE_WITHOUT_PAYMENT': '支払いなしで完了',
    'paymentMethod.OTHER': 'その他',
    'currencyCode.VND': 'VND',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': 'JPY',
    'currencyCode.SGD': 'SGD',
    'currencyCode.MYR': 'MYR',

    // Message error call API
    'error.vehicle.config.over.lapse.time': '指定する運行時間が既に紐づけされています。',
    'error.time.must.be.in.future': '車両紐づけの設定可能は明日以降です。',
    'error.file.size.less.than.200KB': '200kb以上のファイルサイズ',
    'error.file.size.is.empty': 'ファイルを空白のままにすることはできません',
    'error.no.record': '記録なし',
    'error.already.exist.or.has.been.invited': 'メールはすでに存在するか、招待されています。',
    'error.account.user.not.found': 'アカウントユーザーが見つかりません。',
    'error.business.not.found': 'ビジネスが見つかりません。',
    'error.role.not.found': '役割が見つかりません。',
    'error.permission.not.found': '権限が見つかりません。',
    'error.api.role.permission.unknown': 'API役割の権限は不明です。',
    'error.api.token.not.match': 'APIトークンが一致しません。',
    'error.link.not.found': 'リンクが見つかりません。',
    'error.user.not.resumed': 'アカウントユーザーは再開されていません。',
    'error.link.expired': 'リンクの有効期限が切れています。',
    'error.email.not.found': 'メールが見つかりません。',
    'error.email.password.invalid': 'パスワードが無効です。',
    'error.change.password': 'このパスワードは間違っています。',
    'error.newPassword.must.not.equals.currentPassword': '現在のパスワードと新しいパスワードは一致してはなりません。',
    'error.not.found.user': 'ユーザーが見つかりません。',
    'error.user.not.exists': 'ユーザーが存在しません',
    'error.user.not.exist': 'ユーザーが存在しません',
    'error.current.password.wrong': 'このパスワードは間違っています。',
    'error.email.exists': 'メールが存在しません',
    'error.email.exist': 'メールが存在しました',
    'error.mobile.exists': '携帯は存在しません',
    'error.mobile.exist': '電話番号は存在しています',
    'error.reissue.password.token_expired': 'トークンはすでに存在します',
    'error.reissue.password.token_not_match': 'トークンの形式が正しくありません',
    'error.member.not.exists': 'Member does not exist',
    'error.time.from.not.after.time.to': '無効な日付です',
    'error.check.value.empty': '入力する値を確認してください',
    'error.500': '内部サーバーエラー',
    'error.401': '許可しない',
    'error.403': '禁止: このリソースにアクセスする権限がありません。',
    'error.common': 'エラーが発生しました。システム管理者に連絡してください。',
    'error.waypoint.not.exist': 'ウェイポイントは存在しません',
    'error.simulation.one.time.already.exists': 'このジオフェンスのワンタイム金額は既に存在しています',
    'error.csv.export': 'CSVをダウンロードできません。再試行してください。',
    'error.timeout': '処理が途中でタイムアウトになりました。システム管理者に処理の実施状況を確認してください。',
    'invalid.email': '無効なメール',
    'invalid.timeTo': '無効な時間',
    'invalid.timeFrom': 'からの無効な時間',
    'update.reservation.request.invalid': '更新された予約リクエストは無効です',
    'update.reservation.request.invalid.to.complete.operator': '選択されたステータスに変更できません。\n選択できるステータスは「オペレーターキャンセル」',
    'update.reservation.request.invalid.to.complete.or.operator': '選択されたステータスに変更できません。\n選択できるステータスは「オペレーターキャンセルまたは完了」',
    'status.reservation.request.not.support': 'ステータス予約リクエストはサポートされていません',
    'ski.times.is.not.valid': '過去の時刻を更新していますので、開始時刻と終了時刻をもう一度確認してください',
    'time.from.must.before.time.to': 'マストビフォアタイムからタイム',
    'vehicle.shift.not.exist': '指定した時期はJIT運行しない日付が含まれる。再指定してくさい。',
    'api.mapping.driver.vehicle.success': '車両紐づけを成功しました。',
    'error.arrival.time.before.not.less.interval.time': '定期到着便の手じまい時間は定期到着の時間間隔より大きい値が必要です。',
    'group.type.is.incorrect': '車種が正しくない',
    'user.email.token.invalid': 'メールトーケン認証が失敗しました',
    'plan.existed': '既に同じプランを購入済みです',
    'create.partner.waypoint.failed': 'パートナーウェイポイントの作成に失敗しました',
    'api.supplier.not.found': 'サプライヤーが見つかりません。',
    'api.facility.not.found': 'API機能が見つかりません',
    'service.already.exist': 'サービスはすでに存在しています ',
    'service.not.exist': 'サービスが存在しません',
    'cannot.update.service': 'サービスを更新できません',
    'transfer.status.cannot.be.changed': '転送ステータスは変更できません',
    'subscription.not.exists': 'サブスクリプションが存在しません',
    'bank.account.not.exists': '銀行口座が存在しません',
    'bank.account.exists': 'この銀行口座は存在しています',
    'recommend.shuttle.not.exist': 'シャトルの推奨は存在しません',
    'partner.waypoint.exists': 'パートナーウェイポイントが存在します',
    'plan.not.support': 'サポートしない計画',
    'geofence.id.already.exist': 'ジオフェンスIDはすでに存在しま',
    'qr.payment.error.not.found': 'エラーQR支払いが見つかりませんでした',
    'partner.waypoint.existed': 'このパートナーウェイポイントが存在しました',
    'subscription.payment.not.exists': 'サブスクリプションの支払いは存在しません',
    'mobile.function.not.exists': 'モバイル機能は存在しません',
    'user.family.not.exists': 'ユーザーファミリーが存在しません',
    'menu.app.not.exists': 'メニューアプリが存在しません',
    'plan.not.found': 'プランが見つかりません',
    'driver.schedule.is.invalid': 'ドライバースケジュールが無効です',
    'time.is.not.specify.period': '時間は指定されていない期間',
    'jit.setting.not.exist': 'JIT設定が終了しない',
    'error.driver.not.exist': 'エラードライバーが存在しません',
    'error.vehicle.not.exist': 'エラー車両が存在しません',
    'subscription.payment.fail': 'クレカ決済に失敗しました',
    'error.permission.function.not.empty': '許可機能を空白にすることはできません',
    'booking.users.out.of.limit': '利用人数の限度を超えましたので、予約できません。',
    'error.vehicle.shift.over.lapse.time': '経過時間にわたる車両シフト',
    'api.send.jit.order.faulty': 'JIT注文番号{{number}}でエラーが発生した。再度確認する、または再度送信してください。',
    'company.not.exists': 'エラー会社は存在しません。',
    'api.csv.upload.failed': 'ファイルCSVがエラーです。',
    'mass.route.existed': 'このルートは存在しています',
    'error.booking.not.found': 'リソースが見つかりませんでした',
    'resource.notfound': '予約が見つかりませんでした',
    'error.role.permission.name.already.exist': 'ロール権限名はすでに存在しています',
    'number_of_unit.must.less.than.max_transfer_days': '最大転送日数は日数以下である必要があります',
    'max_transfer_days.must.less.than.reminder_days': 'リマインダー日数は、最大転送日数よりも短くする必要があります',
    'number_of_unit.must.less.than.reminder_days': 'リマインダーの日数は日数より短くする必要があります',
    'subscription.must.transfer': 'スロットを追加する前にサブスクリプションを転送する必要があります',
    'change.payment.method.not.support': 'この支払い方法は、このサブスクリプションではサポートされていません',
    'jit.generate_route_fail': '車両モードがサポートされていないため、ルートを生成できません',
    'the.process.is.being.performed.by.another.user': 'プロセスは別のユーザーによって実行されています: {{username}}',
    'error.select.interval_departure': '間隔出発時間を選択してください',
    'error.select.interval_arrival': 'インターバル到着を選択してください',
    'max.rides.must.not.less.than.additional.rides': '乗車制限は追加の乗車以上でなければなりません',
    'booking.invalid.geofence.rules': '選択したスポットはシャトルをサポートしません。他のスポットを選択してください',
    'error.date.not.in.past': '将来の時間を選択してください。',
    'route.not.found': '路線がありません。',
    'agency.not.found': '事業者がありません。',
    'station.not.found': '駅がありません。',
    'hash.value.is.invalid': 'ハッシュ値が無効です。',
    'payment.fail': '支払いに失敗しました。',
    'duplicate.station': '駅はすでに存在しました。',
    'ticket.not.found': '券がありません。',
    'start_date_is_invalid': '利用開始日は無効です。',
    'error.date.validation.failed': '開始日は配布日以上である必要があります。',
    'api.csv.upload.member.code.duplicate': 'メンバコードが重複している。',
    'error.restrict.waypoint': 'ウェイポイント {{fields}} は制御時間内のため利用できません。 別のウェイポイントを選択してください',
    'mass.booking.subscription.expired': '乗り放題はご利用できません',
    'notification.waypoint.restrict.content': 'ご指定頂いた乗降地は現在ご利用できません。\nお手数ですが、再度乗降地の選択をお願いします。',
    'mass.simulation.end.time.invalid': 'シミュレーションの終了時間を 1 日以上更新するか、このシミュレーションを無効にしてください',
    'geofence.not.support.advance.booking': 'ジオフェンスは事前予約には対応していません。',
    'error.vehicle.session.default.location.invalid': 'この緯度・経度はジオフェンスに存在していません',
    'one.time.price.special.invalid': '特別乗客価格はワンタイム乗客価格より低くなければなりません。',

    // Message success call API
    'api.delete.recommend.shuttle.success': 'シャトルを正常に削除する',
    'api.create.recommend.shuttle.success': 'シャトルを正常に作成する',
    'api.update.recommend.shuttle.success': 'シャトルを正常に更新する',
    'api.create.comment.success': 'コメントの作成成功',
    'api.update.comment.success': 'コメントの更新成功',
    'api.delete.comment.success': 'コメントの削除成功',
    'api.order.plan.success': '注文計画を成功させる',
    'api.create.partner.success': 'ショップサブスクリプションを正常に作成する',
    'api.update.partner.success': 'ショップサブスクリプションを正常に更新する',
    'api.update.sale.times.success': '販売時間を正常に更新する',
    'api.update.enabled.vehicle.success': '予約が正常に更新されました。',
    'api.update.reservation.success': '予約が正常に更新されました。',
    'api.import.vehicle.config.success': 'CSVを正常にインポートします。',
    'api.invite.success': 'アカウントを招待しました。',
    'api.active.account.success': 'アクティブなアカウントが正常に作成されました。',
    'api.updated.account.success': 'アカウントを更新しました。',
    'api.execute.reissue.success': 'パスワードの再発行に成功しました。',
    'Api.success.change.password': 'パスワードを変更しました。',
    'api.reissue_password.request.success': 'パスワードの再発行をリクエストしました',
    'api.reissue_password.verify.success': '再発行パスワードを確認しました',
    'api.reissue_password.confirm.success': '再発行パスワードを確認しました',
    'api.add.supplier.success': 'サプライヤーを追加しました。',
    'api.updated.supplier.success': 'サプライヤーを更新しました。',
    'api.deleted.supplier.success': '削除しました',
    'api.delete.driver.success': 'ドライバーを削除しました',
    'api.update.driver.success': 'ドライバーを更新しました',
    'api.create.driver.success': 'ドライバーを作成しました',
    'api.delete.caution.success': 'アイテムーを削除しました',
    'api.update.caution.success': 'アイテムをーを更新しました',
    'api.create.caution.success': 'アイテムーを作成しました',
    'api.updated.member.success': 'メンバーを更新しました。',
    'api.created.member.success': 'メンバーを作成しました',
    'api.canceled.member.success': 'メンバーはキャンセルされました',
    'api.deleted.facility.success': '施設を削除しました',
    'api.updated.facility.success': '施設を更新しました',
    'api.add.facility.success': '営業所・店舗が登登録されました。',
    'api.delete.vehicle.success': '車両を削除しました',
    'api.updated.vehicle.success': '更新しました',
    'api.created.vehicle.success': '登録が完了しました',
    'api.update.calendar.success': 'カレンダーを更新しました',
    'api.create.image.success': '画像を作成しました',
    'api.update.image.success': '画像を更新しました',
    'api.delete.image.success': '画像を削除しました',
    'api.create.delivery.success': '配達を作成しました',
    'api.update.delivery.success': '配達を更新しました',
    'api.update.delivery.time.setting.success': 'デリバリー時間設定を更新しました',
    'api.delete.delivery.success': '配達を削除しました',
    'api.register.congestion.success': '混雑を登録しました',
    'api.update.jit.special.success': 'JitSpecial設定を正常に更新します。',
    'api.add.role.permission.success': 'アカウントタイプが正常に作成されました',
    'api.update.role.permission.success': 'アカウントタイプが正常に更新されました',
    'api.delete.role.permission.success': 'アカウントの種類が正常に削除されました',
    'permission.denied': '許可が拒否されました',
    'permission.reservation.denied': '予約は成功しましたが、予約内容を参照する権限がありません。',
    'api.disable.error.qr.success': 'エラーQR支払いを正常にクリアしました',
    'api.create.one.time.price.success': 'ワンタイム金額の設定は完了しました。',
    'api.update.one.time.price.success': 'ワンタイム金額の更新は完了しました。',
    'api.create.plan.success': 'プランが正常に作成されました',
    'api.update.plan.success': '計画を正常に更新する',
    'api.create.recommend.success': '推奨を正常に作成しました',
    'api.update.recommend.success': '更新された推奨は正常に',
    'api.delete.recommend.success': '削除された推奨は正常に',
    'Api.success': '成功',
    'Api.fail': '失敗',
    'api.create.service.success': 'サービスが正常に作成されました',
    'api.update.service.success': 'サービスが正常に更新されました',
    'api.delete.service.success': 'サービスが正常に削除されました',
    'api.create.waypoint.success': 'ウェイポイントが正常に作成されました',
    'api.delete.waypoint.success': 'ウェイポイントが正常に削除されました',
    'change.payment.transfer.status.success': '支払いステータスが正常に変更されました',
    'payment.transfer.status.cannot.be.changed': '支払い状態の変更に失敗しました。',
    'user.verify.email.token.success': '認証に成功しました',
    'api.create.bank.account.success': '銀行口座が正常に作成されました',
    'api.update.bank.account.success': '銀行口座が正常に更新されました',
    'api.refresh.cache.success': 'キャッシュを正常に更新する',
    'api.create.partner.waypoint.success': 'パートナーウェイポイントが正常に作成されました',
    'api.delete.mobile.funciton.success': 'モバイル機能を正常に削除しました',
    'api.create.mobile.function.success': 'モバイル機能を正常に作成しました',
    'api.update.mobile.function.success': 'モバイル機能が正常に更新されました',
    'api.create.user.family.success': 'ユーザーファミリが正常に作成されました',
    'api.update.user.family.success': 'ユーザーファミリが正常に更新されました',
    'api.delete.user.family.success': 'ユーザーファミリーを正常に削除しました',
    'api.delete.menu.app.success': 'メニューアプリを正常に削除しました',
    'api.create.menu.app.success': 'メニューアプリが正常に作成されました',
    'api.update.menu.app.success': 'メニューアプリを正常に更新しました',
    'api.copy.plan.success': '計画を正常にコピーする',
    'api.not.null': '{{field}}はnullであってはなりません',
    'Api.unknown.error': '不明なエラー',
    'api.create.jit.order.success': '予約作成に成功しました。',
    'api.update.jit.order.success': '予約更新に成功しました。',
    'api.send.jit.order.success': 'JIT予約送信が成功しました。',
    'api.clone.jit.order.success': '予約クローンに成功しました。',
    'api.delete.area_geofence.success': 'ジオフェンスエリアを正常に削除しました',
    'api.create.area_geofence.success': 'ジオフェンスエリアが正常に作成されました',
    'api.update.area_geofence.success': 'ジオフェンスエリアが正常に更新されました',
    'api.delete.company.success': '会社を正常に削除しました。',
    'api.create.company.success': '会社を成功に作成しました。',
    'api.update.company.success': '会社を成功に更新しました。',
    'api.delete.stop_master.success': 'ストップポイントを正常に削除しました',
    'api.create.stop_master.success': 'ストップポイントが正常に作成されました',
    'api.update.stop_master.success': 'ストップポイントが正常に更新されました',
    'api.sync.stop_master.success': 'ストップポイント正常に同期されました',
    'api.update.just.in.time.setting.success': 'JIT設定の更新に成功しました',
    'api.delete.qr_code.success': 'QRコードを正常に削除しました',
    'api.create.qr_code.success': 'QRコードが正常に作成されました',
    'api.update.qr_code.success': 'QRコードが正常に更新されました',
    'mass.card.not.found': 'クレジットカードの有効期限が切れました。もう一度やり直してください。',

    // Image selector
    'imageSelector.error': '5つ以上の画像を選択することはできません',
    'image.maxSize': '画像サイズ{{imageWidth}} x {{imageHeight}}が無効です。最大サイズが必要です：{{maxWidth}} x {{maxHeight}}',

    // Custom pagination
    'customPagination.rowsPerPage': '1ページあたりの行数',
    'customPagination.startEndRows': '{{rows}}の{{start}} - {{end}}',

    // Login page
    'login.passwordReissue': 'パスワード再発行の方はこちら',

    // Registration page
    'registration.title': 'アカウント作成',
    'registration.accountType': 'アカウント種別',
    'registration.authority': '権限',
    'registration.businessName': '事業者名',
    'registration.contactName': '担当者',
    'registration.rePassword': '再入力パスワード',

    // Change password page
    'changePassword.title': 'パスワード変更',
    'changePassword.msgExpired': '※現在のパスワードの有効期限は残り30日です。',
    'changePassword.currentPassword': '現在のパスワード',
    'changePassword.newPassword': '新パスワード',
    'changePassword.confirmPassword': '再入力パスワード',
    'changePassword.labelCurrentPassword': '現在設定中のパスワードを入力してください。',
    'changePassword.labelNewPassword': '小文字を含む6文字以上で入力してください。',
    'changePassword.labelConfirmPassword': '確認のためもう一度パスワードを入力してください。',

    // Password reissue page
    'passwordReissue.title': 'パスワード再発行',
    'passwordReissue.msg': 'アカウント情報を入力し、送信ボタンを押してください',
    'passwordReissue.msg_child': 'パスワード再設定用ページへのリンクをメールにてお送りします。',
    'passwordReissue.msgFooter': 'メールを受信できない場合メールアドレスをご確認の上、もう一度送信してください。',

    // Password reissue confirm page
    'passwordReissueConfirm.title': 'パスワード再発行',
    'passwordReissueConfirm.new_password': '新パスワード',
    'passwordReissueConfirm.re_password': '再入力パスワード',

    // Account invite Page
    'accountInvite.title': 'アカウント招待',
    'accountInvite.accountType': 'アカウント種別-権限',
    'accountInvite.businessName': '事業者名',
    'accountInvite.contactName': '担当者名',
    'accountInvite.checkInputMsg': '(最大128文字)',
    'accountInvite.noAccountType': 'このビジネスにはアカウントタイプがありません',

    // Account management Page
    'accountManagement.title': 'アカウント管理',
    'accountManagement.titleSearch': '検索条件',
    'accountManagement.accountType': 'アカウント種別',
    'accountManagement.status': '会員ステータス',
    'accountManagement.member_status': '会員ステータス',
    'accountManagement.verify_mobile_status': '電話番号認証ステータス',
    'accountManagement.update_info_status': 'プロフィール入力ステタス',
    'accountManagement.verify_email_status': 'メールアドレス認証ステタス',
    'accountManagement.signUpType': 'サインアップタイプ',
    'accountManagement.registration_platform': '登録プラットフォーム',
    'accountManagement.registration_platform.mobi_app': 'mobiアプリ',
    'accountManagement.registration_platform.admin': '管理画面登録',
    'accountManagement.app_version': 'アプリバージョン',
    'accountManagement.statusValue.inUse': '利用中',
    'accountManagement.statusValue.stop': '停止中',
    'accountManagement.statusValue.inRec': '招待中',
    'accountManagement.contactName': '担当者名',
    'accountManagement.businessName': '事業者名',
    'accountManagement.companyName': '会社名(ユーザ表示)',
    'accountManagement.partialMatch': '部分一致',
    'accountManagement.resendInviteEmail': '招待メール再送信',
    'accountManagement.accountRestart': 'アカウント再開',
    'accountManagement.accountSuspension': 'アカウント停止',
    'accountManagement.autoUpdate': '自動更新',
    'accountManagement.autoUpdateValue.inValid': '有効',
    'accountManagement.autoUpdateValue.valid': '無効',
    'accountManagement.autoUpdateWarn': '有効の場合、更新処理を行った後自動で検索を行います',
    'accountManagement.author': '権限',
    'accountManagement.registrationDate': '登録/招待日時 (UTC)',
    'accountManagement.resendInvitation': '選択したアカウントに招待状を再送信しますか？',
    'accountManagement.restartAccounts': '選択したアカウントを再開しますか？',
    'accountManagement.suspendAccounts': '選択したアカウントを一時停止しますか？',

    // Update account page
    'updateAccount.before': '変更前',
    'updateAccount.after': '変更後',
    'updateAccount.memo': 'メモ欄',
    'updateAccount.memoSize': '(最大2000文字)',
    'updateAccount.updateModal': 'アカウントを更新する',

    // Account type page
    'accountType.register.title': 'アカウントタイプの登録',
    'accountType.detail.title': 'アカウントタイプの詳細',
    'accountType.typeName': 'タイプ名',
    'accountType.supplierName': '事業者名',
    'accountType.facilityName': '営業所・店舗名',
    'accountType.majorItems': '主なアイテム',
    'accountType.mediumItem': 'ミディアムアイテム',
    'accountType.function': '関数',
    'accountType.functionalRestriction': '機能制限',
    'accountType.allow': '許可する',
    'accountType.management.title': '口座の種類',
    'accountType.typeId': 'タイプID',
    'accountType.titleSearch': '検索条件',
    'accountType.deleteConfirmAccountType': '削除します。大丈夫ですか。',

    // Member management page
    'memberManagement.total_one_time': '利用した金額',
    'memberManagement.sts.withdraw': '退会済み',
    'memberManagement.sts.banned': '退会',
    'memberManagement.sts.update': 'プロフィール未入力',
    'memberManagement.sts.verify': 'メールアドレス未認証',
    'memberManagement.sts.verified': 'メールアドレス認証済み',
    'memberManagement.sts.un_verified_mobile': '電話番号未認証',
    'memberManagement.sts.verified_mobile': '電話番号認証済み',
    'memberManagement.sts.updated_info': 'プロフィール登録済み',
    'memberManagement.status.registration_completed': '登録完了',
    'memberManagement.status.incomplete_registration': '登録未完了',
    'memberManagement.status.withdraw': '退会済み',
    'memberManagement.status.verified': '認証済み',
    'memberManagement.status.un_verified': '未認証',
    'memberManagement.status.updated': '更新済み',
    'memberManagement.status.un_update': '未更新',
    'memberManagement.sex.none': 'なし',
    'memberManagement.sex.male': '男性',
    'memberManagement.sex.female': '女性',
    'memberManagement.createMember': 'メンバーを作成する',
    'memberManagement.memberType': '会員タイプ',
    'memberManagement.memberId': 'ユーザーコード',
    'memberManagement.memberEmail': '会員メールアドレス',
    'memberManagement.memberName': 'ユーザー名',
    'memberManagement.memberPhone': '会員電話番号',
    'memberManagement.sex': '性別',
    'memberManagement.enrollmentDateUTC': '登録日時 (UTC)',
    'memberManagement.enrollmentDate': '登録日時',
    'memberManagement.withdrawalDateTime': '出金日時 (UTC)',
    'memberManagement.birthDay': '生年月日',
    'memberManagement.in_sub': 'サブスクリプション利用中',
    'memberManagement.verified': '承諾ステータス',
    'memberManagement.uuid': 'Veritransユーザーコード',
    'memberManagement.confirm_credit_card': 'クレカ確認',
    'memberManagement.cardNumber': 'カード番号',
    'memberManagement.cardExpire': '有効期限',
    'memberManagement.cantGetCardInfo': 'カード情報が取得できませんでした',
    'memberManagement.memberRegistrationDate': '会員登録日',
    'memberManagement.force_logout': '強制ログアウト',
    'memberManagement.confirm_force_logout': 'このユーザーを強制的にログアウトさせますか？',
    'memberManagement.on_booking': 'ユーザーが予約しています',
    'memberManagement.on_subscription': 'ユーザーはサブスクリプションに参加しています',
    'memberManagement.can_not_cancel': 'ユーザーをキャンセルすることはできません',
    'memberManagement.confirm_free_trial': 'このユーザーの無料トライアルを続行しますか？',
    'memberManagement.no_smart_phone': 'スマートフォンなし',
    'memberManagement.membershipStatus': '会員ステータス',
    'memberManagement.membershipType': '会員種別',
    'memberManagement.referrer_code_name': '紹介コード名',
    'memberManagement.nricNumber': 'NRIC 番号',
    'memberManagement.passportNumber': 'パスポート番号',
    'memberManagement.identityNumber': 'NRIC・パスポート番号',
    'memberManagement.nircNumbericValidation': 'NRIC 番号は 12 桁の数字を含める必要があります',
    'memberManagement.passportNummberValidation': 'パスポート番号の形式が正しくない、または特殊文字が含まれています',
    'memberManagement.identityVerificationManagement': '身分証審査',
    'memberManagement.identityVerificationDetail': '身分証審査詳細',
    'memberManagement.userCode': 'ユーザーコード',
    'memberManagement.registrationTime': '申請日時',
    'memberManagement.registrationPeriod': '申請期間',
    'memberManagement.registrationFrom': '申請日(から)',
    'memberManagement.registrationTo': '申請日（まで）',
    'memberManagement.applicationDocument': '申請書類',
    'memberManagement.applicationStatus': '審査状況',
    'memberManagement.applicationLanguage': '申請時言語',
    'memberManagement.nric': 'NRIC',
    'memberManagement.passport': 'パスポート',
    'memberManagement.mailLanguage': 'メール言語',
    'memberManagement.reasonNote': '理由・メモ',
    'memberManagement.btnUpdateVerificationResult': '審査結果を登録する',
    'memberManagement.applicationStatus.unVerified': '申請中',
    'memberManagement.applicationStatus.cancelRegistration': '取り下げ',
    'memberManagement.applicationStatus.verified': '審査完了',
    'memberManagement.applicationStatus.rejected': '差し戻し',
    'memberManagement.profileImage': 'プロファイル画像',
    'memberManagement.nricImage': 'NRIC画像',
    'memberManagement.passportImage': 'パスポート画像',
    'memberManagement.memoField': '審査履歴',
    'memberManagement.registrationPeriod.within30days': '開始日と終了日は 30 日以内である必要があります。',
    'memberManagement.hasNewAvatar': '現在プロフィールとして設定されている最新画像：',
    'memberManagement.selectUserCodeOrStatus': 'ユーザコードまたは審査状況を選択してください。',

    // Update member page
    'updateMember.province': '州',
    'updateMember.district': '地区',
    'updateMember.updateModal': 'メンバーを更新?',
    'updateMember.createModal': 'メンバーを更新?',
    'updateMember.note': '注意事項/特記事項',
    'updateMember.firstName': '名',
    'updateMember.lastName': '姓',
    'updateMember.firstName_furigana': 'めい ひらがな',
    'updateMember.lastName_furigana': 'せい　ひらがな',
    'updateMember.gender': '性別',
    'updateMember.app_version': 'バージョン',
    'updateMember.os': 'OSバージョン',
    'updateMember.screen_size': '画面サイズ',
    'updateMember.cancelModal': 'メンバーをキャンセル',
    'updateMember.cancelNotifyEmail': '会員解約、\r\n それらをメールで送信してください！',
    'updateMember.cancelNotify': 'メール通知する',

    // Member referrer list
    'memberReferrer.completedDateTime': '認証日時',
    'memberReferrer.canceledDateTime': '解除日時',
    'memberReferrer.inactiveReason': '認証解除理由',
    'memberReferrer.inactivatedByMember': 'メンバーによって無効にします',
    'memberReferrer.active': '有効',
    'memberReferrer.inactive': '無効',

    // member's favorite waypoint
    'favoriteWaypoint.add_waypoint': 'Waypoint追加',
    'favoriteWaypoint.waypoint_list': 'お気に入りのwaypointの一覧',
    'favoriteWaypoint.favorite_tag': 'お気に入り登録名',
    'favoriteWaypoint.location': '場所',
    'favoriteWaypoint.pick_up': '乗車地',
    'favoriteWaypoint.drop_off': '降車地',
    'favoriteWaypoint.pick_up_name': '乗車Waypoint名',
    'favoriteWaypoint.drop_off_name': '降車Waypoint名',
    'favoriteWaypoint.home': '自宅',
    'favoriteWaypoint.create_by': '作成者',
    'favoriteWaypoint.form': 'お気に入りのwaypointのフォーム',
    'favoriteWaypoint.uploadModal': 'このお気に入りのwaypointを登録しますか？',
    'favoriteWaypoint.deleteModal': 'このお気に入りのwaypointを削除しますか？',
    'favoriteWaypoint.changeLatLongAndName': 'ご利用時から名称/場所が変更になっています',
    'favoriteWaypoint.changeLatLong': 'ご利用時から場所が変更になっています',
    'favoriteWaypoint.changeName': 'ご利用時から名称が変更になっています',

    // Business management page
    'businessType.other': 'その他',
    'businessRole.global': '管理権限 / システム管理権限',
    'businessRole.country': '国ごと管理権限',
    'businessRole.business': '一般権限',
    'businessType.taxi': 'タクシー会社',
    'businessType.bus': 'バス会社',
    'businessStatus.contracted': '契約済み',
    'businessStatus.suspended': '販売停止中',
    'businessStatus.unsigned': '未契約',
    'businessType.1': 'その他',
    'businessType.2': 'デリバリー',
    'businessType.3': '人の移動',
    'businessIndustry.0': 'スーパーマーケット',
    'businessIndustry.1': '運輸',
    'businessIndustry.2': '土産物店',
    'businessIndustry.3': 'レストラン',
    'businessCountry.jp': '日本',
    'businessCountry.vn': 'ベトナム',
    'businessCountry.sg': 'シンガポール',
    'businessCountry.my': 'マレーシア',
    'businessLanguage.ja': '日本語',
    'businessLanguage.eng': '英語',
    'businessLanguage.vie': 'ベトナム語',
    'businessLanguage.chi': '中国語',
    'businessInformation.title': '事業者情報',
    'businessInformation.searchTitle': '検索条件',
    'businessInformation.id': '事業者ID',
    'businessInformation.status': '運行ステータス',
    'businessInformation.type': '事業者種別',
    'businessInformation.role': '管理権限',
    'businessInformation.cannotRegisterSaleOffice': '*管理権限／システム管理権限や国ごとの管理権限を持っている事業者が営業所・店舗を作成できません',
    'businessInformation.industry': '業種',
    'businessInformation.code': '事業者ID',
    'businessInformation.name': '事業者名',
    'business.question.confirm.update': '車両を登録します。よろしいですか。',
    'business.question.confirm.create': '事業者を登録します。よろしいですか。',
    'company.question.confirm.update': '会社を変更しませんか？',
    'company.question.confirm.create': '新しい会社を作成しませんか？',
    'business.question.confirm.paymentRetry': '支払いを再試行しますか？',
    'coupon.question.confirm.update': 'クーポンを更新します。よろしくお願いします？',
    'coupon.question.confirm.create': 'クーポンを登録します。よろしくお願いします？',
    'coupon.question.confirm.delete': 'クーポンを削除します。よろしくお願いします？',

    // Business Detail page
    'businessDetail.form': '事業者情報',
    'businessDetail.title': '事業内容',
    'businessDetail.id': 'ビジネスID',
    'businessDetail.languages': '言語',
    'businessDetail.fee': '費用',
    'businessDetail.other': '他の',
    'businessDetail.rate': '割合',

    // Business Vehicle
    'businessVehicle.title': '車両情報',
    'businessVehicle.add': '車両登録',
    'businessVehicle.form': '車両登録',
    'businessVehicle.detail': '車両詳細',
    'businessVehicle.searchTitle': '検索条件',
    'businessVehicle.code': '車両ナンバー',
    'businessVehicle.code_id': '車両ID',
    'businessVehicle.status': '運行ステータス',
    'businessVehicle.businessCode': '商コード',
    'businessVehicle.businessName': '事業者名',
    'businessVehicle.businessId': '事業者ID',
    'businessVehicle.sales_office_store_code': '営業所/店舗ID',
    'businessVehicle.sales_office_store_name': '営業所/店舗名',
    'businessVehicle.type': '車種',
    'businessVehicle.sheetType': 'シートタイプ',
    'businessVehicle.brand': 'ブランド',
    'businessVehicle.vehicleName': '車両名',
    'businessVehicle.vehicleNumber': '車両ナンバー',
    'businessVehicle.vehicleInformation': '車両情報',
    'businessVehicle.numberOfSeats': '座席数',
    'businessVehicle.insuranceType': '保険の種類',
    'businessVehicle.displayVehicle': 'ウェポイント住所',
    'businessVehicle.numberOfRows': '行の数',
    'businessVehicle.seatsPerRow': '行あたりの座席数',
    'businessVehicle.driverSeat': '運転手',
    'businessVehicle.seatYear': '可',
    'businessVehicle.seatNo': '不可',
    'businessVehicle.chairAvailable': '椅子が使われている',
    'businessVehicle.chairNotUse': '未使用チェア',
    'businessVehicle.blankSpace': 'スペース',
    'businessVehicle.type.large': '大',
    'businessVehicle.type.medium': '中',
    'businessVehicle.type.small': '小さい',
    'businessVehicle.type.other': 'その他の',
    'businessVehicle.status.operable': '運行可',
    'businessVehicle.status.stopping': '停止中',
    'businessVehicle.seatType.standard': '標準',
    'businessVehicle.seatType.premium': 'プレミアム',
    'businessVehicle.color': '車両色',
    'businessVehicle.usagePattern': '利用形態',
    'businessVehicle.mobiType': 'mobi種別',
    'businessVehicle.color.yellow': '黄色',
    'businessVehicle.color.orange': 'オレンジ',
    'businessVehicle.color.red': '赤',
    'businessVehicle.color.purple': '紫',
    'businessVehicle.color.blue': '青',
    'businessVehicle.color.green': '緑',
    'businessVehicle.color.white': '白',
    'businessVehicle.color.black': '黒',
    'businessVehicle.color.brown': '茶色',
    'businessVehicle.color.gray': 'グレー',
    'businessVehicle.color.pink': 'ピンク',
    'businessVehicle.mobiType.TAXI': 'TAXI',
    'businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND': 'オンデマンド',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'JIT HTW',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'JIT WTH',
    'businessVehicle.mobiType.UNITRAND': 'UNITRAND',
    'businessVehicle.mobiType.searchResult.TAXI': 'TAXI',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_ON_DEMAND': 'オンデマンド',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'JIT HTW',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'JIT WTH',
    'businessVehicle.operatingHours': '車両運行時間',
    'businessVehicle.jit': 'JIT詳細設定',
    'businessVehicle.date': '日付',
    'businessVehicle.title_shift': 'JIT乗降時間表示',
    'businessVehicle.vir': '仮想車両',
    'businessVehicle.driver_schedule_register': 'ドライバースケジュール登録',
    'businessVehicle.driver_schedule_register_form': 'ドライバースケジュール登録フォーム',
    'businessVehicle.applyFromTo': '{{start_date}}から{{end_date}}まで適用しますか?',
    'businessVehicle.applyFromToOver': '{{start_date}}から{{end_date}}まで適用しますか?\n期間が1か月以上のため、処理に時間が掛かる可能性があります。実行しますか？',
    'businessVehicle.title_jit_special_setting': 'JIT 特別な設定',
    'businessVehicle.start_date': '開始日',
    'businessVehicle.end_date': '終了日',
    'businessVehicle.vehicle_mode': '車両モード',
    'businessVehicle.modal.add.row': '行を追加してください',
    'vehicle.registration_number': '車両ナンバー',
    'businessVehicle.auto_config': '自動設定',
    'businessVehicle.confirm_delete_shift': 'これらのシフトを削除してもよろしいですか?',
    'businessVehicle.trackingGPSTitle': 'GPSリアルタイムを追跡するビジネス車両',
    'businessVehicle.trackingTitle': '業務用車両追跡',
    'businessVehicle.historyLocation': '履歴の場所',
    'businessVehicle.realTimeLocation': 'リアルタイムの場所',
    'businessVehicle.vehicle_not_in_geofence': 'この車両はジオフェンスにありません',
    'businessVehicle.validate.between_a_day': '終了日と開始日はちょうど24時間です',
    'businessVehicle.validate.from_to': '終了日が開始日よりも大きい',
    'businessVehicle.pickup_date': '追跡時間',
    'businessVehicle.vehicle_selection_period': '車両使用期間',
    'businessVehicle.driver_shift_config': 'シフト設定',
    'businessVehicle.driver_vehicle_association': 'ドライバー・車輛の紐づけ変更履歴',
    'businessVehicle.view_shift_config': 'シフト確認',
    'businessVehicle.vehicleAddition': ' 車両情報追加',
    'businessVehicle.description_notice': '説明を入力ください',
    'businessVehicle.description_name': '内容',
    'businessVehicle.title_notice': 'タイトルを入力ください',
    'businessVehicle.title_name': '項目名',
    'businessVehicle.field': 'フィールド',
    'businessVehicle.line_break': '(改行入力可能)',
    'businessVehicle.delete_additional': '削除してもよろしいですか？',
    'businessVehicle.booking_type': '予約タイプ',
    'businessVehicle.booking_normal': 'ODM リアルタイム予約',
    'businessVehicle.booking_advanced': 'ODM リアルタイム予約・事前予約',
    'businessVehicle.cancel_all_booking':
      '車輛セッションの運行時間変更のため、一部のご予約はキャンセルとなります。\r\n 影響を受ける予約数: {{field}} 件.',
    'businessVehicle.defaultLocation': 'デフォルト位置',
    'businessVehicle.latitude': '緯度',
    'businessVehicle.longtitude': '経度',
    'businessVehicle.locationOfDriver': '* 運行開始時にどのロケーションにドライバー（車輛）がいるかを特定する役割です',
    'businessVehicle.loadingText': 'この処理は、3分以上掛かる可能性があります。そのままお待ちください。',
    'businessVehicle.associationStatus': '紐づけ有無',
    'businessVehicle.deleteVehicle': '車両の削除により、いくつかの予約がキャンセルされます。\n 影響を受ける予約の数: {{field}} 件',
    'businessVehicle.deleteVehicleConfirm': '車両を本当に削除しますか？',

    // Business Partner
    'businessPartner.searchTitle': '検索条件',
    'businessPartner.code': 'コード',
    'businessPartner.description': '説明',
    'businessPartner.discount': '割引',
    'businessPartner.discount_type': 'タイプ',
    'businessPartner.alertDelete': 'このショップサブスクリプションを削除します。 大丈夫ですか？',
    'businessPartner.alertUpdate': 'このショップサブスクリプションを更新します。 大丈夫ですか？',
    'businessPartner.alertCreate': 'このショップサブスクリプションを作成します。 大丈夫ですか？',
    'businessPartner.alertChangeDiscount': 'waypointに割引を適用する場合は、waypoint画面で割引を変更してください。',
    'businessPartner.discount.1': 'パーセント引き',
    'businessPartner.discount.2': '円引き',
    'partner_payment_status.unpaid': '未入金',
    'partner_payment_status.paid': '有料',
    'partner_payment_status.need_refund': '返金が必要',
    'partner_payment_status.refuned': '洗練された',
    'partner_payment_status.over': '入金済み',
    'businessPartner.usage_status': '利用ステータス',
    'businessPartner.qr_code': 'QRコード',
    'businessPartner.number_of_free_rides': '無料利用数',
    'businessPartner.shop_free': '無料で利用',
    'businessPartner.number_of_waypoint': 'ウェイポイントの数',
    'businessPartner.waypoint': 'ウェイポイント',
    'businessPartner.payment_transfer_status': '支払いステータス',
    'businessPartner.transfer_deadline_day': '振り込み期限',
    'businessPartner.plan_code': 'プランコード',
    'businessPartner.subscription_code': 'サブスクリプションコード',
    'businessPartner.information': '情報',
    'businessPartner.business_time': '営業時間',
    'businessPartner.website': 'ホームページ',
    'businessPartner.name': 'ショップパートナー名',
    'businessPartner.campaignInfor': 'キャンペーン情報表示',
    'businessPartner.waypointDisplay': '金額・割引率表示',
    'businessPartner.commentDisplay': 'コメント表示',
    'businessPartner.comment': 'コメント',
    'businessPartner.noteCampaignInfor': '*キャンペーン情報を設定したい場合は、割引を入力してください。',

    'businessPartner.restrict': '制御',
    'businessPartner.restrict_description': '制御説明',
    'businessPartner.restrict_photo': '制御画像',
    'businessPartner.restrict_type': '制御タイプ',
    'businessPartner.restrict_days': '制御日',
    'businessPartner.repeat_setting': '繰り返し設定',
    'businessPartner.repeat_start_date': '繰り返し開始日',
    'businessPartner.repeat_end_date': '繰り返し終了日',
    'businessPartner.repeat_weekly_on': '毎週繰り返し',
    'businessPartner.repeat_monthly_on': '毎月繰り返し',
    'businessPartner.exceptional_days': '除外日',
    'businessPartner.restrict_time': '制御時間',
    'businessPartner.required_restrict_description': '制御説明を入力してください',
    'businessPartner.required_restrict_type': '制御タイプを選択してください',
    'businessPartner.required_restrict_days': '制御日を選択してください',
    'businessPartner.required_exceptional_days': '除外日を選択してください',
    'businessPartner.required_repeat_start_date': '繰り返し開始日を選択してください',
    'businessPartner.required_repeat_end_date': '繰り返し終了日を選択してください',
    'businessPartner.required_repeat_weekly_on': '毎週繰り返しを選択してください',
    'businessPartner.required_repeat_monthly_on': '毎月繰り返しを選択してください',
    'businessPartner.duplicated_days': '制限日による重複日があります',
    'businessPartner.specific_days': '特定日',
    'businessPartner.repeat': '繰り返し',
    'businessPartner.daily': '毎日',
    'businessPartner.weekly': '毎週',
    'businessPartner.monthly': '毎月',
    'businessPartner.waypoint_restrict': 'ウェポイント制御',
    'businessPartner.place_holder_waypoint_restrict': 'ウェポイント制御を選択してください',
    'businessPartner.date_of_month': '月の日付',
    'businessPartner.delete': '削除',
    'businessPartner.never': 'なし',
    'businessPartner.on': '終了日',
    'businessPartner.restrict_setting': 'ウェポイント制御',
    'businessPartner.waypoint_display_type': 'ウェポイント表示タイプ',
    'businessPartner.partner_display': 'パートナー表示',
    'businessPartner.normal_display': '通常表示',
    'businessPartner.error_repeat_start_date': '繰り返し開始日はスタート日付よりも後にする必要です',
    'businessPartner.error_repeat_end_date': '繰り返し終了日はエンド日付より前にする必要です',
    'businessPartner.error_discount': '制御時間外waypoint表示を通常表示にする場合、割引は０にする必要です。',
    'businessPartner.popup_error_discount': 'waypointに通常表示を適用する場合は、waypoint画面で割引を０に変更してください。',
    //
    'businessPartner.referrer_setting': '紹介コード設定',
    'businessPartner.referrer_id': 'ID',
    'businessPartner.referrer_name': '紹介コード名',
    'businessPartner.referrer_code': '紹介コード',
    'businessPartner.plan_setting': 'プレゼントチケット設定',
    'businessPartner.referrer_description': '紹介コード詳細',
    'businessPartner.total_associated_memmber_amount': 'メンバーの総数',
    'businessPartner.list_member': 'ユーザー一覧',
    'businessPartner.list_associated_disassociated_members': 'ユーザー一覧',
    'businessPartner.status': 'ステータス',
    'businessPartner.active': '有効',
    'businessPartner.inActive': '無効',
    'businessPartner.distribution_period': '配布期間',
    'businessPartner.question.confirm.create': 'この紹介コードを作成しますか？',
    'businessPartner.question.confirm.disabled': '「認証された{{count}}人のユーザーがいます。無効にしてよろしいですか？」',
    'businessPartner.question.confirm.update': '紹介コードを更新します。よろしいですか？',
    'businessPartner.referrerNameRequired': '紹介コード名を入力してください ',
    'businessPartner.referrerCodeRequired': '紹介コードを入力してください',
    'businessPartner.referrerMailRequired': 'メールを入力してください',
    'businessPartner.referrerDescriptionPlaceHolder': '紹介コード詳細を入力してください',
    'businessPartner.distributionPeriodRequired': '配布期間を選択してください',
    'businessPartner.errorUseTime': '配布期間はプラン使用時間内で指定してください',
    'businessPartner.checkFromTime': '（から）は現在時間以上である必要があります',
    'businessPartner.checkToTime': '（まで）は現在時間以上である必要があります',
    'businessPartner.planSettingYes': 'あり',
    'businessPartner.planSettingNo': 'なし',
    'businessPartner.mail_notification': 'メール通知',
    'businessPartner.userAuthenCompleted': 'ユーザー認証時',
    'businessPartner.userAuthenCanceled': 'ユーザー解除時',
    'businessPartner.distribution_status': '配布ステータス',
    'businessPartner.distribution_fail_reason': '配布失敗理由',
    'businessPartner.inactive_reason': '認証解除理由',
    'businessPartner.eg': '例、A12345',
    'businessPartner.editAndEnable': '変更して有効',
    'businessPartner.confirm': '確認',
    'businessPartner.displayRecord': '表示フラグ',
    'businessPartner.waypointName': 'ウェイポイント名',

    'business.invalidUseTime': '使用時間が重複しています',
    'business.referralCodeFormat': '紹介コードは頭文字が英語の大文字、残り5桁が数字にしてください',
    'business.referralCodeFormatIncorrect': '紹介コードフォーマットが正しくありません',
    'business.checkPlanCodeExist': 'プランコードが正しくありません',
    'business.checkReferrerCodeExist': '紹介コードが使用されます',
    'business.checkPlanCodeRequired': 'プランコードを入力してください',
    'business.memberCode': 'ユーザーコード',
    'business.activatedDateTime': '認証日時',
    'business.inactivatedDateTime': '解除日時',
    'business.planCode': 'プランコード',
    // Member list distribution/active status reason
    'reason.distribution.system.error': 'システムエラー',
    'reason.distribution.fail.date.not.in.plan': '認証日時が配布期間内ではありません',
    'reason.distribution.fail.already.has.ticket': 'メンバーは既にこのチケットを持っています',
    'reason.distribution.fail.already.has.ticket.from.this.referrer': 'メンバーは既にこの紹介者からチケットを受け取っています',
    'reason.distribution.fail.because.same.geofence.but.difference.discount.header': 'このメンバーはすでに同じジオフェンスの一回払い割引チケットを持っています:',
    'reason.distribution.fail.because.same.geofence.but.difference.discount.content': '(ジオフェンス{{geofenceName}}、サブスクID: {{subCode}}、大人: {{adultAmount}}、子供: {{childAmount}})',
    'reason.inactive.because.shop.deleted': '紹介者が無効にしたため、無効します',
    'reason.inactive.by.member': 'メンバーによって無効にします',
    'reason.inactive.because.member.withdrew': 'メンバーが退会したため、無効にします',
    'reason.inactive.because.this.member.withdrew': 'このメンバーが退会したため、無効にします',
    'reason.inactive.because.that.member.withdrew': 'メンバー{{memberCode}}が退会したため、無効にします',
    'reason.reactivate.because.this.member.recover.account': 'アカウントが回復されたため、{{datetime}}で最有効にします',

    // Waypoint
    'waypoint.id': 'ウェポイントID',
    'waypoint.latitude': 'ウェイポイント 緯度',
    'waypoint.longitude': 'ウェイポイント 軽度',
    'waypoint.description': '説明',
    'waypoint.product_photos': '商品画像',
    'waypoint.confirm_create': 'このウェイポイントを作成しますか？',
    'waypoint.confirm_update': 'このウェイポイントを更新しますか？',
    'waypoint.confirm_delete': 'このウェイポイントを削除しますか？',
    'waypoint.confirm_update.reservations': 'ウェイポイントの時間制限のため、一部のご予約はキャンセルとなります。\r\n影響を受ける予約数: {{noReservations}} 件',
    'waypoint.display_name': 'ウェイポイント 表示名',
    'waypoint.street_name': 'ウェイポイント 住所',
    'waypoint.not_found': 'ウェイポイントが見つかりません。',
    'waypoint.transit_stop_swat_id': 'トランジットストップスワットID',
    'waypoint.select': 'ウェイポイントを選択',
    'waypointMessage.updated': 'Waypointを正常に更新しました',
    'waypointMessage.created': 'ウェイポイントが正常に作成されました',
    'waypoint.not.exists': 'ウェイポイントは存在しません',
    'waypoint.choose_lat_long': '緯度は -90 から 90 までの数値で、経度は -180 から 180 までの数値で必要です',
    'waypoint.waypoint_restrict': 'ウェポイント制御',
    'waypoint.restrict': '制御',
    'waypoint.restrict.description': '制御説明',
    'waypoint.restrict.photo': '制御画像',
    'waypoint.restrict.type': '制御タイプ',
    'waypoint.restrict.repeat': '繰り返し',
    'waypoint.restrict.specific_days': '特定日',
    'waypoint.restrict.date_of_monthly': '月の日付',
    'waypoint.restrict.day': '制御日',
    'waypoint.restrict.setting': '制御設定',
    'waypoint.restrict.on': '終了日',
    'waypoint.restrict.never': 'なし',
    'waypoint.restrict.no_restrict': 'パートナー設定を上書き 制御なし',
    'waypoint.restrict.override_partner': 'パートナー設定を上書き 制御あり',
    'waypoint.restrict.take_over_partner': 'パートナー設定を引き継ぐ',
    'waypoint.restrict.partner_display': 'パートナー表示',
    'waypoint.restrict.normal_display': '通常表示',
    'waypoint.type.display': '制御時間外のwaypoint表示',
    'waypoint.restrict.normal_display_error': '制御時間外waypoint表示を通常表示にする場合、割引は０にする必要です。',

    // Business Store Management

    // Business Store Management
    'business.facility_delivery.title': '店舗管理',
    'business.facility_delivery.store_id': '店舗ーID',
    'business.facility_delivery.store_name': '店舗名',
    'business.facility_delivery.products_section_title': '製品',
    'business.facility_delivery.update_confirm_message': 'このストアを更新しますか',
    'business.facility_delivery.product_id': '商品ID',
    'business.facility_delivery.product_name': '商品名',
    'business.facility_delivery.supplier_facility_name': '店舗名',
    'business.facility_delivery.supplier_id': '事業者コード',
    'business.facility_delivery.sale_status': '販売ステータス',

    // Bank
    'bankManagement.code': '銀行ID',
    'bankManagement.bankName': '金融機関名',
    'bankManagement.branchName': '支店名',
    'bankManagement.branchCode': '支店番号',
    'bankManagement.accountType': '口座種類',
    'bankManagement.accountName': '口座名義',
    'bankManagement.accountNumber': '口座番号',
    'bankManagement.transferContent': '振込み内容',
    'bankManagement.confirm_create': '情報を登録しますか。?',
    'bankManagement.confirm_update': '情報を更新しますが。?',

    // Mail Category
    'mailCategory.mail_id': 'メールID',
    'mailCategory.category_mail': 'メールカテゴリ',
    'mailCategory.title_mail': 'メールタイトル',
    'mailCategory.setting_email': 'メールアドレスの設定',
    'mailCategory.email_address': 'メールアドレス',
    'mailCategory.mail_to': '宛先',
    'mailCategory.mai_cc': 'CC',
    'mailCategory.mail_bcc': 'BCC',
    'mailCategory.geofence_setting': 'ジオフェンス設定',
    'mailCategory.mail_setting_geofence': '各ジオフェンスのメールアドレスを設定する',

    // Service Group
    'serviceGroup.project_id': 'プロジェクトID',
    'serviceGroup.dataset_id': 'データセットID',
    'serviceGroup.routing_profile_id': 'ルーティング プロファイルＩＤ',
    'serviceGroup.walking_profile_id': 'ウォーキングプロフィールID',
    'serviceGroup.transit_stop_set_id': '停留所セットID',
    'serviceGroup.route_network': 'ルートネットワーク',
    'serviceGroup.description': '説明',
    'serviceGroup.confirm_create': 'サービスグループ情報を登録しますか。?',
    'serviceGroup.confirm_update': 'サービスグループ情報を更新しますか。?',
    'serviceGroup.confirm_delete': 'サービスグループ情報を削除しますか。?',

    // JIT setting
    'jit.overlap': '時間は重なっています',
    'jit.setPast': '更新可能の設定内容は明日以降です。',
    'jit.setting': 'JIT設定画面',
    'jit.collect_setting': '一括設定',
    'jit.brandOrVehicleName': 'ブランド名/車両名',
    'jit.saleOfficeName': '営業所名',
    'jit.vehicleNo': '車両ナンバー',
    'jit.vehicleColor': '車両色',
    'jit.mobiType': 'mobi種別',
    'jit.serviceArea': 'JIT運行エリア',
    'jit.virtualBusStop': '起点の仮想バス停名称',
    'jit.virtualBusStopHtw': '起点の仮想バス停名称 HTW',
    'jit.virtualBusStopWth': '起点の仮想バス停名称 WTH',
    'jit.specifyPeriod': '期間指定',
    'jit.fromWhen': 'いつから',
    'jit.untilWhen': 'いつまで',
    'jit.closeTimeDeparture': '手じまい時間（定時出発)',
    'jit.closeTimeArrival': '手じまい時間（定時到着)',
    'jit.scheduledDeparture': '定時出発',
    'jit.firstTrain': '始発',
    'jit.lastTrain': '最終',
    'jit.timeInterval': '時間間隔',
    'jit.bufferTime': 'バッファ時間',
    'jit.calendar_detail_setting': '運行カレンダー',
    'jit.arrivalOnTime': '定時到着',
    'jit.tableDeparture': '出発（最早）/出発（最遅）',
    'jit.tableInterval': '間隔',
    'jit.tableArrival': '到着（最早）/到着（最遅）',
    'jit.tableImplement': '実施有無',
    'jit.tableMinute': '分',
    'jit.dayAgo': '日前',
    'jit.untilTime': '時まで',
    'jit.hourAgo': '時間前',
    'jit.minuteAgo': '分まで',
    'jit.timeMustBe': '時間は{{time_start}}から{{time_end}}の間でなければなりません',
    'jit.htwBreakTime': '運行停止便（定時到着）',
    'jit.wthBreakTime': '運行停止便（定時出発）',
    'jit.stopped_flights': '運行停止便',
    'jit.updateSettingMsg': 'JIT時刻表を更新しますか？',
    'jit.updateSettingMsgHavingShift': '更新しますか？\r\n      更新したら既存のドライバーと車両のデータが失われます。',
    'jit.only1month': '1か月以内にのみセットアップ',
    'jit.title': 'JIT予約管理',
    'jit.status.draft': '一時的な予約',
    'jit.status.sent': '送信した予約',
    'jit.csv.import': 'CSV入力',
    'jit.csv.export': 'CSV出力',
    'jit.title.update': 'JIT予約詳細',
    'jit.title.create': 'JIT予約作成',
    'jit.confirm.create': '予約を送信します。よろしいですか。',
    'jit.confirm.update': 'この予約を更新します。よろしいですか。',
    'jit.confirm.detete': 'この予約を削除します。よろしいですか。',
    'jit.confirm.clone': 'この予約をクローンします。よろしいですか。',
    'jit.confirm.send': 'この予約を送信します。 大丈夫ですか。',
    'jit.masterData': 'JIT マスターデータ',
    'jit.date': '日付',
    'jit.time': '時間',
    'jit.time.htw': 'HTW時間',
    'jit.time.wth': 'WTH時間',
    'jit.template.csv': 'CSVテンプレート',
    'jit.waypoint.name': 'ウェポイント名',
    'jit.waypoint.code': 'ウェポイントコード',
    'jit.country.code': '国コード',
    'jit.geofence.code': 'ジオフェンスコード',
    'jit.pickup_address.code': 'ピックアップ住所コード',
    'jit.dropoff_address.code': 'ドロップオフ住所コード',
    'jit.dateTime': '日時',
    'jit.code': 'コード',
    'jit.order_id': 'JIT注文ID',
    'jit.order_number': 'JIT注文番号',
    'jit.transit_stop_points': 'トランジットストップポイント',
    'jit.list_pickup_point': '出発地 リスト',
    'jit.list_dropoff_point': ' 目的地リスト',
    'jit.setting_route': '設定ルート',
    'jit.route_name': 'ルート名',
    'jit.confirm_create_route': 'このJITルートを作成しますか',
    'jit.confirm_update_route': 'このJITルートを更新しますか',
    'jit.confirm_delete_route': 'このJITルートを削除しますか',
    'jit.no_direction': 'ボタンをタップしてください',
    'jit.20_different': '20%以上異なりますがよろしいでしょうか？',
    'jit.geofence_no_mode': 'このジオフェンスにはモードがありません',
    'jit.pickup': '出発地',
    'jit.dropoff': '目的地',
    'jit.duration': '間隔',
    'jit.generate_direction': '方向を生成する',
    'jit.distance': '距離',
    'jit.pickup_location_name': '出発地',
    'jit.dropoff_location_name': '目的地',
    'jit.route_id': 'ルートID',
    'jit.no_service_notify': 'サービスは利用できません、ウェイポイントは利用できません!',
    'jit.route_duration': 'ルート期間',
    'jit.time_minimum': '最小値は{{field}}以上である必要があります',
    'jit.missing_point': 'データの変更または削除が原因で一部のウェイポイントが欠落しています',
    'jit.onlyForOldApp': 'ルート表示設定',
    'jit.routeType': 'ルートタイプ',
    'jit.singleWork': 'JIT 1 - N',
    'jit.multiWork': 'JIT N - N',
    // eslint-disable-next-line max-len
    'jit.noteSetting': '以下のケースに当てはまるとき以外は、チェックボックスは空にしてください。\n（チェックを入れるケース）\n N-Nのルートを設定したい場合は、\n 1-Nのルートを別途複数作成して、このチェックボックスは空にしてください。\n 別途作成頂いた、1-Nのルートのチェックボックスにチェックを入れてください。',
    'jit.estimate_time': '見積もり時間',
    'jit.estimate_time_title': '見積もり時間（min）',
    'jit.estimate_option': '見積もり時刻オプション',
    'jit.estimate_option.swat': 'SWAT見積もり',
    'jit.estimate_option.manual': '手動見積もり',
    'jit.estimate_time.duration': '手動見積もり間隔',
    'jit.estimate_time.min.workToHome': 'HomeとWorkの間のルートの見積もり時間を 1以上で入力してください',

    // Business Image
    'businessImage.formData': '画像マスターフォーム',
    'businessImage.titleUpload': '画像選択',
    'businessImage.titleCrop': '画像アップロード',
    'businessImage.id': '画像ID',
    'businessImage.type': '種別',
    'businessImage.image': '画像',
    'businessImage.type.store_appearance': '店舗（外観）',
    'businessImage.type.store_inside_view': '店舗（内観）',
    'businessImage.type.person': '人物',
    'businessImage.type.product': '商品',
    'businessImage.type.vehicle_appearance': '車両（外観）',
    'businessImage.type.vehicle_inside_view': '車両（内観）',
    'businessImage.type.logo_shop': 'ショップ（ロゴ）',
    'businessImage.type.avatar_shop': 'ショップ（アバター）',
    'businessImage.crop.button': '切る',
    'businessImage.crop.upload': 'アップロード',
    'businessImage.crop.preview': 'プレビュー',

    // Sales Office/Stores page
    'facility.title': '営業所・店舗情報',
    'facility.title.detail': '営業所/店舗フォーム',
    'facility.searchTitle': '検索条件',
    'facility.id': '営業所/店舗ID',
    'facility.status': '契約ステータス',
    'facility.type': 'サービス種別',
    'facility.industry': '業種',
    'facility.industry.transportation': '交通手段',
    'facility.industry.shop': '土産物店',
    'facility.industry.supermarket': 'スーパーマーケット',
    'facility.code': '営業所/店舗コード',
    'facility.name': '営業所/店舗名',
    'facility.business.code': '事業者ID',
    'facility.business.name': '事業者名',
    'facility.person_charge': '担当者',
    'facility.home_page': 'ホームページ',
    'facility.mobile_operator': '電話番号オペレーター',
    'facility.email': 'メールアドレス①',
    'facility.email_2': 'メールアドレス②',
    'facility.email_3': 'メールアドレス③',
    'facility.language': '言語',
    'facility.holiday': '休日',
    'facility.business_hours_1': '営業時間①',
    'facility.business_hours_2': '営業時間②',
    'facility.take_over_operator': '事業者情報を引き継ぐ',
    'facility.fee': '手数料',
    'facility.other': 'その他',
    'facility.rate': '料率',
    'facility.question.confirm.delete': '選択した営業所・店舗を削除します。よろしいですか。',
    'facility.question.confirm.update': '営業所・店舗の情報を変更します。よろしいですか',
    'facility.question.confirm.create': '営業所・店舗を登録します。よろしいですか。',
    'facility.select_driver_name': 'ドライバー名選択（ドライバーApp）',
    'facility.countDownTime': '待機時間（秒）',
    'facility.countDownTime2': '待機時間',

    // Driver Registration page
    'driverStatus.currentlyEnrolled': '在籍中',
    'driverStatus.retired': '退職済',
    'driverStatus.closed': '休職中',
    'driverType.delivery': 'デリバリー',
    'driverType.move': '人の移動',
    'driver.partialMatch': '部分一致',
    'driver.title': 'ドライバー情報',
    'driver.create.title': 'ドライバー作成',
    'driver.detail.title': 'ドライバー詳細',
    'driver.title.detail': 'ドライバーフォーム',
    'driver.searchTitle': '検索条件',
    'driver.code': 'ドライバーコード',
    'driver.id': 'ドライバーID',
    'driver.name_search': '名前/名称',
    'driver.name': '名前（事業者様用）',
    'driver.status': '在籍ステータス',
    'driver.language': 'アプリの言語表示',
    'driver.type': '種別',
    'driver.facility.code': '営業所/店舗ID',
    'driver.facility.name': '営業所/店舗名',
    'driver.supplier.code': '事業者ID',
    'driver.supplier.name': '事業者名',
    'driver.gender': '性別',
    'driver.male': '男性',
    'driver.female': '女性',
    'driver.name_app': '名前（アプリ表示）',
    'driver.vehicle_binding': '車両紐づけ',
    'driver.reference': '参照',
    'driver.lang': '言語コード',
    'driver.defaultVehicle': '規定車両',

    // Product delivery Page
    'delivery.settingProductListTime': '商品リスト時間の設定',
    'delivery.listTimeSettingProduct': '設定を確認する',
    'delivery.title': '商品管理',
    'delivery.caution_title': '注意選択',
    'delivery.sort_column': '表示順',
    'delivery.product_id': '商品ID',
    'delivery.product_name': '商品名',
    'delivery.sale_status': '販売ステータス',
    'delivery.language': '言語',
    'delivery.sales_store': '営業所/店舗',
    'delivery.business_person': '事業者',
    'delivery.arrangement_method': '手配方法',
    'delivery.sales_contact': '営業所・連絡先',
    'delivery.sale_period': '販売期間',
    'delivery.sale_period_start': '商品販売期間 (から)',
    'delivery.sale_period_end': '商品販売期間 (まで)',
    'delivery.display_period': '表示期間',
    'delivery.display_period_start': '表示期間 (から)',
    'delivery.display_period_end': '表示期間 (まで)',
    'delivery.operating_time': '稼働時間',
    'delivery.operating_time_start': '稼働時間 (から)',
    'delivery.operating_time_end': '稼働時間 (まで)',
    'delivery.service_type': 'サービス種別',
    'delivery.product_type': 'デリバリー商品種別/中',
    'delivery.product_type_small': 'デリバリー商品種別/小',
    'delivery.basic_inform': '基本情報',
    'delivery.description': '商品説明',
    'delivery.product_photo_data': '製品写真データ',
    'delivery.reservation_date': '予約日',
    'delivery.cancellation_fee': '解約料',
    'delivery.information': '情報',
    'delivery.precautions': '予防策',
    'delivery.changer': 'チェンジャー',
    'delivery.update_date': '更新日',
    'delivery.created_date': '作成日',
    'delivery.author': '著者',
    'delivery.content': 'コンテンツ',
    'delivery.title_msg_label': '部分一致',
    'delivery.whether_sales_date_is_specified': '販売日指定の有無',
    'delivery.designated_sales_date_calendar': '販売日カレンダー',
    'deliveryStatus.sale': '販売中',
    'deliveryStatus.in_preparation': '準備中',
    'deliveryStatus.end_of_sale': '販売終了',
    'deliveryStatus.stopped': '停止中',
    'deliveryServiceType.on_demand': 'モビ',
    'deliveryServiceType.delivery': 'デリバリー',
    'deliveryServiceType.taxi': 'タクシー',
    'deliveryServiceType.qr': 'QRコード',
    'deliveryArrangement.money': '現金',
    'deliveryArrangement.WillerPay': 'ウィラーペイ',
    'delivery.specified': '販売日が指定されているかどうか',
    'delivery.storeCode': '営業所・店舗ID',
    'delivery.storeName': '営業所・店舗名',

    // Product vehicle Page
    'vehicles.title': '車両製品管理',
    'vehicles.sort_column': '表示順',
    'vehicles.product_id': '製品JD',
    'vehicles.product_name': '商品名',
    'vehicles.sales_status': '販売状況',
    'vehicles.sales_store_contact': '営業所・連絡先',
    'vehicles.sales_store': '営業所・店舗',
    'vehicles.sales_period': '販売期間',
    'vehicles.display_period': '表示期間',
    'vehicles.from': '(から)',
    'vehicles.to': '(まで)',
    'vehicles.operating_time': '稼働時間',
    'vehicles.product_type': '製品タイプ',
    'vehicles.description': '解説',
    'vehicles.product_photo_data': '製品写真データ',
    'vehicles.reservation_start_date': '予約日',
    'vehicles.reservation_date': '予約日',
    'vehicles.cancellation_fee': '解約料',
    'vehicles.information': '情報',
    'vehicles.precautions': '予防策',
    'vehicles.author': '著者',
    'vehicles.changer': 'チェンジャー',
    'vehicles.update_date': '更新日',
    'vehicles.created_date': '作成日',
    'vehicles.content': 'コンテンツ',
    'vehicles.title_msg_label': '部分一致',
    'vehicles.basic_inform': '基本情報',

    // Product Cancellation fee
    'cancellation_fee.title': '取消料管理',
    'cancellation_fee.titleSearch': '検索条件',
    'cancellation_fee.basic_info': '基本情報',
    'cancellation_fee.id': 'キャンセル料ID',
    'cancellation_fee.name': 'キャンセル料名',
    'cancellation_fee.period': '期間',
    'cancellation_fee.rate': '取消率/円',
    'cancellation_fee.number': '率',
    'cancellation_fee.grown_up': '大人',
    'cancellation_fee.children': '子供',
    'cancellation_fee.update': 'キャンセル料の更新',
    'cancellation_fee.create': 'キャンセル料を作成',
    'cancellation_fee.rateType.yen': '円',
    'cancellation_fee.rateType.percentage': '%',
    'cancellation_fee.fee_code': 'キャンセル料コード',
    'cancellation_fee.information': 'キャンセル料情報',
    'cancellation_fee.price': '価格',
    'cancellation_fee.from': '取消期間（から）',
    'cancellation_fee.to': '取消期間（まで）',
    'cancellation_fee.periodType.day': '日',
    'cancellation_fee.periodType.hour': '時間',
    'cancellation_fee.periodType.indefinite': '無期限',
    'cancellation_fee.periodType.reservationDay': '予約当日',
    'cancellation_fee.periodType.afterReservationDay': '出発後',
    'cancellation_fee.facility.id': '営業所・店舗ID',
    'cancellation_fee.facility.name': '営業所・店舗名',
    'cancellation_fee.add': '追加',
    'cancellation_fee.delete': '削除',
    'cancellation_fee.detailTitle': '取消料詳細設定',

    // Product Information fee
    'caution.id': '注意事項ID',
    'caution.name': '名称',
    'caution.display_time': '表示期間（から～まで）(UTC)',
    'caution.from_time': '表示期間（から）',
    'caution.to_time': '表示期間（まで)',
    'caution.content': 'コンテンツ',
    'caution.update': '注意事項詳細',
    'caution.create': '注意事項登録',
    'caution.item': 'アイテム',
    'caution.searchTitle': '注意事項情報',

    // Business Driver&Vehicle Association
    'driverAssociation.id': 'ドライバーID',
    'driverAssociation.name': 'ドライバー名',
    'driverAssociation.vehicle': '車両ナンバー',
    'driverAssociation.name.app': 'ドライバー名 (App)',
    'driverAssociation.name.ad': 'ドライバー名 (AD)',
    'driverAssociation.online': 'オンライン',
    'driverAssociation.offline': 'オフライン',
    'driverAssociation.activated_times': '使用時間',
    'driverAssociation.last_device_info': 'デバイス情報',
    'driverAssociation.os_version': 'OSバージョン',
    'driverAssociation.screen_size': '画面サイズ',
    'driverAssociation.delete_shift': 'シフトを削除',
    'driverAssociation.onDemandRealTime': 'オンデマンドリアルタイム予約',
    'driverAssociation.onDemandAdvance': 'オンデマンドリアルタイム予約・事前予約',

    // Product price calendar
    'calendar.month': '月',
    'calendar.years': '年',
    'calendar.Monday': '月曜日',
    'calendar.Tuesday': '火曜日',
    'calendar.Wednesday': '水曜日',
    'calendar.Thursday': '木曜日',
    'calendar.Friday': '金曜日',
    'calendar.Saturday': '土曜日',
    'calendar.Sunday': '日曜日',
    'calendar.Holiday': '休日',
    'calendar.csv_output': 'CSV出力',
    'calendar.csv_input': 'CSV入力',
    'calendar.csv_upload': 'CSVをアップロード',
    'calendar.csv_title': '※CSVは1年間登録できます',
    'calendar.price': '価格',
    'calendar.tax': '税金',
    'calendar.en': '円',
    'calendar.checkAll': 'すべてチェック',
    'calendar.batch_register_all': '料金 一括登録',
    'calendar.batch_register_all_tax': '料金税',
    'calendar.time_from': '時間/から 一括登録',
    'calendar.time_to': '時間/まで 一括登録',
    'calendar.all_day': '曜日一括',
    'calendar.jan': '一月',
    'calendar.feb': '二月',
    'calendar.march': '三月',
    'calendar.apr': '四月',
    'calendar.may': '五月',
    'calendar.june': '六月',
    'calendar.july': '七月',
    'calendar.aug': '八月',
    'calendar.sep': '九月',
    'calendar.oct': '十月',
    'calendar.nov': '十一月',
    'calendar.dec': '十二月',

    // Setting price taxi form
    'settingFeeTaxiForm.titleRegister': '設定料金タクシー登録',
    'settingFeeTaxiForm.titleDetails': '設定料金タクシー詳細',
    'settingFeeTaxiForm.form': '設定料金タクシーフォーム',
    'settingFeeTaxiForm.businessName': '事業者',
    'settingFeeTaxiForm.facilityName': '営業所・店舗名',
    'settingFeeTaxiForm.vehicleType': '車両種別',
    'settingFeeTaxiForm.general': '通常',
    'settingFeeTaxiForm.nightShift': 'ナイトシフト',
    'settingFeeTaxiForm.startingFee': 'スタート料金',
    'settingFeeTaxiForm.continuingFee': '続行料金',
    'settingFeeTaxiForm.minute': '分から/',
    'settingFeeTaxiForm.meter': 'Mから/',
    'settingFeeTaxiForm.yen': '円',
    'settingFeeTaxiForm.openingFee': '配車料金',
    'settingFeeTaxiForm.distanceUnit': '単位の長さ',
    'settingFeeTaxiForm.fee': '費用',
    'settingFeeTaxiForm.shiftTime': 'シフトタイム',
    'settingFeeTaxiForm.period': '限目',

    // One time price
    'oneTimePrice.informationTitle': 'ジオフェンスにより金額設定情報',
    'oneTimePrice.detailsTitle': 'ジオフェンスにより金額設定詳細',
    'oneTimePrice.registrationTitle': 'ジオフェンスにより金額設定作成',
    'oneTimePrice.form': 'ジオフェンスにより金額設定登録',
    'oneTimePrice.no': '番号',
    'oneTimePrice.geofence': 'ジオフェンス',
    'oneTimePrice.vehicle_group_type': '車両グループタイプ',
    'oneTimePrice.price': '金額',
    'oneTimePrice.total': '合計',
    'oneTimePrice.taxRate': '税率',
    'oneTimePrice.currency': '通貨',
    'oneTimePrice.paymentMethods': '支払い方法',
    'oneTimePrice.confirmRegister': 'ワンタイムを登録します。よろしいですか。',
    'oneTimePrice.confirmUpdate': 'ワンタイムを更新します。よろしいですか。',
    'oneTimePrice.adult_price': '大人の価格',
    'oneTimePrice.child_price': '子供の価格',
    'oneTimePrice.one_time_discount_error': 'このジオフェンスは同乗者割引チケットに設定されています。変更する前に、同乗者割引適用後の料金を一回払い料金より低く設定してください。',
    'oneTimePrice.plan_with_discount': '(プランコード: {{Id}}, 大人: {{Adult}}, 子供: {{Child}})',
    'oneTimePrice.operating_hour_geofence': 'ジオフェンス運行時間',
    'oneTimePrice.start_time': '適用開始時刻',
    'oneTimePrice.end_time': '適用終了時刻',
    'oneTimePrice.time_range': '時間帯',
    'oneTimePrice.overlap_time_range': '時間帯が重複しています',
    'oneTimePrice.adult_charge': '大人料金',
    'oneTimePrice.child_charge': '子ども料金',
    'oneTimePrice.require_charge': '大人・子どものいずれかに料金を設定ください',
    'oneTimePrice.clear_all_current_setting': 'すべての設定を削除しますか',
    'oneTimePrice.surcharge_setting': '加算料金設定',
    'oneTimePrice.additional_fee': '事前予約手数料',
    'oneTimePrice.time_range_surcharge_discount_setting': '時間帯割り増し - 時間帯割引',
    'oneTimePrice.setting_time_range_discount': '割引を設定したい場合は数字の前に（-）を入力してください',
    'oneTimePrice.all_time_ranges_discount_smaller_one_time_discounts':
      '時間帯割引を適用した後のワンタイム料金は同乗者割引料金を超える設定をしてください。',
    'oneTimePrice.time_range_discount_smaller_one_time_discount': '時間帯割引を設定する場合はワンタイム料金を超えないようにしてください',
    'oneTimePrice.time_range_discount_smaller_specical_fee': '時間帯割引の値は、特別乗客価格よりも小さくする必要があります。',
    'oneTimePrice.description_detail_price': '内訳料金の説明文',
    'oneTimePrice.description_onetime_banner': 'ワンタイム料金の説明文',
    'oneTimePrice.description_subscription_banner': '乗り放題料金の説明文',
    'oneTimePrice.description_ticket_banner': 'チケット料金の説明文',
    'oneTimePrice.time_range_surcharge_discount': '時間帯割り増し / 割引',
    'oneTimePrice.validatePaymentMethods': '支払い方法には現金またはクレジットカードが必要です',
    'oneTimePrice.special_passengers_fee_setting': '特別乗客価格設定',
    'oneTimePrice.name': '名前',
    'oneTimePrice.popup': 'ポップアップ',
    'oneTimePrice.popup_title': 'ポップアップタイトル',
    'oneTimePrice.popup_content': 'ポップアップコンテンツ',
    'oneTimePrice.setting': '設定',
    'oneTimePrice.type': 'タイプ',
    'oneTimePrice.surcharge_discount_adult': '大人と同等の割引割増料金を適用',
    'oneTimePrice.surcharge_discount_child': '子供と同等の割引割増料金を適用',
    'oneTimePrice.no_surcharge_discount': '割引割増料金を適用しない',
    'oneTimePrice.display': '表示フラグ',
    'oneTimePrice.description': '説明',
    'oneTimePrice.on_user_app': 'ユーザーアプリに表示',
    'oneTimePrice.charge': '価格',

    // congestion
    'congestion': '混雑',
    'congestion.detail': '詳細設定',
    'congestion.setting': '一括設定',
    'congestion.setting.1': 'すべての日',
    'congestion.setting.2': '曜日別',
    'congestion.setting.3': 'タイムゾーン別',
    'congestion.setting.4': '詳細設定',
    'congestion.1v': '混雑していない',
    'congestion.2v': '混雑していない',
    'congestion.3v': 'やや混んでいる',
    'congestion.4v': '混雑',
    'congestion.5v': 'とても混雑している',
    'congestion.1': '1',
    'congestion.2': '2',
    'congestion.3': '3',
    'congestion.4': '4',
    'congestion.5': '5',
    'congestion.status': '混雑状況登録ステータス',
    'congestion.status.1': '未登録',
    'congestion.status.2': '登録済み',

    // reservationManagement
    'reservationManagement.user_sub': 'ユーザーサブスクリプション',
    'reservationManagement.adult': '大人',
    'reservationManagement.child': '子ども',
    'reservationManagement.infant': '幼児',
    'reservationManagement.adult_one_time_discount': '割引適用大人',
    'reservationManagement.child_one_time_discount': '割引適用子供',
    'reservationManagement.pickup_address_search': 'ピックアップ住所',
    'reservationManagement.dropoff_address_search': 'ドロップオフ住所',
    'reservationManagement.pickup_date': 'ピックアップ予定日',
    'reservationManagement.pickup_date_from': 'ピックアップ予定日 (から)',
    'reservationManagement.pickup_date_to': 'ピックアップ予定日 (まで)',
    'reservationManagement.dropoff_date': 'ドロップオフ予定日',
    'reservationManagement.pickup_date_scheduled': 'ピックアップ予定時刻',
    'reservationManagement.dropoff_date_scheduled': 'ドロップオフ予定時刻',
    'reservationManagement.booking_flight_pickup_time': 'ピックアップ時刻',
    'reservationManagement.booking_flight_dropoff_time': 'ドロップオフ時刻',
    'reservationManagement.dropoff_date_from': 'ドロップオフ予定日 (から)',
    'reservationManagement.dropoff_date_to': 'ドロップオフ予定日 (まで)',
    'reservationManagement.sale_office': '営業所・店舗名',
    'reservationManagement.detail.1': '集荷元住所 & 集荷元電話番号',
    'reservationManagement.detail.2': '配達先住所 & 配達先電話番号',
    'reservationManagement.product_type': '商品種別',
    'reservationManagement.service_type': 'サービス種別',
    'reservationManagement.business_name': '事業者名',
    'reservationManagement.facility_name': '営業所・店舗名',
    'reservationManagement.company_name': '営業所/店舗名',
    'reservationManagement.reservation_date_from': '予約日 (から)',
    'reservationManagement.reservation_date_to': '予約日 (まで)',
    'reservationManagement.usage_date_from': '利用日 (から)',
    'reservationManagement.usage_date_to': '利用日 (まで)',
    'reservationManagement.booking_status': '予約ステータス',
    'reservationManagement.deposit_status': '入金ステータス',
    'reservationManagement.notification_complete': 'ユーザーに通知',
    'reservationManagement.message_fail_change_payment': 'クレジットカード決済に失敗しました。\nもう一度お試しいただくか、お支払い方法を変更してください。',
    'reservationManagement.completeReservation': 'ステータス変更処理',
    'reservationManagement.completeReservation.title': '乗車ステータスを完了に変更します。\nこの予約の支払方法を選択してください。',
    'reservationManagement.completeReservation.errorMessage': '支払方法がサポートされていないため、予約を完了できません',
    'reservationManagement.notificationMethod.operatorCancel': '乗車ステータスをオペレーターキャンセルに変更します。',
    'reservationManagement.notificationMethod.subTitle': 'ユーザーに通知（Thank you画面など）を送りますか？ ',
    'reservationManagement.member_name_csv': 'ユーザー名（旧システム）',
    'reservationManagement.reservation_number': 'メールアドレス',
    'reservationManagement.reservation_number_search': '予約番号',
    'reservationManagement.payment_id': '決済ID',
    'reservationManagement.get_onoff_pairkey': '乗-下車ペアキー',
    'reservationManagement.firstName_csv': '名',
    'reservationManagement.lastName_csv': '姓',
    'reservationManagement.member_type': '会員タイプ',
    'reservationManagement.product_name': '商品名',
    'reservationManagement.name': 'ユーザー名',
    'reservationManagement.booker_information': '予約者情報',
    'reservationManagement.booker_name': '予約者名',
    'reservationManagement.representative_name': '乗客名',
    'reservationManagement.representative_infor': '乗客情報',
    'reservationManagement.product_reservation_number': '商品予約番号',
    'reservationManagement.reservation_date': '予約日',
    'reservationManagement.the_date_of_use': '利用日',
    'reservationManagement.categories': '商品区分',
    'reservationManagement.dropoff_address': 'ドロップオフ住所',
    'reservationManagement.dropoff_time': 'ドロップオフ予定時間',
    'reservationManagement.dropoff_time_search': '降車最終予定時刻',
    'reservationManagement.get_on_agency_name': '乗車地運営会社',
    'reservationManagement.get_off_agency_name': '下車地運営会社',
    'reservationManagement.exportAlert': 'ダウンロード件数が1000件以上です。ダウンロードに時間がかかりますが、よろしいですか？',
    'reservationManagement.exportAlertLimit': '検索結果が多すぎます。\n検索条件を絞り込んで再検索してください。\n50,000件以下に絞り込んで下さい。',
    'reservationManagement.exportAlert.limitRequest': '現在、同時にダウンロードしているユーザーが多すぎるため、しばらく待ってからダウンロードしてください。',
    'reservationManagement.exportAlert.narrowDateFromTo': '予約日・ピックアップ予定日・ドロップオフ予定日は、1年以内に絞り込んでください。',
    'reservationManagement.get_on_busstop_code': '乗車駅コード',
    'reservationManagement.get_off_busstop_code': '下車駅コード',
    'reservationManagement.get_on_use_date': '乗車日付',
    'reservationManagement.get_off_use_date': '下車日付',
    'reservationManagement.get_on_flag': '乗車フラグ',
    'reservationManagement.get_off_flag': '下車フラグ',
    'reservationManagement.payment_time': '決済時間',
    'reservationManagement.qr_fee': '料金',
    'reservationManagement.order_id': '決済ＩＤ',
    'reservationManagement.qr_payg_id': 'QR PaygＩＤ',
    'reservationManagement.get_on_stop_name': '乗車駅名',
    'reservationManagement.get_off_stop_name': '下車駅名',
    'reservationManagement.pickup_location_booking_jit': 'ピックアップ場所',
    'reservationManagement.dropoff_location_booking_jit': 'ドロップオフ場所',
    'reservationManagement.select_flight': '便を選択 ',
    'reservationManagement.select_the_ride': 'ご希望の便を選択してください',
    'reservationManagement.booking_deadline': '予約締切日時',
    'reservationManagement.available_seats': ' 残席数',
    'reservation.arrival': '着',
    'reservation.departure': '発',
    'reservation.booking.0': '仮予約（JITのみ)',
    'reservation.booking.1': 'ユーザー予約',
    'reservation.booking.2': 'オペレーター予約',
    'reservation.booking.3': 'ユーザーキャンセル',
    'reservation.booking.4': 'オペレーターキャンセル',
    'reservation.booking.5': 'ドライバーキャンセル',
    'reservation.booking.6': 'システムキャンセル',
    'reservation.booking.7': '完了',
    'reservation.booking.8': '新規',
    'reservation.booking.9': '予約不成立',
    'reservation.booking.10': 'ユーザー予約（未乗車）',
    'reservation.booking.11': 'ユーザー予約（乗車済み）',
    'reservation.booking.12': 'オペレーター予約（未乗車）',
    'reservation.booking.13': 'オペレーター予約（乗車済み）',
    'reservation.booking.14': 'オペレーター完了',
    'reservation.booking.15': '予約',
    'reservation.delivery.1': '未入金',
    'reservation.delivery.2': '入金済',
    'reservation.delivery.3': '未返金',
    'reservation.delivery.4': '返金済',
    'reservation.mobi.1': 'オンデマンド',
    'reservation.mobi.2': 'JIT',
    'reservationManagement.registration_number': '登録番号',
    'reservationManagement.vehicle_id': '車輛コード',
    'reservationManagement.vehicle_no': '車輛番号',
    'reservationManagement.driver_rating': '評価点数',
    'reservationManagement.description_rating': '評価時のコメント',
    'reservationManagement.driver_average_rating': 'ドライバーの平均評価',
    'reservationManagement.applicable_plan': '適用プラン',
    'reservationManagement.number_of_adults': '大人人数',
    'reservationManagement.number_of_children': '小児人数',
    'reservationManagement.number_of_subscribers': 'サブスク人数',
    'reservationManagement.payment_method': '決済方法',
    'reservationManagement.amount': '決済金額',
    'reservationManagement.desired_pickup_date_and_time': '乗車希望時刻',
    'reservationManagement.pickup_final_presentation_date_and_time': '乗車最終提示時刻',
    'reservationManagement.dropoff_final_presentation_date_and_time': '降車最終提示時刻',
    'reservationManagement.pickup_date_time': '乗車実績時刻',
    'reservationManagement.dropoff_date_time': '降車実績時刻',
    'reservationManagement.estimated_pickup_date_and_time': '乗車初回予定時刻',
    'reservationManagement.estimated_dropoff_date_and_time': '降車初回予定時刻',
    'reservationManagement.cancellation_time': 'キャンセル時刻',
    'reservationManagement.pickup_address_csv': '乗車waypoint',
    'reservationManagement.dropoff_address_csv': '降車waypoint',
    'reservationManagement.pickup_waypoint_latitude': '乗車waypoint緯度',
    'reservationManagement.pickup_waypoint_longitude': '乗車waypoint経度',
    'reservationManagement.dropoff_waypoint_latitude': '降車waypoint緯度',
    'reservationManagement.dropoff_waypoint_longitude': '降車waypoint経度',
    'reservationManagement.number_of_carpools': '相乗り件数',
    'reservationManagement.number_of_people': '相乗り人数',
    'reservationManagement.number_of_stops_before_pickup': '乗車前停車回数',
    'reservationManagement.number_of_stops_while_boarding': '乗車中停車回数',
    'reservationManagement.number_of_chats_user': 'チャット回数（USER）',
    'reservationManagement.number_of_chats_driver': 'チャット回数（DRIVER）',
    'reservationManagement.number_of_calls_completed': '通話完了回数',
    'reservationManagement.number_of_lost_calls_user': '通話不通回数（USER）',
    'reservationManagement.number_of_lost_calls_driver': '通話不通回数（DRIVER）',
    'reservationManagement.subscription_id': 'プラン予約番号',
    'reservationManagement.plan_type': 'プラン種別',
    'reservationManagement.plan_status': 'プランステータス',
    'reservationManagement.enrollment_date': '購入日時',
    'reservationManagement.free_period_start_date': '無料期間開始日',
    'reservationManagement.free_period_end_date': '無料期間終了日',
    'reservationManagement.payment_date_end_time': '決済日時',
    'reservationManagement.update_off_date': '更新off日時',
    'reservationManagement.automatic_updating': '自動更新',
    'reservationManagement.payment_status': '決済ステータス',
    'reservationManagement.payment_code': '決済コード',
    'reservationManagement.family_member_1': '家族会員1',
    'reservationManagement.family_member_2': '家族会員2',
    'reservationManagement.family_member_3': '家族会員3',
    'reservationManagement.family_member_4': '家族会員4',
    'reservationManagement.family_member_5': '家族会員5',
    'reservationManagement.family_member_6': '家族会員6',
    'reservationManagement.for_coupon_tickets': '回数券対象',
    'reservationManagement.number_of_tickets': '回数券枚数',
    'reservationManagement.remaining_number_of_coupon_tickets': '回数券残枚数',
    'reservationManagement.age': '年齢',
    'reservationManagement.email_address': 'メールアドレス',
    'reservationManagement.created_by': 'created_by',
    'reservationManagement.canceled_by': 'canceled_by',
    'reservationManagement.rating_csv_output': '評価項目CSV',
    'reservationManagement.rating': '評価',
    'reservationManagement.rating_text': '評価テキスト',
    'reservationManagement.reservationInformation': '予約情報',
    'reservationManagement.operationCompany': '運行会社',
    'reservationManagement.reservationTime': '予約日時',
    'reservationManagement.companyName': '運行会社名',
    'reservationManagement.routeName': '路線名',
    'reservationManagement.pickupTime': '乗車時刻',
    'reservationManagement.dropOffTime': '降車時刻',
    'reservationManagement.busRoute': '路線',
    'reservationManagement.busReservationDateTime': '予約日時',

    // reservationManagement detail
    'reservationManagement.usage_status': '利用ステータス',
    'reservationManagement.use_start_date': '利用開始日時',
    'reservationManagement.use_end_date': '利用終了日時',
    'reservationManagement.member_information': 'お客様情報',
    'reservationManagement.delivery_status': '配達ステータス',
    'reservationManagement.booking_detail': '予約詳細',
    'reservationManagement.basic_information': '基本情報',
    'reservationManagement.reservation_date_and_time': '予約時刻',
    'reservationManagement.reservation_confirm_date_and_time': '予約確定時刻',
    'reservationManagement.reservation_reception_deadline': '予約受付締め切り時刻（JIT）',
    'reservationManagement.cancellation_date_and_time': 'キャンセル日時',
    'reservationManagement.date_of_use_start': '利用（開始）日時',
    'reservationManagement.total_amount_paid': '合計入金済額',
    'reservationManagement.membership_information': '会員情報',
    'reservationManagement.membership_number': '会員番号',
    'reservationManagement.memberName': 'ユーザー名',
    'reservationManagement.sex': '性別',
    'reservationManagement.birthday': '生年月日',
    'reservationManagement.current_age': '年齢',
    'reservationManagement.change_mail_address': 'メールアドレス',
    'reservationManagement.delivery_collection_delivery_service': 'デリバリー・集荷・配達サービス',
    'reservationManagement.number': '個数',
    'reservationManagement.price': '代金',
    'reservationManagement.per_price': '(1個あたり）',
    'reservationManagement.service_providing_company_name': '(サービス提供会社名）',
    'reservationManagement.collection_origin_address': '集荷元住所',
    'reservationManagement.collection_origin_telephone_number': '集荷元電話番号',
    'reservationManagement.delivery_address': '配達先',
    'reservationManagement.delivery_phone_number': '配達先電話番号',
    'reservationManagement.collection_date_and_time': '集荷日時',
    'reservationManagement.scheduled_delivery_date': '配達予定日時',
    'reservationManagement.delivery_completion_date_and_time': '配達完了日時',
    'reservationManagement.taxi_on_demand_service': 'タクシー・オンデマンドサービス',
    'reservationManagement.taxi_jit_service': 'タクシー・MOBI',
    'reservationManagement.number_of_users': '利用人数',
    'reservationManagement.per_person': '（一人あたり）',
    'reservationManagement.pickup_address': 'ピックアップ場所',
    'reservationManagement.drop_off_address': 'ドロップオフ場所',
    'reservationManagement.scheduled_pickup_time': 'ピックアップ予定時間',
    'reservationManagement.scheduled_drop_off_time': 'ドロップオフ予定時間',
    'reservationManagement.pickup_time': 'ピックアップ済時間',
    'reservationManagement.pickup_time_search': '乗車最終予定時刻',
    'reservationManagement.drop_off_time': 'ドロップオフ予定時刻',
    'reservationManagement.new_drop_off_time': 'ドロップオフ済時間',
    'reservationManagement.cancellation_information_rules': '取消情報・ルール',
    'reservationManagement.cancellation_fee': '取消料',
    'reservationManagement.deposit_information': '入金情報',
    'reservationManagement.deposit_method': '入金手段',
    'reservationManagement.total_billed_amount': '合計金額',
    'reservationManagement.deposited_amount': '入金済金額',
    'reservationManagement.refunded_amount': '返金済金額',
    'reservationManagement.passenger': '人数',
    'reservationManagement.number_passenger': '乗車人数',
    'reservationManagement.people': '名',
    'reservationManagement.settlement_date': '決済日時',
    'reservationManagement.refund_method': '返金手段',
    'reservationManagement.refund_date_and_time': '返金日時',
    'reservationManagement.internal_memo': '社内メモ',
    'reservationManagement.business_memo': '事業者メモ',
    'reservationManagement.up_to_characters': '（xxxx文字以内）',
    'reservationManagement.update': '更新',
    'reservationManagement.cancellation_of_arrangement_contents': '手配内容の取消',
    'reservationManagement.chat_history': 'チャット履歴',
    'reservationManagement.no_message_yet': '履歴がありません',
    'reservationManagement.loading': '読み込み中',
    'reservationManagement.voice_chat': 'ボイスチャット',
    'reservationManagement.cancel_call': 'キャンセルされた通話',
    'reservationManagement.miss_call': '不在着信',
    'reservationManagement.polyline_tracking': 'ポリライン追跡',
    'reservationManagement.no_polyline_data': 'ポリラインデータなし',
    'reservationManagement.estimated_pickup_time': '当初のピックアップ予定時刻',
    'reservationManagement.estimated_drop_off_time': '当初のドロップオフ予定時刻',
    'reservation.booking_jit_nodata': '便がありません。',
    'reservationManagement.creditCardRetry': 'クレジットカードトライ',
    'reservationManagement.otherPaymentRetry': '入金ステータスの更新',
    'card.not.found': 'カードが見つかりません',
    'amount.not.support': 'サポートされていない金額',
    'reservationManagement.message.creditCardError': '決済は失敗しました。もう一度お試しください。',
    'reservationManagement.update_memo_modal': '予約のメモを変更します。よろしいですか。',
    'reservationManagement.number_of_users_origin': '利用人数（初回）',
    'reservationManagement.number_of_users_latest': '利用人数（最終）',
    'reservationManagement.amount_origin': '金額（初回）',
    'reservationManagement.amount_latest': '金額（最終）',

    // create reservation
    'reservationManagement.create_reservation': '空席検索',
    'reservationManagement.store_sales_office': '店舗・営業所',
    'reservationManagement.date_and_time_of_use': '利用日時',
    'reservationManagement.gender': '(性別)',
    'reservationManagement.choose_a_location_on_the_map': '地図上の場所を選択してください',
    'reservation.please_select_router': '上記の中から移動ルートを選択してください',
    'reservation.error_waypoint_not_associate': '検索した乗降地が不可になりました。乗降地を変更してください。',
    'reservationManagement.service_time': 'サービス時間',
    'reservationManagement.density_rate': 'ソーシャルディスタンス',
    'reservationManagement.detail': '詳細',
    'reservationManagement.product_information': '商品情報',
    'reservationManagement.items': 'アイテム',
    'reservationManagement.quantity': '量',
    'reservationManagement.order_details': 'ご注文内容',
    'reservationManagement.change_order': '商品をタップすると注文内容を変更できます',
    'reservationManagement.view_cart': 'かごの中身を見る',
    'reservationManagement.btnSwap': '入り替え',
    'reservationManagement.discount_target': '割引適用対象',
    'reservationManagement.amount_after_discount': '割引後の金額',
    'reservationManagement.applied_number': '割引適用人数',
    'reservationManagement.one_time_discount': '同乗者割引',
    'reservationManagement.max_number_people': '割引適用最大人数',
    'reservationManagement.remaining_number_people': '割引適用残り人数',
    'reservationManagement.discountable_per_tiket': '1枚あたり{{person}}名割引',
    'reservationManagement.plan_out_of_service': 'このプランは提供時間外です',
    'reservationManagement.unlimitedRides': '乗り放題（家族オプションも含む）',
    'reservationManagement.unitCost': '単価',
    'reservationManagement.peopleTypeAmount': '料金',
    'reservationManagement.partnerDiscount': 'パートナー割引',
    'reservationManagement.total': '合計',
    'reservationManagement.totalDiscount': '合計割引',
    'reservationManagement.finalTotal': 'お支払い料金',

    // new Reservation
    'newReservation.dateTime': '予約日時',
    'newReservation.number': '予約番号',
    'newReservation.orderer': '注文者',
    'newReservation.totalFee': '金額',
    'newReservation.registerer': '予約登録者',
    'newReservation.cardNumber': 'カード番号',
    'newReservation.cardCompany': 'カード会社',
    'newReservation.expiredDate': '有効期限',
    'newReservation.month': '月',
    'newReservation.year': '年',
    'newReservation.back': '戻る',
    'newReservation.confirm': '確認',
    'newReservation.complete': '決済の登録が完了しました',
    'newReservation.operator': 'オペレータ',
    'newReservation.linkMap1': '現在位置を地図で確認',
    'newReservation.linkMap2': '場所を地図で確認',
    'newReservation.polyline_for_booking': '予約用のポリライン',

    // taxi on-demand
    'reservationManagement.taxi_on_demand': 'タクシー/オンデマンド',
    'reservationManagement.early_order': '早い順',
    'reservationManagement.cheapest': '安い順',
    'reservationManagement.easy_order': '楽な順',
    'reservationManagement.shuttle': 'シャトル',
    'reservationManagement.charge_forecast': '料金予想',
    'reservationManagement.estimated_required_time': '想定所要時間',
    'reservationManagement.taxi': 'タクシー',
    'reservationManagement.fee': '料金',
    'reservationManagement.time_required': '所要時間',
    'reservationManagement.number_transfer': '乗換回数',
    'reservationManagement.counts': '回',
    'reservationManagement.route': 'ルート',
    'reservationManagement.present_location': '現在地',
    'reservationManagement.waiting_time': '待ち時間',
    'reservationManagement.pick_up_time_varies': 'お迎え時間に幅があります',
    'reservationManagement.pick_up_time': 'ピックアップ予定時刻',
    'reservationManagement.arrival_time': '到着時間',
    'reservationManagement.dankai_bus': '丹海バス',
    'reservationManagement.departure_time': '出発時間',
    'reservationManagement.call_a_taxi': 'タクシーを呼ぶ',
    'reservationManagement.miyazu_station': '宮津駅',

    // Reservation delivery
    'reservationManagement.delivery': '配達',
    'reservationManagement.restaurant': 'レストラン',
    'reservationManagement.grocery_store': '食料品・スーパー',
    'reservationManagement.pharmacy_drugstore': '薬局・ドラッグストア',
    'reservationManagement.near_by_grocery_store': '近くの食料品店',
    'reservationManagement.restaurant_detail': 'レストランの詳細',
    'reservationManagement.supermarket': 'スーパーマーケット',
    'reservationManagement.restaurant_name': 'スーパーマーケットにしがき大宮バイパス店',
    'reservationManagement.restaurant_description': `スーパーにしがきは、丹後・舞鶴・豊岡エリアで安全・安心
      な食品を提供するスーパーマーケットです。地元の食品はも
      ちろん、添加物を最大限除去した自社開発・製造の食品、日
      本世界各地から美味しくて安全安心な食品をお届けします。`,
    'reservationManagement.if_you_order_now': 'いま注文すると',
    'reservationManagement.delivery_to': 'にお届け',
    'reservationManagement.social_distance': 'ソーシャルディスタンス',
    'reservationManagement.check_store': '店舗をチェック',
    'reservationManagement.vegetables': '野菜',
    'reservationManagement.fruit': '果物',
    'reservationManagement.meat': '肉',
    'reservationManagement.item_1': 'きのこ',
    'reservationManagement.item_name_1': '福岡県産 ホクトぶなしめじブナピー1パック',
    'reservationManagement.item_name_2': '国内産 このまま使えるカットえのき茸 1パック 140g',
    'reservationManagement.item_name_3': '長崎県産 サン・グリーンズ 菌床栽培 肉厚しいたけ 1パック 270g',
    'reservationManagement.customer_info': '顧客情報入力',
    'reservationManagement.customer_confirm': '予約確認',
    'reservationManagement.passenger_detail': '乗車者情報',
    'reservationManagement.member_information_selection': '会員情報選択',
    'reservationManagement.for_member': '（会員向け）',
    'reservationManagement.name_furigana': 'ふりがな',
    'reservationManagement.tel': 'TEL',
    'reservationManagement.member_id': 'ユーザーコード',
    'reservationManagement.used_subscription': 'サブスクリプションを使う',
    'reservationManagement.subscriptions': ' 乗り放題',
    'reservationManagement.subscriptions_main_user': '代表者',
    'reservationManagement.subscriptions_using': '利用者',
    'reservationManagement.name_info': '部分一致/漢字・ひら・カタ',
    'reservationManagement.confirmation': '確認',
    'reservationManagement.date_and_time_of_us': '私たちの日時',
    'reservationManagement.boarding_place': '搭乗場所',
    'reservationManagement.get_off_place': 'Get off place',
    'reservationManagement.boarding_datetime': 'Boarding datetime',
    'reservationManagement.boarding_datetime_from': 'Boarding datetime (from)',
    'reservationManagement.boarding_datetime_to': 'Boarding datetime (to)',
    'reservationManagement.get_off_datetime': 'Get off datetime',
    'reservationManagement.get_off_datetime_from': 'Get off datetime (from)',
    'reservationManagement.get_off_datetime_to': 'Get off datetime (to)',
    'reservationManagement.upload_csv': 'Upload CSV',
    'reservationManagement.download_csv': 'Download CSV',
    'reservationManagement.number_people': '人数',
    'reservationManagement.status': 'ステータス',
    'reservationManagement.destination': '先',
    'reservationManagement.amount_of_money': '料金',
    'reservationManagement.customer_information': '顧客情報',
    'reservationManagement.select_member_info': '会員情報を選択',
    'reservationManagement.book': '予約',
    'reservationManagement.send_mail': 'メールを送る',
    'reservationManagement.loginId': 'ログインID',
    'reservationManagement.member': 'メンバー',
    'reservationManagement.departure_taxi': '出発地を選択してください',
    'reservationManagement.departure_delivery': '店舗を選択してください',
    'reservationManagement.destination_taxi': '目的地を選択してください',
    'reservationManagement.destination_delivery': '配達場所を選択してください',
    'reservationManagement.next': '次のページ',
    'reservationManagement.search': '検索',
    'reservationManagement.cancel': 'キャンセル',
    'reservationManagement.reservation': '予約',
    'reservationManagement.absent': '欠席',
    'reservationManagement.take_out_office': 'テイクアウトオフィス',
    'reservationManagement.pickup_location': '乗車場所',
    'reservationManagement.dropoff_location': '目的地',
    'reservationManagement.message_not_enough_seats_left': '残席数が足りません。',
    'reservationManagement.no_result': '該当なユーザーが見つかりません',
    'reservationManagement.ride_rate': '乗車率',
    'reservationManagement.estimate_on_board': '想定乗車数',
    'reservationManagement.discount_percent': '割引率',
    'reservationManagement.discount_price': '割引金額',
    'reservationManagement.fee_breakdown': '料金内訳',
    'reservationManagement.family_subscription_price': 'mobi 定額乗り放題 対象者 （家族オプションも含む）',
    'reservationManagement.in_plan': '定額に含む',
    'reservationManagement.onetime_pride': 'mobi ワンタイム乗車',
    'reservationManagement.discount': '割引',
    'reservationManagement.total_amount': '料金合計',
    'reservationManagement.mobi_type': 'モビタイプ',
    'reservationManagement.jit_pride': 'JIT 乗車',
    'reservationManagement.search_route': '経路を検索',
    'reservationManagement.estimate_contact_time': '予約締切日時',
    'reservationManagement.infantsCanBeAdd': '※大人/子ども1名につき幼児を2名まで追加できます',
    'reservationManagement.maxNumberOfPassenger': '※このジオフェンスの最大予約人数は{{maxPassenger}}名です',
    'reservationManagement.seats': '（席数）',
    'reservationManagement.expectPickUpTime': '乗車希望時刻',
    'reservationManagement.driverNameDefault': 'ドライバー名 (ディフォルト)',
    'reservationManagement.driverNameSelectApp': 'ドライバー名（アプリで選択）',

    // waypoint association
    'reservationManagement.reset_waypoint': 'ウェイポイントリセット',
    'reservationManagement.drop_off_not_associated': '現在選択されている乗車地からは、こちらのwaypointは選択できません',
    'reservationManagement.pickup_not_associated': '現在選択されている降車地からは、こちらのwaypointは選択できません',
    'reservationManagement.pickup_not_associated_drop_off': 'ご指定頂いたmobiスポットでは 予約できません',
    'reservationManagement.location_is_not_support': 'こちらの場所を指定できません',

    // Advance booking
    'reservationManagement.advance.select.ride': '乗車オプション選択',
    'reservationManagement.advance.pickup.date': '乗車日',
    'reservationManagement.advance.pickup.time': '乗車時刻',
    'reservationManagement.advance.pickup.time.not.past': '乗車時刻は現在時刻以降を指定してください',
    'reservationManagement.advance.booking.type': '予約タイプ',
    'reservationManagement.advance.booking.type.real-time': 'リアルタイム予約',
    'reservationManagement.advance.booking.type.advance': '事前予約',
    'reservationManagement.advance.no.offer': '現在ご利用いただけるライドはありません。あとでもう一度お試しいただくか、予約情報を変更してお試しください。',
    'reservationManagement.advance.route.information': '経路情報',
    'reservationManagement.advance.ride.options': '乗車オプション',
    'reservationManagement.advance.estimated.pickup.time': '到着予定時刻',
    'reservationManagement.advance.estimated.dropoff.time': '降車予定時刻',
    'reservationManagement.advance.adult.one-time.price': '大人{{passenger}}名の場合（事前予約手数料、{{price}}/人を含む）',
    'reservationManagement.advance.one_time_fee': '事前予約手数料、{{fee}}/人を含む',
    'reservationManagement.advance.one_time_fee_adult_child': '事前予約は大人1人{{fee_adult}}、子ども1人{{fee_child}}の追加料金がかかります',
    'reservationManagement.advance.valid_operating_of_plan': 'このプランは {{operating_hour}} から有効です',
    'reservationManagement.advance.booking_fee': '事前予約手数料',
    'reservationManagement.advance.booking_cost': '事前予約手数料',
    'reservationManagement.advance.available_booking_time': 'ご予約可能時間 ',
    'reservationManagement.advance.pickup_time_outside_available': '選択した乗車日時は予約可能時間外です',
    'reservationManagement.advance.booking_in_tejimai_time': '現在時刻より{{minutes}}分後以降のみ予約可能',
    'reservationManagement.one_time_surcharge': '選択した乗車時間に時間帯割り増し料金が適用されます(大人{{surcharge_adult}}、子ども{{surcharge_child}})',
    'reservationManagement.including_time_range_surcharge': ' 時間帯割り増し（一人{{surcharge_fee}}）',
    'reservationManagement.including_time_range_discount': '時間帯割り引き（一人{{surcharge_fee}}）',
    'reservationManagement.time_range_surcharge': 'この時間（{{from}} - {{to}}）は時間帯割り増し料金がかかります',
    'reservationManagement.apply_time_range_surcharge': '只今の時間は割増料金が設定されています',
    'reservationManagement.surcharge': '時間帯割り増し料金',
    'reservationManagement.infantInvalid': '幼児は少なくとも1人の大人に付き添われる必要があります。',

    // Reservation message
    'reservationManagement.out_geofence': 'ご指定の場所はエリア外です',
    'successful_booking': '予約成功',
    'booking_failed': '予約に失敗しました',
    'booking.time.so.far': 'これまでの予約時間',
    'booking.retry': '失敗しました。再試行しますか？',
    'reservationManagement.select_member': '指定した人数が登録された人数を超えます。再度指定してください。',
    'reservationManagement.select_main_user': 'サブスクリプションを使用している人をメインユーザーとして選択してください。',
    'reservationManagement.no_rating': 'この乗車の評価はまだありません。',
    'mass.shift.not.available.for.booking': 'シフトは予約できなくなりました。',
    'mass.booking.not.support.booking.twice': '2回の予約はサポートされていません',
    'mass.booking.overlaps.time.with.another': '2件の重複した予約はサポートされていません',
    'mass.booking.time.so.far': '現時点では時間指定による予約はサポートされていません。サポートについてはモデレータに連絡してください',
    'mass.admin.booking.exceed.max.number.of.booking.per.day': 'この日、ユーザーは {{maxNumberOfBookings}} 件の予約をしました。 もう一度試してください。',
    'mass.booking.no.available.seat': 'この予約には空席がありません。 もう一度お試しください。',
    'mass.booking.exceed.offers.timeout': '予約情報が変更されました。 もう一度お試しください。',

    // Delivery time setting
    'deliverySetting.list_delivery': 'デリバリー受付時間一覧',
    'deliverySetting.collect_setting': '一括設定',
    'deliverySetting.calendar_detail_setting': 'カレンダー詳細設定',
    'deliverySetting.acceptance_time': '配達受付時間',
    'deliverySetting.status.unregister': '未登録',
    'deliverySetting.status.customize': '個別設定',

    // QR Payment
    'qr_payment.confirm_delete_items': '{{numSelected}} つのアイテムを削除しますか？',
    'qr_payment.confirm_delete_item': 'このアイテムを削除しますか？',
    'qrPayment.list_error_qr_payment': 'リストエラーQR支払い',
    'qrPayment.use_date': 'QR使用日',
    'qrPayment.payg_id': 'PaygＩＤ',
    'qrPayment.qr_pair_key': 'QRペイメントペアキー',
    'qrPayment.stop_name': 'QR支払い停止名',
    'qrPayment.user_email': 'QRペイメントユーザーのメール',
    'qrPayment.on_of_lag': 'QR支払いオンオフフラグ',
    'qrPayment.details_error_qr_payment': 'QR支払いの詳細',
    'qrPayment.user_name': 'QR決済ユーザー名',
    'qrPayment.user_uuid': 'QR決済ユーザーUUID',
    'qrPayment.nodata': 'データなし',

    // simulation management page
    'simulationManagement.title': 'シミュレーション管理',
    'simulationManagement.titleSearch': '検索条件',
    'simulationManagement.vehicleGroupType': '車両グループタイプ',
    'simulationManagement.simulation_id': 'シミュレーションＩＤ',
    'simulationManagement.service_group_id': 'グループサービスＩＤ',
    'simulationManagement.service_id': 'サービスIＤ',
    'simulationManagement.allow_vehicle_late': 'レーテンシー許容',
    'simulationManagement.enable_curb': '縁石を有効にする',
    'simulationManagement.detail': 'シミュレーション詳細',
    'simulationManagement.information': 'シミュレーション情報',
    'simulationManagement.clone': 'シミュレーションクローン',
    'simulationManagement.start_time': '開始時間',
    'simulationManagement.end_time': '終了時間',
    'simulationManagement.time': '開始時間-終了時間',
    'simulationManagement.old_simulation_id': '旧シミュレーションID',
    'simulationManagement.force': '力',
    'simulationManagement.transportation_type': '交通手段の種類',
    'simulationManagement.vehicle_capacity': '車両容量',
    'simulationManagement.max_additional_journey_time': '最大追加所要時間',
    'simulationManagement.max_additional_journey_time_percent': '最大追加所要時間(%)',
    'simulationManagement.max_walking_distance': '最長歩行距離',
    'simulationManagement.description': '説明',
    'simulationManagement.confirm_delete': 'このシミュレーションを削除しますか。',
    'simulationManagement.limit_user_booking': '要約制限',
    'simulationManagement.manual_manage_transit_stop': '停留所の手動管理',
    'simulationManagement.waypoint_mode': '経由点モード',
    'simulationManagement.nearest_distance': '最寄距離',
    'simulationManagement.enable_waypoints_cache': 'ウェイポイント キャッシュを有効にする',
    'simulationManagement.door_to_door_mode': 'ドアツードアモード',
    'simulationManagement.acceptable_waiting_time': '許容待機時間',
    'simulationManagement.pickup_service_time': 'Pickup service time',
    'simulationManagement.dropoff_service_time': 'Dropoff service time',
    'simulationManagement.shrink_time_delta': 'Shrink time delta',
    'simulationManagement.reverse_shrink_time_delta': 'Reverse Shrink time delta',
    'simulationManagement.offer_auto_cancellation_timeout': 'Offer auto cancellation timeout',
    'simulationManagement.stop_set_id': 'Stop set ID',
    'simulationManagement.start_time_end_time_limitations': 'Start time - End time limitations',
    'simulationManagement.currency_code': 'Currency code',
    'simulationManagement.payment_support': 'Payment support',
    'simulationManagement.willer_stop_set': 'WILLER停車場所セット',
    'simulationManagement.swat_transit_stop_set_id': 'SWAT停車場所セットID',
    'simulationManagement.display_name': '表示名',
    'simulationManagement.performance_tracker_enabled': 'パフォーマンストラッカーが有効',
    'simulationManagement.check_simulation_swat': 'シミュレーションSWATを確認してください',
    'simulationManagement.max_pool_size': '最大プールサイズ',
    'simulationManagement.trip_cost': '旅費',
    'simulationManagement.time_factor': 'Time factor',
    'simulationManagement.waiting_time_cost_factor': '待機時間のコスト要因',
    'simulationManagement.vehicle_mode': '車両モード',
    'mass.clone.simulation.failed': 'クローンシミュレーションが失敗しました',
    'mass.service.not.found': 'サービスが見つかりません',
    'simulationManagement.clone.failedProcess': 'クローン作成プロセスはまだ完了していません。続行するには[はい]をクリックしてください。',
    'simulationManagement.show_walking_result': 'Show walking result',
    'simulationManagement.show_waypoint_in_map': 'Show waypoint in map',
    'simulationManagement.use_path_equalizer': 'Use path equalizer',

    // shuttle management
    'shuttleSetting.headerTitle': 'シャトル管理',
    'shuttleSetting.searchTitle': '検索条件',
    'shuttleSetting.header': 'ヘッダー',
    'shuttleSetting.title': 'タイトル',
    'shuttleSetting.description': '説明',
    'shuttleSetting.createTitle': '右メニュー設定登録',
    'shuttleSetting.detailTitle': '詳細',
    'shuttleSetting.formTitle': '右メニュー設定フォーム',
    'shuttleSetting.question.confirm.create': 'シャトル設定の質問確認作成',
    'shuttleSetting.question.confirm.update': 'シャトルを更新したい',

    // Geofences & services
    'geofence.none': 'なし',
    'geofence.geofence_id': 'ジオフェンスID',
    'geofence.status': 'ジオフェンスのステータス',
    'geofence.simulation_id': 'シミュレーションID',
    'geofence.name': 'ジオフェンス名',
    'geofence.jit_home_to_work_sim_id': 'HTW',
    'geofence.jit_work_to_home_sim_id': 'WTH',
    'geofence.on_demand_sim_id': 'オンデマンド',
    'geofence.simulation_taxi': 'Taxi',
    'geofence.service_group': 'サービスグループ',
    'geofence.detail_title': 'ジオフェンスの詳細',
    'geofence.create_title': 'ジオフェンスを作成する',
    'geofence.title': 'ジオフェンス管理',
    'geofence.description': '説明',
    'geofence.bg_color': '背景色',
    'geofence.geofence_last_sync': '最終同期は: {{last_sync}}',
    'geofence.enable_for_admin': '管理者用',
    'geofence.enable_for_app': 'アプリ用',
    'geofence.confirm_update': 'このジオフェンスを更新しますか？',
    'geofence.confirm_register': 'このジオフェンスを作成しますか？',
    'geofence.confirm_sync': 'ジオフェンスを同期化しますか。?',
    'geofence.swat_geofence_id': 'SWATのジオフェンスID',
    'geofence.swat_geofence_key': 'SWATのジオフェンスキー',
    'geofence.in_hole': 'オーバーラップしたエリア',
    'geofence.unitrand_sim_id': 'Unitrand simＩＤ',
    'geofence.service_group_id': 'サービスグループID',
    'geofence.sync_swat': 'SWAT同期化',
    'geofence.money_config': '現金の設定',
    'geofence.opacity': '不透明度',
    'geofence.border_color': 'ボーダの色',
    'geofence.opacity_border': '不透明度の境界',
    'geofence.operating_start_time': '(から)',
    'geofence.operating_end_time': '(まで)',
    'geofence.warning.set_overlapped_plan_time': 'このジオフェンスを更新しますか？\nこのジオフェンスはプランに設定されています。更新前に、ジオフェンスの運行時間とプランの運行時間が重複するように設定してください。',
    'geofence.min_walking_distance_show': 'Min walking distance show',
    'geofence.max_walking_distance_show': 'Max walking distance show',
    'geofence.mapbox_path_segment': 'Mapbox path segment',
    'geofence.auto_renew_services': 'Auto renew services',
    'geofence.offer_estimation_timeout': 'Offer estimation timeout (minutes)',
    'geofence.offer_estimation_timeout_invalid': 'Offer estimation timeout has invalid format',
    'geofence.display_for_app': 'アプリでの表示',
    'geofence.color_in_top_map': 'トップマップの色',
    'geofence.color_in_my_page': 'プラン詳細の色',
    'geofence.geofence_preview': 'ジオフェンスプレビュー',
    'geofence.geofence_preview_top_map': 'トップマップ',
    'geofence.geofence_preview_my_page': 'プラン詳細',
    'geofence.allow_infant': '幼児表示',
    'geofence.enableForAdmin': '有効 (管理者用)',
    'geofence.settingScreenDisplay': '設定画面表示フラグ',
    'geofence.historyScreenDisplay': '履歴画面表示フラグ',
    'geofence.lastOrderTime': 'ラストオーダー時刻',
    'geofence.settingLastOrderTime': 'ラストオーダー時刻設定',

    // Service
    'service.title': 'サービス管理',
    'service.id': 'サービスID',
    'service.geofence_key': 'ジオフェンスキー',
    'service.transportation_type': '輸送タイプ',
    'service.service_mode': 'サービスモード',
    'service.times': 'Service times',
    'service.vehicle_group_type': '車両グループタイプ',
    'service.confirm_update': 'このサービスグループを更新しますか？',
    'service.detail_title': 'サービスの詳細',
    'service.dataset_id': 'データセットＩＤ',
    'service.routing_profile_id': 'ルーティングプロファイルＩＤ',
    'service.transit_stop_set_id': 'トランジットストップセットＩＤ',
    'service.walking_profile_id': 'ウォーキングプロフィールＩＤ',
    'geofence.geometry': '幾何学',
    'service.willerManagement': 'Willer Services Management',
    'service.enable': '有効',
    'service.type': 'サービスの種類',
    'service.notFound': 'No willer services found',
    'service.addLanguage': '言語を追加する',
    'service.note': 'ノート',
    'service.language': '言語',

    // Business Plan
    'business_plan.form': 'ユーザーサブスクリプションフォーム',
    'business_plan.active': 'アクティブ',
    'business_plan.invite_date': '招待日',
    'business_plan.message_clone': '選択したプランをコピーします。よろしいですか',
    'business_plan.id': 'ID',
    'business_plan.package_name': 'プラン名',
    'business_plan.partner_id': 'パートナー',
    'business_plan.code': 'プランコード',
    'business_plan.stt': 'プランID',
    'business_plan.price': '料金',
    'business_plan.unit': '通貨',
    'business_plan.geofence': 'ジオフェンス',
    'business_plan.company': '会社',
    'business_plan.type': 'タイプ',
    'business_plan.days': '日間',
    'business_plan.number_day': '日数',
    'business_plan.number_of_people': '人数(本人加えて)',
    'business_plan.free_time': '無料体験期間',
    'business_plan.hour': '時間',
    'business_plan.day': '日数',
    'business_plan.status': 'ステータス',
    'business_plan.username': 'ユーザー名',
    'business_plan.mail': 'メールアドレス',
    'business_plan.user_code': 'ユーザーコード',
    'business_plan.deposit_status': '入金ステータス',
    'business_plan.operation_time': '運行時間',
    'business_plan.start_time': 'スタート日時',
    'business_plan.end_time': 'エンド日時',
    'business_plan.start_date': '利用開始日',
    'business_plan.end_date': '利用終了日',
    'business_plan.copy': 'プランをコピーしました',
    'business_plan.family_option': 'ファミリーオプション',
    'business_plan.family_option_amount': '家族オプション金額',
    'business_plan.extra_fee': '1人追加の金額',
    'business_plan.enable_for_landing_page': 'アプリにプランを表示する',
    'business_plan.description': '説明',
    'business_plan.main_plan': 'メインプラン',
    'business_plan.display_times': '表示時間',
    'business_plan.full_name': 'フルネーム',
    'business_plan.created': '作成した',
    'business_plan.use_time': '使用時間',
    'business_plan.msg_show_main': '選択したジオフェンスにて メインプランとして設定します。',
    'business_plan.msg_show_main_create': '作成するプランをメインプランとして設定します。',
    'business_plan.banking': '銀行',
    'business_plan.registered_user': '家族オプション申し込み数',
    'business_plan.user_added': '家族オプション紐づけ数',
    'business_plan.date_of_payment': '支払いした日',
    'business_plan.max_transfer_days': '振り込み期限',
    'business_plan.reminder_days': 'リマインダー日間',
    'business_plan.max_transfer_days_validate': '転送日はリマインダー日よりも長くする必要があります',
    'business_plan.confirm_create': 'サブスクリプションを登録します。よろしいですか。?',
    'choose.bank_account': '銀行口座を選択',
    'business_plan.bank_transfer': 'お支払い方法：銀行振込',
    'business_plan.validate_start_time_use_time': 'スタート日時は プランの利用期間の中にある日時となければなりません。',
    'business_plan.validate_amount': '{{position}}の位置に金額を入力してください',
    'business_plan.validate_extra_fee': '{{position}}の位置に追加金額を入力してください',
    'transfer.not_transfer': '未入金',
    'transfer.transfer': '入金済み',
    'transfer.cancel': '未入金_キャンセル',
    'transfer.refuned': '返金済み',
    'business_plan.addCompany': '会社を追加',
    'business_plan.add_company_confirm': 'これらの会社をプランに追加しますか？',
    'business_plan.category': 'プランカテゴリ',
    'business_plan.unlimited_rides': '乗り放題',
    'business_plan.ride_ticket': 'チケット',
    'business_plan.operation_information': '操作情報',
    'business_plan.additional_information': '追加情報',
    'business_plan.applicable_object': '適用対象',
    'business_plan.show_promotion_app': 'アプリ特典詳細表示',
    'business_plan.promotion_title': '特典題名',
    'business_plan.promotion_description': '特典詳細',
    'business_plan.one_time_discount': '同乗者割引',
    'business_plan.list_detail': '割引詳細',
    'business_plan.number_of_user': '利用人数',
    'business_plan.one_time_discount_icon': '同乗者割引アイコン',
    'business_plan.icon': '写真',
    'business_plan.one_time_discount_description': '同乗者割引説明文',
    'business_plan.validate_time': 'プランの運行時間は選択したすべてのジオフェンスの運行時間と重複する必要があります',
    'business_plan.error_referral_time': '既に設定されている紹介コードがあります',
    'business_plan.validate_user': '1つのジオフェンス以上に適用対象.>0を設定してください',

    'ticket.name': 'チケット名',
    'ticket.add': 'チケットを追加',
    'ticket.type': 'チケットタイプ',
    'ticket.all_user': '全てのユーザー',
    'ticket.never_used_mobi': 'Mobiを使用したことがない',
    'ticket.no_plan': 'プランがない',
    'ticket.withdraw': '退会',
    'ticket.registration_period': '登録期間',
    'ticket.period': 'チケット期間',
    'ticket.adult': '大人',
    'ticket.child': '子供',
    'ticket.discount.adult': '割引適用大人',
    'ticket.discount.child': '割引適用子供',
    'ticket.ride_limit': '回数',
    'ticket.amount_type': '対象',
    'ticket.code': 'チケットコード',
    'ticket.days': '日数',
    'ticket.automatic_update': '自動更新',
    'ticket.additional_purchase': '追加購入',
    'ticket.confirm_register': 'このチケットを登録してもよろしいですか？',
    'ticket.confirm_update': 'このチケットを更新してもよろしいですか？',
    'ticket.confirm_delete': 'このチケットを削除してもよろしいですか？',
    'ticket.confirm_clone': 'このチケットのクローンを作成してもよろしいですか？',
    'ticket.add_ticket_for_member': 'メンバーにチケットを追加',
    'ticket.confirm_add_ticket': '{{count}}メンバー用にこのチケットを追加してもよろしいですか？',
    'ticket.validate_ride_limit': '位置{{position}}で乗車制限を入力してください',
    'ticket.ride': '回',
    'ticket.user_ticket_id': 'ユーザーチケットID',
    'ticket.registration_date': '登録日',
    'ticket.total_ride_limit': '回数合計',
    'ticket.used_count': '使用回数',
    'ticket.remain_count': '残り回数',
    'ticket.validate_start_date_time': '開始日時は使用時間内である必要があります',
    'ticket.start_date_time': '使用開始日',
    'ticket.additional_purchase_ride_ticket': 'チケットの追加購入',
    'ticket.history_additional': '歴史追加',
    'ticket.history_additional_purchase': '追加購入履歴',
    'ticket.payment_code': '支払いコード',
    'ticket.state': 'ステータス',
    'ticket.created_date': '作成日',
    'ticket.created_by': '作成者',
    'ticket.additional': '追加購入',
    'ticket.select_additional_purchase': '乗車チケットの追加購入枚数を選択',
    'ticket.confirm_select_additional_purchase': 'クレジットカードを使用して支払います。{{count}}回（{{amount}}）の乗車を購入しますか？',
    'ticket.no_additional_purchase': 'データの追加購入はありません',
    'ticket.available_ride': '利用可能枚数 {{count}}枚',
    'ticket.suggest_rides': '乗車数を提案する',
    'ticket.add_credit_card': 'アカウントにクレジットカードを追加してください',
    'ticket.member_list_title': 'CSVからのメンバーリスト',
    'ticket.csv_success': 'CSVのインポートに成功',
    'ticket.csv_failed': 'CSVのインポートに失敗しました',
    'ticket.member_code_incorrect': 'メンバーコードが正しくありません',
    // eslint-disable-next-line no-template-curly-in-string
    'ticket.sub.different_discount_amount_header': 'このメンバーはすでに同じジオフェンスの一回払い割引チケットを持っています',
    'ticket.sub.different_discount_amount_end': '(ジオフェンス{{GeofenceName}}, サブスクID: {{Id}} ,  大人: {{Adult}}, 子供: {{Child}})',
    'ticket.different_discount_amount_header': 'このメンバーはすでに同じジオフェンスの一回払い割引チケットを持っています:',
    'ticket.different_discount_amount_end': '(ジオフェンス: {{Geofence}}, サブスクID: {{Subscription_ID}}, 大人: {{Adult}}, 子供: {{Child}})',
    'ticket.for_gift_only': 'プレゼントのみ',
    'ticket.ticket_name_gift': 'プレゼントのプラン名',
    'ticket.adult_ticket': '大人チケット',
    'ticket.child_ticket': '子供チケット',
    'ticket.distribution_date': 'チケット配布日',
    'ticket.use_ride_ticket': '乗車チケット利用',
    'ticket.add_csv_success': 'チケットを正常に追加しました\nレコードの総数：{{count}}メンバー',
    'mass.booking.cannot.use.ticket': 'チケットはご利用できません',
    'ticket.validate_ticket_distribution_date': 'チケット配布日時はご利用時間内である必要があります。',
    'ticket.ticket_distribution_date_note': '各国にチケットを配布する際は、タイムゾーンに注意してください。',
    'ticket.ticket_distribution_date_note_time_range': 'チケット配布日は00:31 ~ 23:29以内に設定してください',

    // ticket distribution history
    'ticket.history.distributed': '配布済み',
    'ticket.history.undistributed': '未配布 ',
    'ticket.history.distributed.with.error': 'エラーで配布済み',
    'ticket.history.messageConfirm': 'チケット配布のスケジュールを削除しますか？',
    'ticket.history.messageConfirm.totalRecord': 'レコードの総数：{{count}} メンバー',
    'ticket.history.totalMember': 'メンバー合計',
    'ticket.history.listMember': 'メンバーリスト',
    'ticket.history.searchMember': 'メンバーコード / ユーザー名',
    'ticket.history.distributionStatus': '配布ステータス',
    'ticket.history.distributionFailReason': '配布失敗理由',
    'ticket.history.success': '成功',
    'ticket.history.fail': '失敗',

    // Subscriptions
    'subscription.create_modal': 'サブスクリプションを登録します。よろしいですか。',
    'subscription.update_modal': 'サブスクリプションを更新します。よろしいですか。',
    'subscription.update_memo_modal': 'サブスクリプションのメモを変更します。よろしいですか。',
    'sub.last_name': '姓',
    'sub.first_name': '名',
    'sub.firstname_furigana': 'めい ひらがな',
    'sub.lastname_furigana': 'せい　ひらがな',
    'sub.has_family_options': 'ファミリーオプション有無',
    'sub.effective_date': 'サブスク申込日',
    'sub.start_date': 'サブスク利用開始日 ',
    'sub.next_update_date': 'サブスク次回更新日',
    'sub.end_date': 'サブスク利用終了日',
    'sub.acceptance_date': 'キャンセル受付日',
    'sub.effective_date_from': 'から',
    'sub.effective_date_to': 'まで',
    'sub.plan_cost': 'サブスク料金',
    'sub.payment_id': '支払いID',
    'sub.subscription_id': 'サブスクID',
    'sub.price_sub': 'サブスク料金',
    'sub.plan_fee': 'プラン料金',
    'sub.price_family_option': 'オプション料金',
    'sub.total_price': '料金合計',
    'sub.delete_family_user': 'このユーザーを削除しますか？',
    'sub.enable_main': '無効にしますか？',
    'sub.disable_main': 'アクティベートしますか？',
    'sub.date_bank': '振り込み期限 ',
    'sub.register_sub': 'サブスクリプション登録',
    'sub.register_success': 'サブスクリプション登録に成功しました。',
    'sub.add_family_confirm': 'このメンバーをサブスクリプションに追加します。よろしいですか?',
    'sub.number_member': '人数',
    'sub.start_time': '利用開始日時',
    'sub.family_option_amount': '家族オプション金額',
    'sub.total_amount': '合計',
    'sub.reminder_days': 'リマインダー日',
    'sub.automatic_update': '自動更新',
    'sub.enable_auto_update': '自動更新を有効にする',
    'sub.disable_auto_update': '自動更新を無効にする',
    'sub.enable_renewable_confirm': '自動更新を有効にしますか。',
    'sub.disable_renewable_confirm': '自動更新を無効にしますか。',
    'sub.true': '承諾済み',
    'sub.false': '未承認',
    'sub.status': '状態',
    'sub.active_status': 'アクティブステータス',
    'sub.free_trial_start': '無料体験スタート',
    'sub.free_trial_end': '無料体験エンド',
    'sub.number_family': '申込人数合計（本人含む）',
    'sub.number_family_added': '家族オプション紐づけ数（本人含む）',
    'sub.continue_free_trial': '無料トライアルを続ける',
    'sub.canceled_by': '取消者',
    'sub.canceled_time': 'キャンセル時間',
    'sub.family_member_code': '家族のメンバーID',
    'sub.confirm_resend_mail': 'メールを再送しますか?',
    'sub.confirm_remove_slot': 'このスロットを削除しますか？',
    'sub.not_payment': '未払い',
    'sub.extended': '更新成功',
    'sub.renewal_failed': '更新失敗',
    'sub.expired': '有効期限終了',
    'sub.batch': 'バッチ',
    'sub.sent_mails': '送信済みメールリスト',
    'sub.title': '題名',
    'sub.content': 'コンテンツ',
    'sub.type': '種別',
    'sub.remove_slot': 'スロットを削除します',
    'sub.add_slot': 'スロットを追加',
    'sub.add_member': 'メンバーを追加',
    'sub.select_slot': '合計{{number}}スロット（{{count}}スロット追加）',
    'sub.confirm_add_slot': 'ファミリーオプションに{{count}}スロットを合計{{amount}}つの追加料金で追加してもよろしいですか？',
    'sub.additional_slot': '追加のスロット',
    'sub.maximum_slot': 'このプランのスロット数が最大に達しました',
    'sub.total_extra_fee': '合計追加料金',
    'sub.confirm_change_payment_method': 'お支払い方法を変更してもよろしいですか？',
    'sub.has_one_time_discount': '一回払いあり',
    'sub.applicable_user': '適用対象 \n (※1枚あたりの割引人数)',
    'sub.amount_discount': '割引後の金額',
    'sub.validate.max_child': '子供の割引後金額はジオフェンスの一回払い金額以下で入力してください',
    'sub.validate.max_adult': '大人の割引後金額はジオフェンスの一回払い金額以下で入力してください',
    'sub.validate.compare_max_child_adult': '大人または子供の割引後金額はジオフェンスの一回払い金額以下で入力してください',
    'sub.applicable_users': '適用対象',
    'sub.extended_sub_notification': '本サブスクリプションは、前の期間から繰り越された情報です。（引継ぎ元サブスクリプション：',

    // Bank
    'bank.account_name': 'アカウント名',
    'bank.account_number': '口座番号',
    'bank.bank_name': '銀行名',
    'bank.transfer_content': 'コンテンツの転送',

    // History Sub
    'history.state': '状態',
    'history.payment_code': '支払いコード',
    'history.created_date': '作成日',

    // Popup Recommend
    'popup_recommend.header': 'ヘッダ',
    'popup_recommend.title': '題名',
    'popup_recommend.description': '説明',
    'popup_recommend.service_type': 'サービス種別',
    'popup_recommend.recommend_type': '推奨タイプ',
    'popup_recommend.user_id': 'ユーザーID',
    'popup_recommend.recommend_id': 'IDをお勧めします',

    'recommend_type.not_used': 'mobi利用なし',
    'recommend_type.one_time': 'mobi利用あり',
    'recommend_type.sub': 'ファミリープラン',
    'recommend_type.not_sub': '評価サブスク',
    'recommend_type.trial': 'TRIAL',
    'recommend_type.one_time_trial': 'ONE TIME TRIAL',
    'recommend_type.not_sub_trial': 'NOT SUB TRIAL',

    // News Management
    'news_management.title': 'ニュース',
    'news_management.form_title': 'ニュースフォーム',
    'news_management.key_word': 'キーワード',
    'news_management.note': '表示時間',
    'news_management.active_status': '使用状況',
    'news_management.active': '有効',
    'news_management.inactive': '無効',
    'news_management.delete': '削除',
    'news_management.display_time': '表示時間',
    'news_management.create_time': '時間を作成する',
    'news_management.all_geofence': '全てのジオフェンスが有効',
    'news_management.time_out': 'タイムアウト',
    'news_management.valid_multiple_languages': '少なくとも1つの言語を選択し、十分な有効な情報を入力してください',
    'news_management.display_method': '表示方法',
    'news_management.template_list': 'テンプレート設定',
    'news_management.serious_error': '障害・事故等のお知らせ（赤色ポップアップ有り）',
    'news_management.announcement': '復旧や重要なお知らせ（緑色ポップアップ有り）',
    'news_management.promotion_inform': 'キャンペーン情報などのお知らせ（アプリ起動時のお知らせ表示）',
    'news_management.news_inform': '通常情報のお知らせ（お知らせ欄への記載のみ）',
    'news_management.display_on': 'アプリ表示',
    'news_management.push_notification': 'プッシュ通知',
    'news_management.using_template': 'テンプレート使用',
    'news_management.yes': 'あり',
    'news_management.no': 'なし',
    'news_management.messageCode.createConfirm': 'ニュースを登録します。よろしいですか？',
    'news_management.messageCode.updateConfirm': 'このニュースを更新しますか？',
    'news_management.push_notification_description': '* プッシュ通知を設定したい場合は、表示時間(From)を今日に設定してください',

    // Template Management
    'template_management.list': 'テンプレート一覧',
    'template_management.name': 'テンプレート名',
    'template_management.title': '題名',
    'template_management.content': 'コンテンツ',
    'template_management.read_more': 'もっと読む',
    'template_management.read_less': '少なく読む',
    'template_management.template': 'テンプレート',
    'template_management.template_form': 'テンプレートフォーム',
    'template_management.language': '言語',
    'template_management.lowercase_language': '言語',
    'template_management.registerConfirm': 'テンプレートを登録します。よろしいですか？',

    // Message API Popup
    'cannot.update.recommend': '推奨を更新できません',
    'recommend.not.exist': '推奨は存在しません',
    'recommend.exist': '推奨存在',
    'recommend.question.confirm.update': '推奨質問確認更新',
    'recommend.question.confirm.create': '推奨質問確認作成',
    'recommend.question.confirm.delete': '質問をお勧めします削除を確認します',
    'over.max.slide.per.country.allowed': '許可されている国ごとの最大スライド数を超える',
    'slide.not.exist': 'スライドは存在しません',
    'api.create.slide.success': '画像を登録しました',
    'api.update.slide.success': '画像を更新しました',
    'api.delete.slide.success': 'スライドを正常に削除しました',

    // Menu App Management
    'menu_app.titleManagement': 'メニューアプリ管理',
    'menu_app.titleForm': 'メニューアプリフォーム',
    'menu_app.titleSearch': '検索条件',
    'menu_app.title': 'タイトル',
    'menu_app.link': 'リンク',
    'menu_app.order': '注文',
    'menu_app.action': 'アクション',
    'menu_app.image_master_url': '画像',
    'menu_app.country_setting': '国画像設定',
    'menu_app.geofence_setting': 'ジオフェンス画像設定',
    'menu_app.displaying': '表示',
    'menu_app.not_displaying': '非表示',
    'menu_app.add_image_information': '画像情報を追加してください',
    'menu_app.register_image_success': '画像を登録しました',
    'menu_app.update_image_success': '画像を更新しました',
    'menu_app.display': '表示',

    // Area Geofence
    'area_geofence.form': 'ジオフェンスエリアフォーム',
    'area_geofence.detailTitle': 'ジオフェンスエリア詳細',
    'area_geofence.createTitle': 'ジオフェンスエリア登録',
    'area_geofence.question.confirm.create': 'ジオフェンスエリアのを登録します。よろしいですか。',
    'area_geofence.question.confirm.update': 'ジオフェンスエリアの情報を変更します。よろしいですか。',
    'area_geofence.question.confirm.delete': 'ジオフェンスエリアを削除しますか。',
    'area_geofence.center_point': '中心座標',
    'area_geofence.geofence': 'ジオフェンス',
    'area_geofence.area': 'エリア',

    // Stop Master
    'stop_master.managementTitle': 'ストップポイント管理',
    'stop_master.stopSetData': 'トランジットストップセットデータ',
    'stop_master.stopSet': 'トランジットストップセット',
    'stop_master.detailTitle': 'ストップポイント詳細',
    'stop_master.createTitle': 'ストップポイント登録',
    'stop_master.titleForm': 'ストップポイントフォーム',
    'stop_master.code': 'コード',
    'stop_master.work': 'ワークポイント',
    'stop_master.description': '説明',
    'stop_master.hotline': 'ホットライン',
    'stop_master.ref_link': '紹介リンク',
    'stop_master.street_name': '道の名前',
    'stop_master.type': '種別',
    'stop_master.stop_sets': 'Stop Sets',
    'stop_master.name_translations': '名前の翻訳',
    'stop_master.street_translations': 'ストリート翻訳',
    'stop_master.question.confirm.update': 'ストップポイントの情報を変更します。よろしいですか。',
    'stop_master.question.confirm.delete': 'ストップポイントを削除しますか。',
    'stop_master.question.confirm.create': 'ストップポイントのを登録します。よろしいですか。',

    //  Company Management
    'company.name': '名称',
    'company.usageStatus': '状態',
    'company.code': '会社コード',
    'company.id': 'ID',
    'company.form': '会社フォーム',
    'company.registration': '会社登録',
    'company.update': '会社詳細',
    'company.createdBy': '作成者',
    'company.userList': 'メンバーリスト',
    'company.addMember': 'メンバーを追加',
    'company.deleteConfirmMember': 'このメンバーを削除しますか?',
    'company.add_members_confirm': 'これらのメンバーを会社に追加しますか？',
    'company.memberCsvImport': 'CSV入力',
    'company.errorUsers': 'エラーメンバー',
    'company.note': 'ノート',
    'company.errorMemberList': 'エラーメンバーリスト',
    'company.successMemberList': 'メンバーリストのプレビュー',
    'company.message.errorImport': 'メンバーコードまたはタイプが正しくない。',
    'company.type': 'タイプ',
    'company.import.success': 'CSV入力が完了しました。',
    'company.message.total': '合計',
    'company.message.add': '追加',
    'company.message.delete': '消去',
    'company.message.errorAdded': 'メンバーリストにメンバーが追加されました。',
    // QR code
    'qr_code.managementTitle': 'QRコード管理',
    'qr_code.detailTitle': 'QRコード詳細',
    'qr_code.historyTitle': 'QRコードの使用履歴',
    'qr_code.createTitle': 'QRコード登録',
    'qr_code.titleForm': 'QRコードフォーム',
    'qr_code.owner': 'オーナー',
    'qr_code.partner': 'パートナー/会社名',
    'qr_code.name': 'QRコード名',
    'qr_code.usage_date': '使用の開始/終了',
    'qr_code.usage_start': '使用開始',
    'qr_code.usage_end': '使用終了',
    'qr_code.usage_limit': '限定使用',
    'qr_code.question.confirm.update': 'QRコードの情報を変更します。よろしいですか。',
    'qr_code.question.confirm.delete': 'QRコードを削除しますか。',
    'qr_code.question.confirm.create': 'QRコードのを登録します。よろしいですか。',
    'qr_code.category': 'カテゴリー',
    'qr_code.member_list': 'メンバーリスト',
    'qr_code.all_member': 'すべてのメンバーに適用',
    'qr_code.total_quantity': '総量',
    'qr_code.total_usage_count': '総使用数',
    'qr_code.total_remain_count': '総残存数',
    'qr_code.remain_count': '残存数',
    'qr_code.usage_count': '使用数',
    'qr_code.place': '場所',

    'owner.type.partner': '相棒',
    'owner.type.company': '会社',

    'ticket.departure_station': '出発駅',
    'ticket.arrival_station': '到着駅',
    'ticket.ticket_type': '券種',
    'ticket.commuter_pass_type': '定期券種',
    'ticket.coupon_ticket_type': '回数券種',
    'ticket.ticket_use_type': 'チケット使用タイプ',
    'ticket.start_date_of_use': '利用開始日',
    'ticket.end_date_of_use': '使用終了日',
    'ticket.payment_method': '支払方法',
    'ticket.search_route': '経路を検索',
    'ticket.route_information': '経路選択',
    'ticket.credit_card': 'クレジットカード',
    'ticket.buy_ticket': 'チケットを購入',
    'ticket.transfer_station': '経由',
    'ticket.route': '経路',
    'ticket.transfer': '乗り換え',
    'ticket.fare': '運賃',
    'ticket.time': '回',
    'ticket.commuter_pass': '定期券',
    'ticket.coupon_ticket': '回数券',
    'ticket.confirm': '最終確認',
    'ticket.titleSearch': 'QR乗車券購入',
    'ticket.already.exists': 'チケットはすでに存在します。',
    'ticket.amount': '金額',
    'ticket.notification_1': '回数券の有効期限が切れていないので、他の券を購入できません。',
    'ticket.notification_2': '定期券の有効期限は14日以上なので、他の券を購入できません。',
    'ticket.notification_3': '定期券は{{date}}まで有効です。 使用開始日を現在の券の有効期限後で選択してください。',

    // QR Ticket
    'qrTicket.title': 'ユーザーの鉄道チケット',
    'qrTicket.searchCondition': '検索条件',
    'qrTicket.detailTitle': 'ユーザーの鉄道チケットの詳細',
    'qrTicket.detailForm': 'ユーザーの鉄道チケットフォーム',
    'qrTicket.country': '国',
    'qrTicket.businessName': '事業者',
    'qrTicket.memberCode': 'メンバーコード',
    'qrTicket.fullName': '姓名',
    'qrTicket.phoneNumber': '電話番号',
    'qrTicket.mail': 'メール',
    'qrTicket.ticketType': '鉄道チケットタイプ',
    'qrTicket.couponTicketType': '回数券種',
    'qrTicket.commuterPassType': '定期券種',
    'qrTicket.ticketUseType': 'チケット使用タイプ',
    'qrTicket.userTicketId': 'ユーザーのチケットID',
    'qrTicket.amount': '金額',
    'qrTicket.rideLimit': '回乗車合計',
    'qrTicket.paymentMethod': '支払方法',
    'qrTicket.paymentId': '支払ID',
    'qrTicket.registrationDate': '登録日',
    'qrTicket.startDate': '利用開始日',
    'qrTicket.endDate': '利用終了日',
    'qrTicket.status': '状態',
    'qrTicket.currency': '使用回数',
    'qrTicket.usedCount': '使用回数',
    'qrTicket.remainCount': '残り回数',
    'qrTicket.departureStation': '出発駅',
    'qrTicket.arrivalStation': '到着駅',
    'qrTicket.routeName': '経由',
    'qrTicket.disable': '無効にする',
    'qrTicket.credit_card': 'クレジットカード',
    'qrTicket.number': '番号',
    'qrTicket.departureDate': '出発日',
    'qrTicket.arrivalDate': '到着日',
    'qrTicket.onetimeAmount': '決済金額',
    'qrTicket.detailModalTitle': '回数券の利用履歴',
    'qrTicket.disableMessage': 'このチケットを無効にしてよろしいですか?',
    'qrTicket.complete': '回数券使用済み',
    'qrTicket.onTrain': '電車内',
    'qrTicket.canceled': 'キャンセル',
    'qrTicket.completedCollection': '回数券使用済み（もぎり）',
    'qrTicket.noUseCoupon': '回数券不使用',

    // Coupon Management
    'coupon.couponCode': 'クーポンコード',
    'coupon.shopName': '店舗名',
    'coupon.shopNameJp': '店舗名 (JP)',
    'coupon.shopNameEn': '店舗名 (EN)',
    'coupon.shopNameVn': '店舗名 (VN)',
    'coupon.couponName': 'クーポン名',
    'coupon.couponNameJp': 'クーポン名 (JP)',
    'coupon.couponNameEn': 'クーポン名 (EN)',
    'coupon.couponNameVn': 'クーポン名 (VN)',
    'coupon.couponType': 'クーポンタイプ',
    'coupon.publishedDate': '公開日',
    'coupon.useTime': '有効期間',
    'coupon.createdDate': '作成日',
    'coupon.useTimeStart': '有効期間 (開始日)',
    'coupon.useTimeEnd': '有効期間 (終了日)',
    'coupon.nearestWaypoint': '最寄りのmobi スポット名',
    'coupon.nearestWaypointJp': '最寄りのmobi スポット名 (JP)',
    'coupon.nearestWaypointEn': '最寄りのmobi スポット名 (EN)',
    'coupon.nearestWaypointVn': '最寄りのmobi スポット名 (VN)',
    'coupon.displayOnApp': 'アプリに表示',
    'coupon.csvExportCoupon': 'クーポン一覧',
    'coupon.csvExportCouponUsage': 'クーポン利用状況',
    'coupon.image': 'クーポン画像',
    'coupon.discountContent': '割引内容',
    'coupon.description': '説明',
    'coupon.oneTime': '一回のみ',
    'coupon.unlimited': '無制限',
    'coupon.couponRegister': 'クーポン登録',
    'coupon.couponDetail': 'クーポン詳細',
    'coupon.displayStatus': '表示ステータス',
    'coupon.imageNotice': '画像の推奨サイズは4x3です',

    // Auto bus booking
    'auto_bus_booking.titleInformation': '自動運転車両',
    'auto_bus_booking.title': '自動運転車両',
    'auto_bus_booking.form': '自動運転車両登録テンプレート',
    'auto_bus_booking.register': '自動運転車両登録',
    'auto_bus_booking.saleOffice': '営業所・店舗情報',
    'auto_bus_booking.driverCodeAndName': 'ドライバーコード - 名前',
    'auto_bus_booking.driverCode': 'ドライバーコード',
    'auto_bus_booking.driverName': '名前',
    'auto_bus_booking.userCode': 'ユーザーコード',
    'auto_bus_booking.pickUpWaypoint': '乗車地',
    'auto_bus_booking.dropOffWaypoint': '降車地',
    'auto_bus_booking.pickupDropoffDuplicated': '乗車地と降車地の違いが必要です',
    'auto_bus_booking.bookingInformation': '予約情報',
    'auto_bus_booking.operationDate': '運用日',
    'auto_bus_booking.routeName': 'ルート名',
    'auto_bus_booking.operationHours': '運用時間',
    'auto_bus_booking.overlapedTime': '選択した日にドライバーのスケシュールが重複になっていしまいます',
    'auto_bus_booking.pickupTime': '乗車時間',
    'auto_bus_booking.dropoffTime': '降車時間',
    'auto_bus_booking.pickUpWaypointAndTime': '乗車地と乗車時間',
    'auto_bus_booking.dropOffWaypointAndTime': '降車地と降車時間',
  },
};

export default ja;
