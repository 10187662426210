import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  VEHICLE_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  MOBI_TYPES,
  USAGE_PATTERN,
  MAX_SIZE_OUTPUT,
  EXPORT_LIMIT_NUMBER_ROWS,
  EXPORT_ALERT_NUMBER_ROWS,
  PERMISSION_ACTIONS,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListDriverVehicleApi} from '../../../services/businessServices';
import {checkBookingByVehicleApi} from '../../../services/businessVehicleServices';
import {searchListBusinessVehicle, deleteVehicle, exportVehicle, getListVehicleMode} from '../../../stores/business_vehicles/action';
import {getListSupplier, getAllFacilityList, getListGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal, isRoleCountry, isRoleBusiness} from '../../../utils/role';

/**
 * Vehicle Registration Page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      supplier_name: null,
      facility_id: '',
      supplier_facility_name: null,
      vehicle_id: '',
      usage_pattern: '',
      mobi_types: [],
      mode: '',
      vehicle_name: null,

      dataCheckDelete: [],

      flag: false,
      vehicleList: [],
      isSearch: false,
      deleteId: null,
      flgDelete: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      geofence_id: '',
      listGeofences: [],
      bookingCancelNumber: null,
      bookingCancelIds: [],
      showWarningDialog: false,
      flgCheckBooking: false,
    };

    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const {principal} = this.props;
    await this.props.getAllFacilityList();
    const listSupplier = await this.props.getListSupplier();
    if (isRoleBusiness()) {
      this.setState({
        supplier_name: listSupplier.find((item) => item.id === principal.supplier_id),
      });
    }
    await this.props.getListVehicleMode();
    await this.props.getListGeofence().then((response) => {
      if (isRoleGlobal()) {
        this.setState({listGeofences: response});
      } else {
        this.setState({
          listGeofences: isRoleCountry() ?
            response.filter((item) => item.country.id === principal.country_id) :
            response.filter((item) => principal.geofence_ids.includes(item.geofence_id)),
        });
      }
    });
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  };

  /**
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.usage_pattern && this.state.usage_pattern !== prevState.usage_pattern) {
      this.setState({mobi_types: []});
    }
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearch);
      }
    }
  }

  resetSearchCondition = () => {
    this.setState({
      status: '',
      supplier_name: isRoleBusiness() ? this.state.supplier_name : '',
      facility_id: '',
      supplier_facility_name: '',
      supplier_facility_id: '',
      vehicle_id: '',
      vehicle_name: '',
      usage_pattern: '',
      mobi_types: [],
      mode: '',
      geofence_id: '',
    });
  };

  /**
   * changeSupplier
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({supplier_name: value});
  };

  /**
   * changeFacility
   * @param {event} value
   */
  changeFacilityName = (value) => {
    this.setState({
      supplier_facility_name: value,
    });
  };

  /**
   *
   * @param {boolean} value
   * @param {string} item
   */
  handleSelectMobiTypes = (value, item) => {
    const mobi_types_copy = this.state.mobi_types;
    const index = mobi_types_copy.indexOf(item);
    value ? mobi_types_copy.push(item) : mobi_types_copy.splice(index, 1);

    this.setState({
      mobi_types: mobi_types_copy,
    });
  };

  /**
   * handleDelete
   * @param {int} id
   */
  handleDelete(id) {
    const payload = {
      vehicle_id: id,
      vehicle_operation_session: [],
      deleted_vehicle: true,
    };
    checkBookingByVehicleApi(payload).then((response) => {
      if (response) {
        if (response?.result?.length > 0) {
          const bookingCancelIds = response?.result;
          this.setState({flgCheckBooking: true, bookingCancelNumber: response?.result?.length, bookingCancelIds, deleteId: id});
        } else {
          this.setState({
            flag: true,
            deleteId: id,
            message: 'messageCode.deleteConfirmVehicle',
            bookingCancelNumber: null,
            bookingCancelIds: [],
          });
        }
      }
    });
  }

  /**
   * Check Id exits
   * @param {*} id
   * @param {*} dataVehicle
   * @return {*}
   */
  isCheckVehicleExits = (id, dataVehicle) => {
    return dataVehicle.filter((driver) => driver?.vehicles?.find((item) => item.vehicle_id === id));
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    if (this.state.message === 'messageCode.isCheckNotVehicleDelete') {
      this.setState({
        flag: false,
      });
    } else {
      this.setState({
        flag: false,
      });
      _.remove(this.state.vehicleList, (item) => {
        return item.id === this.state.deleteId;
      });
      this.props.deleteVehicle(this.state.deleteId, this.props).then(() => {
        this.setState({
          vehicleList: this.state.vehicleList,
          flgCheckBooking: false,
        });
      });
    }
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch(reset) {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {usage_pattern, mobi_types} = this.state;
    const vehicle_group_types = mobi_types.length > 0 ? mobi_types : usage_pattern ? [usage_pattern] : [];
    const payload = {
      status: this.state.status ? this.state.status : null,
      supplier_name: this.state.supplier_name ? this.state.supplier_name.supplier_name : null,
      supplier_facility_id: this.state.facility_id ? this.state.facility_id : null,
      supplier_facility_name: this.state.supplier_facility_name ? this.state.supplier_facility_name.facility_name : null,
      vehicle_id: this.state.vehicle_id ? this.state.vehicle_id?.trim() : null,
      vehicle_number: this.state.vehicle_number ? this.state.vehicle_number?.trim() : null,
      vehicle_group_types,
      mode: this.state.mode ? this.state.mode : null,
      geofence_id: this.state.geofence_id ? this.state.geofence_id : '',
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    searchListDriverVehicleApi({from_time: new Date().toISOString()}).then((dataCheckDelete) => {
      this.setState({
        dataCheckDelete: dataCheckDelete?.result,
      });
    });

    this.props.searchListBusinessVehicle(payload, queryParams, this.props).then((response) => {
      this.setState(
        {
          isSearch: true,
          vehicleList: response?.content,
          totalRows: response?.totalSize,
          latestSearchParams: payload,
        },
        () => {
          // Apply changed params into url (remove null value by _pickBy func)
          const queryParamsToChange = {
            status: this.state.status,
            // Must use JSON.stringify() in order to convert object to string
            supplier_name: this.state.supplier_name && JSON.stringify(this.state.supplier_name),
            facility_id: this.state.facility_id,
            supplier_facility_name: this.state.supplier_facility_name && JSON.stringify(this.state.supplier_facility_name),
            vehicle_id: this.state.vehicle_id,
            vehicle_name: this.state.vehicle_name,
            usage_pattern: this.state.usage_pattern,
            mobi_types: this.state.mobi_types,
            mode: this.state.mode,
            currentPage: String(this.state.currentPage),
            rowsPerPage: this.state.rowsPerPage,
            geofence_id: this.state.geofence_id,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t, common} = this.props;
    this.exportHeaders = [
      {label: t('businessVehicle.code_id'), key: 'vehicle_id'},
      {label: t('businessVehicle.vehicleNumber'), key: 'registration_number'},
      {label: t('businessVehicle.usagePattern'), key: 'usage_pattern'},
      {label: t('businessVehicle.mobiType'), key: 'mobi_type'},
      {label: t('businessVehicle.status'), key: 'status'},
      {label: t('businessVehicle.businessName'), key: 'supplier_name'},
      {label: t('businessVehicle.sales_office_store_name'), key: 'supplier_facility_name'},
      {label: t('area_geofence.geofence'), key: 'geofence'},
    ];
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportVehicle(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.mobi_type = row?.mobi_type
        ?.split(',')
        ?.map((type) => t('businessVehicle.mobiType.searchResult.' + type) + '\n')
        .join('');
      row.status = t(`${VEHICLE_STATUS.find((item) => row?.status?.localeCompare(item.code) === 0).display}`);
      row.geofence = common.geofence_list.find((item) => item.geofence_id === row.geofence_id)?.name;
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };
  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, businessVehicle, common, actions} = this.props;
    const {vehicleList} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canTrackingHistory: actions.includes(PERMISSION_ACTIONS.TRACKING_HISTORY),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Operation Status */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessInformation.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.status ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessInformation.status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.status}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {VEHICLE_STATUS.map((item, idx) => {
                                return (
                                  <MenuItem value={item.code} key={idx}>
                                    {t(`${item.display}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Vehicle name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.code_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="vehicle_id"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('businessVehicle.code_id')}
                            inputProps={{maxLength: 256}}
                            value={this.state.vehicle_id}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Vehicle number */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.vehicleNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="vehicle_number"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessVehicle.vehicleNumber')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.vehicle_number}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Usage Pattern */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.usagePattern')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense">
                            <Select
                              variant="outlined"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              inputProps={{
                                name: 'usage_pattern',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.usage_pattern ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessVehicle.usagePattern'),
                                        })}
                                      </div>
                                    )
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                              }}
                              value={this.state.usage_pattern}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {USAGE_PATTERN.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(item.i18n)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Mobi Type */}
                      {/* Only display if usage pattern is MOBI */}
                      {this.state.usage_pattern === USAGE_PATTERN[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessVehicle.mobiType')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormGroup row>
                              {MOBI_TYPES.map((mobiType, index) => {
                                const item = mobiType.id;
                                const checked = this.state.mobi_types.indexOf(item) > -1;
                                return (
                                  <FormControlLabel
                                    className="checkbox_radio_types"
                                    key={index}
                                    labelPlacement="end"
                                    control={<Checkbox className="checkbox_radio" checked={checked} onChange={(event) => this.handleSelectMobiTypes(event.target.checked, item)} />}
                                    label={t('businessVehicle.mobiType.' + item)}
                                  />
                                );
                              })}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      )}

                      {/* Vehicle mode */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.vehicle_mode')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'mode',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.mode ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessVehicle.vehicle_mode'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.mode}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {businessVehicle?.listVehicleMode?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.mode} key={idx}>
                                    {item.mode}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Supplier name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.businessName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_250"
                            options={common?.supplier_list}
                            value={this.state.supplier_name}
                            getOptionLabel={(option) => option.supplier_name}
                            onChange={(event, business) => {
                              this.changeBusiness(business);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'businessName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('businessVehicle.businessName')})}
                              />
                            )}
                            disabled={isRoleBusiness()}
                          />
                        </Grid>
                      </Grid>
                      {/* Facility id */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.sales_office_store_code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="facility_id"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('businessVehicle.sales_office_store_code')}
                            inputProps={{maxLength: 256}}
                            value={this.state.facility_id}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Facility name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.sales_office_store_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_250"
                            options={common?.facility_list_all}
                            value={this.state.supplier_facility_name}
                            getOptionLabel={(option) => option.facility_name}
                            onChange={(event, facility) => this.changeFacilityName(facility)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'businessName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('businessVehicle.sales_office_store_name')})}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      {/* Vehicle geofence */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('area_geofence.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense">
                            <Select
                              variant="outlined"
                              className="field_size_20 field_min_size_250"
                              inputProps={{
                                name: 'geofence_id',
                              }}
                              margin="dense"
                              displayEmpty
                              renderValue={
                                this.state.geofence_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('area_geofence.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_id}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {this.state.listGeofences?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.VEHICLE_ADD}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}

                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('businessVehicle.title')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}

                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnCancel')}
                  ></SelectModal>
                </Dialog>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {vehicleList?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_100p ant-table-cell-fix-left">{t('businessVehicle.code_id')}</TableCell>
                            <TableCell className="width_150p">{t('businessVehicle.vehicleNumber')}</TableCell>
                            <TableCell className="width_100p">{t('businessVehicle.usagePattern')}</TableCell>
                            <TableCell className="width_150p">{t('businessVehicle.mobiType')}</TableCell>
                            <TableCell className="width_150p">{t('businessVehicle.status')}</TableCell>
                            <TableCell className="width_100p">{t('businessVehicle.vehicle_mode')}</TableCell>
                            <TableCell className="width_150p">{t('businessVehicle.businessName')}</TableCell>
                            <TableCell className="width_200p">{t('businessVehicle.sales_office_store_name')}</TableCell>
                            <TableCell className="width_200p">{t('area_geofence.geofence')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_200p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {vehicleList?.map((row, index) => {
                            const mobiTypeConvert = row?.mobi_type
                              ?.split(',')
                              ?.map((type) => '- ' + t('businessVehicle.mobiType.searchResult.' + type) + '<br/>')
                              .join('');
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row" className="ant-table-cell-fix-left">
                                  {row.vehicle_id}
                                </TableCell>
                                <TableCell>{row.registration_number}</TableCell>
                                <TableCell>{row.usage_pattern}</TableCell>
                                <TableCell dangerouslySetInnerHTML={{__html: mobiTypeConvert}}></TableCell>
                                <TableCell>{VEHICLE_STATUS.map((item) => (row?.status?.localeCompare(item.code) === 0 ? t(`${item.display}`) : ''))}</TableCell>
                                <TableCell>{row.mode}</TableCell>
                                <TableCell>{row.supplier_name}</TableCell>
                                <TableCell>{row.supplier_facility_name}</TableCell>
                                <TableCell>{row?.geofence_name}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell className="cell_fixed_right">
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                    &nbsp;
                                    {permission.canDelete && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id)}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                    {permission.canTrackingHistory && (
                                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DRIVER_TRACKING_HISTORY + '/' + row.id}}>
                                        <Button color="primary" variant="contained" className="button_margin">
                                          {t('common.trackingHistory')}
                                        </Button>
                                      </Link>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <br></br>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>
              </Container>
            </Card>
          )}

          <br></br>
          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
          <Dialog
            open={this.state.flgCheckBooking}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonOk.bind(this)}
              onClickCancel={() => this.setState({flgCheckBooking: false})}
              message={'businessVehicle.deleteVehicle'}
              fields={{field: this.state.bookingCancelNumber}}
              listBookingCancel={this.state.bookingCancelIds}
              messageConfirm={'businessVehicle.deleteVehicleConfirm'}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListBusinessVehicle: (params, props) => dispatch(searchListBusinessVehicle(params, props)),
    exportVehicle: (payload, queryParams) => dispatch(exportVehicle(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteVehicle: (params, props) => dispatch(deleteVehicle(params, props)),
    getListSupplier: () => dispatch(getListSupplier()),
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
    getListGeofence: () => dispatch(getListGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
