import {api} from '../services/api';

const getHomeScreenUrl = `admin/function`;
const getListSupplierUrl = `admin/suppliers`;
const getListFacilityBySupplierIdUrl = `admin/suppliers/facilities?supplier_id=`;
const getFacilityUrl = `admin/suppliers/facilities`;
const getListGeofenceUrl = `mass/public/all-geofences`;
const getListCategoryUrl = `admin/category?category_id=`;
const getListGeofenceByCountryUrl = `admin/countries/{countryId}/geofence`;
const getAllCountryCodeUrl = 'admin/countries';
const getSubscriptionGeofenceUrl = 'admin/countries/subscription/geofence';
const getAllJitGeofenceUrl = 'admin/jit-orders/jit-geofence';
const getAllCountryCodeWithGeofenceUrl = 'admin/countries/country-with-geofence';
const serviceTypeUrl = 'admin/service-types/';
const getCsvExportUrl = 'admin/csv-export/';
const getPrincipalUrl = 'admin/principal';
const getListGeofenceDetailsByIdsUrl = 'admin/countries/geofence';
const getAllGeofencesAdminUrl = 'admin/countries/all/geofence';
const getListSupplierBusinessUrl = `admin/suppliers/business`;
const getCSVExportPermissionUrl = 'admin/csv-export/permits-status';
const getAllCountryNotAuthenUrl = 'auth/countries';

export const getHomeScreenApi = () => {
  return api.get(getHomeScreenUrl);
};

export const getListSupplierApi = () => {
  return api.get(getListSupplierUrl);
};

export const getListFacilityBySupplierIdApi = (id) => {
  return api.get(getListFacilityBySupplierIdUrl + id);
};

export const getFacilityApi = () => {
  return api.get(getFacilityUrl);
};

export const getListGeofenceApi = (payload) => {
  return api.get(getListGeofenceUrl, payload);
};

export const getListCategoryApi = (id) => {
  return api.get(getListCategoryUrl + id);
};

export const getListGeofenceByCountryApi = (id, payload) => {
  return api.get(getListGeofenceByCountryUrl.replace('{countryId}', id), payload);
};

export const getAllCountryCodeApi = () => {
  return api.get(getAllCountryCodeUrl);
};

export const getAllCountryCodeWithGeofenceApi = () => {
  return api.get(getAllCountryCodeWithGeofenceUrl);
};

export const getAllGeofenceApi = (payload) => {
  return api.get(getAllGeofencesAdminUrl, payload);
};

export const getSubscriptionGeofenceApi = (payload) => {
  return api.get(getSubscriptionGeofenceUrl, payload);
};

export const getAllJitGeofenceApi = (payload) => {
  return api.get(getAllJitGeofenceUrl, payload);
};

export const getServiceTypesApi = () => {
  return api.get(`${serviceTypeUrl}`);
};

export const getCsvExportApi = (uuid) => {
  return api.get(getCsvExportUrl + uuid);
};

export const getPrincipalApi = () => {
  return api.get(getPrincipalUrl);
};

export const getListGeofenceDetailsByIdsApi = (params) => {
  return api.get(getListGeofenceDetailsByIdsUrl, params);
};

export const getListSupplierBusinessApi = () => {
  return api.get(getListSupplierBusinessUrl);
};

export const getCSVExportPermissionApi = () => {
  return api.get(getCSVExportPermissionUrl);
};

export const getAllCountryNotAuthenApi = () => {
  return api.get(getAllCountryNotAuthenUrl);
};
