/* eslint-disable max-len */
const vi = {
  translations: {
    // Route
    'route.home': 'Trang chủ',
    'route.login': 'Đăng nhập',
    'route.registration': 'Đăng ký',
    'route.logout': 'Đăng xuất',

    'route.accountManagement': 'Quản lý tài khoản',
    'route.accountInvite': 'Mời mở tài khoản',
    'route.updateAccount': 'Cập nhật thông tin tài khoản',
    'route.accountType': 'Quản lý loại tài khoản',
    'route.registerAccountType': 'Đăng ký loại tài khoản',
    'route.changePassword': 'Thay đổi mật khẩu',
    'route.verify_mobile_account': 'Xác nhận tài khoản điện thoại',

    'route.productManagement': 'Quản lý sản phẩm',
    'route.product': 'Sản phẩm',
    'route.product_detail': 'Chi tiết sản phẩm',
    'route.vehicles': 'Xe',
    'route.vehicle_detail': 'Chi tiết xe',
    'route.delivery': 'Giao hàng',
    'route.delivery_detail': 'Chi tiết giao hàng',
    'route.delivery_add': 'Đăng ký mới',
    'route.cancellation_fee': 'Phí hủy',
    'route.caution': 'Quản lý lưu ý',
    'route.price_sales_calendar': 'Lịch bán sản phẩm',
    'route.congestionStatus': 'Quản lý tình trạng tắc nghẽn',
    'route.congestionStatusDetail': 'Sửa trạng thái tình trạng tắc nghẽn',
    'route.cancellation_fee_add': 'Đăng ký phí hủy',
    'route.cancellation_fee_update': 'Thay đổi phí hủy',
    'route.cancellation_fee_detail': 'Chi tiết phí hủy',
    'route.information_fee_create': 'Tạo phí hủy',

    'route.service_pack_price': 'Điều chỉnh giá gói dịch vụ',
    'route.business_plan': 'Thông tin gói',
    'route.business_partner': 'Cài đặt đại lí',
    'route.business_plan_create': 'Tạo mới gói',
    'route.business_plan_details': 'Chi tiết gói',
    'route.business_plan_add_ticket': 'Phát vé cho thành viên',
    'route.ticket_distribution': 'Phát vé',
    'route.ticket_distribution_history': 'Lịch sử phát vé',
    'route.setting_fee_taxi': 'Cài đặt thông tin phí taxi',
    'route.companyManagement': 'Quản lý công ty',
    'route.companyManagement.information': 'Thông tin công ty',
    'route.companyManagement.detail': 'Chi tiết công ty',
    'route.companyManagement.add': 'Đăng ký công ty',
    'route.businessManagement': 'Quản lý nhà cung cấp',
    'route.businessManagement.information': 'Thông tin nhà cung cấp',
    'route.businessManagement.detail': 'Chi tiết nhà cung cấp',
    'route.businessManagement.add': 'Đăng ký nhà cung cấp',
    'route.facilityManagement': 'Thông tin văn phòng kinh doanh - cửa hàng',
    'route.facility.detail': 'Chi tiết văn phòng kinh doanh - cửa hàng',
    'route.facility.add': 'Đăng ký văn phòng kinh doanh - cửa hàng',
    'route.driverManagement': 'Đăng ký tài xế',
    'route.driver.detail': 'Chi tiết tài xế',
    'route.vehicleManagement': 'Đăng ký xe',
    'route.vehicle_add': 'Đăng ký xe',
    'route.driverVehicleAssociation': 'Liên kết tài xế và xe',
    'route.driverVehicleConfig': 'Cấu hình tài xế và xe',
    'route.driverVehicleConfigDetails': 'Cập nhật cấu hình tài xế và xe',
    'route.driver_schedule.register': 'Đăng ký lịch trình tài xế',
    'route.imageMaster': 'Quản lý ảnh',
    'route.imageMasterDetail': 'Chi tiết quản lý ảnh',
    'route.imageMasterAdd': 'Thêm quản lý ảnh',
    'route.partnerInformation': 'Thông tin đối tác',
    'route.partnerDetails': 'Chi tiết đối tác',
    'route.partnerAdd': 'Đăng ký đối tác',
    'route.settingFeeOnDemandInformation': 'Thông tin giá một lần',
    'route.settingFeeOnDemandDetails': 'Chi tiết giá một lần',
    'route.settingFeeOnDemandRegistration': 'Đăng ký giá một lần',
    'route.waypointInformation': 'Thông tin điểm tham chiếu',
    'route.waypointDetails': 'Chi tiết điểm tham chiếu',
    'route.waypointCreate': 'Đăng ký điểm tham chiếu',

    'route.memberManagement': 'Quản lý hội viên',
    'route.memberManagementCreate': 'Tạo mới hội viên',
    'route.updateMember': 'Cập nhật thông tin hội viên',
    'route.memberFavoriteWaypoint': 'Điểm tham chiếu ưa thích',
    'route.buyTrainTicket': 'Mua vé tàu',
    'route.memberManagementReferrer': 'Danh sách người giới thiệu',
    'route.memberIdentityVerification': 'Quản lý xác thực Thẻ định danh/Hộ chiếu',

    'route.reservationManagement': 'Quản lý đặt xe',
    'route.reservationManagementSearch': 'Tìm kiếm đặt xe',
    'route.reservationManagement_detail': 'Chi tiết đặt xe',
    'route.busReservationSearch': 'Quản lý chuyến buýt',
    'route.busReservationDetail': 'Chi tiết chuyến buýt',
    'route.registerSubscription': 'Thêm gói đăng ký',
    'route.business_subscription': 'Thông tin gói đăng ký người dùng',
    'route.business_subscription_create': 'Tạo mới gói đăng ký',
    'route.business_subscription_details': 'Chi tiết gói đăng ký',
    'route.qrPayment': 'Quản lý lỗi thanh toán QR',
    'route.qrPaymentDetail': 'Chi tiết lỗi thanh toán QR',
    'route.jit_setting': 'Cài đặt JIT',
    'route.jit': 'JIT',
    'route.jit_routes': 'Quản lý tuyến đường JIT',
    'route.jit_route_detail': 'Chi tiết tuyến đường JIT',
    'route.jit_route_register': 'Đăng ký tuyến đường JIT',

    'route.deliverySetting': 'Cài đặt thời gian giao hàng',
    'route.deliverySettingInformation': 'Thông tin cài đặt thời gian giao hàng',

    'route.simulationManagement': 'Quản lý mô phòng',
    'route.simulationManagement_detail': 'Chi tiết mô phòng',
    'route.simulationManagement_create': 'Tạo mới mô phòng',
    'route.simulationManagement_clone': 'Sao chép mô phòng',

    'route.serviceGroupManagement': 'Quản lý nhóm dịch vụ',
    'route.serviceGroupAdd': 'Thêm nhóm dịch vụ',
    'route.serviceGroupUpdate': 'Cập nhật nhóm dịch vụ',
    'route.checking_booking_management': 'Kiểm tra thông tin đặt chuyến',
    'route.checking_booking_detail': 'Chi tiết thông tin đặt chuyến',

    'route.commonSetting': 'Cài đặt chung',
    'route.bankManagement': 'Quản lý Ngân hàng',
    'route.bankAdd': 'Thêm Ngân hàng',
    'route.bankUpdate': 'Cập nhật Ngân hàng',
    'route.recommendCreate': 'Tạo mới đề xuất cửa số hiện lên',
    'route.recommendDetails': 'Chi tiết đề xuất cửa số hiện lên',
    'route.recommendInformation': 'Thông tin đề xuất cửa số hiện lên',
    'route.recommend': 'Đề xuất cửa số hiện lên',
    'route.shuttleManagement': 'Cài đặt menu bên phải',
    'route.menuAppManagement': 'Quản lý Menu Ứng dụng',
    'route.menuAppDetail': 'Chi tiết Menu ứng dụng',
    'route.newsManagement': 'Quản lý tin tức',
    'route.newsDetail': 'Chi tiết Tin tức',
    'route.createNews': 'Tạo tin tức',
    'route.templateSettingSearch': 'Cài đặt template',
    'route.templateSettingCreate': 'Đăng kí template',
    'route.templateSettingDetail': 'Chi tiết template',
    'route.areaGeofenceManagement': 'Quản lý vùng',
    'route.subCost': 'Quản lý chi phí phụ',
    'route.csv_import': 'Nhập CSV',
    'route.qrTicket': 'Vé QR',
    'route.register_railway_ticket': 'Mua vé tàu',
    'route.couponManagement': 'Quản lý mã giảm giá',
    'route.coupon': 'Thông tin mã giảm giá',

    'route.autoBusBookingManagement': 'Thông tin chuyến buýt tự động',
    'route.autoBusBookingRegister': 'Đăng ký chuyến buýt tự động',


    // Button common
    'common.btnResend': 'Gửi lại',
    'common.btnReset': 'Đặt lại',
    'common.btnAgree': 'Có',
    'common.btnAdd': 'Thêm',
    'common.btnUpdate': 'Cập nhật',
    'common.btnUpdateMemo': 'Cập nhật memo',
    'common.btnDelete': 'Xóa',
    'common.btnSend': 'Gửi',
    'common.btnLogin': 'Đăng nhập',
    'common.btnLogout': 'Đăng xuất',
    'common.btnRegister': 'Đăng ký',
    'common.btnReturn': 'Quay lại',
    'common.btnConfirm': 'Xác nhận',
    'common.btnCancel': 'Hủy bỏ',
    'common.btnResetSearch': 'Thiết lập lại điều kiện tìm kiếm',
    'common.btnSearch': 'Tìm kiếm',
    'common.btnReopen.Stop': 'Mở lại/Tạm dừng',
    'common.btnUploadCsv': 'Xuất CSV',
    'common.btnDetail': 'Chi tiết',
    'common.btnEdit': 'Thay đổi',
    'common.btnClose': 'Đóng',
    'common.btnBulkRegistration': 'Đăng ký đồng loạt',
    'common.noData': 'Không có kết quả tìm kiếm',
    'common.btnSelect': 'Chọn',
    'common.btnUpload': 'Tải lên',
    'common.btnUnsubscribe': 'Xóa tài khoản',
    'common.btnManage': 'Quản lý',
    'common.btnYes': 'Có',
    'common.btnNo': 'Không',
    'common.btnOk': 'Có',
    'common.btnApply': 'Áp dụng',
    'common.btnSetting': 'Thiết lập',
    'common.btnSync': 'Đồng bộ',
    'common.btnRemove': 'Loại bỏ',
    'common.btnClear': 'Xóa',
    'common.all': 'Tất cả',
    'common.btnHistory': 'Lịch sử',
    'common.btnClone': 'Clone',
    'common.btnCopy': 'Sao chép',
    'common.linkMap': 'Đưỡng dẫn Google Map',
    'common.btnReSearch': 'Tìm lại',
    'common.action': 'Hành động',
    'common.noResult': 'Không có kết quả',
    'common.true': 'ĐÚNG',
    'common.false': 'SAI',
    'common.historyPayment': 'Lịch sử Thanh toán',
    'common.chooseFile': 'Chọn tệp',
    'common.noFilesSelected': 'Không có tệp nào được chọn',
    'common.reload': 'Tải lại',
    'common.next': 'Chuyển tiếp',
    'common.trackingGps': 'Theo dõi GPS',
    'common.trackingHistory': 'Theo dõi lịch sử',
    'common.btnRetry': 'Thử lại',
    'common.btnNow': 'Hiện tại',
    'common.btnMemberInfor': 'Thông tin thành viên',
    'common.btnDoNotSend': 'Không gửi email',

    // Common field
    'common.enable': 'Kích hoạt',
    'common.disable': 'Vô hiệu hóa',
    'common.email': 'Email',
    'common.password': 'Mật khẩu',
    'common.phoneNumber': 'Số điện thoại',
    'common.searchResult': 'Kết quả tìm kiếm',
    'common.case': 'Kết quả',
    'common.address': 'Địa chỉ',
    'common.location': 'Địa điểm',
    'common.photo': 'Ảnh',
    'common.name': 'Tên',
    'common.geofence': 'Geofence',
    'common.area': 'Vùng',
    'common.country': 'Quốc gia',
    'common.paymentMethod': 'Phương thức thanh toán',
    'common.paymentStatus': 'Trạng thái thanh toán',
    'common.id': 'ID',
    'common.from': 'Từ',
    'common.to': 'Đến',
    'common.minute': 'phút',
    'common.hour': 'giờ',
    'common.second': 'giây',
    'common.user': 'Người dùng',
    'common.admin': 'Quản trị viên',
    'common.status': 'Trạng thái',
    'common.stt': 'STT',
    'common.planType': 'Loại kế hoạch',
    'common.no': 'Số thứ tự',
    'common.success': 'Thành công',
    'common.fail': 'Thất bại',
    'common.route': 'Chuyến',
    'common.active': 'Kích hoạt',
    'common.inactive': 'Không kích hoạt',
    'common.none': 'Rỗng',
    'common.description': 'Miêu tả',
    'common.cancellationDateTime': 'Ngày giờ huỷ',
    'common.district': 'Quận',
    'common.province': 'Huyện',
    'common.showInList': 'Hiển thị',
    'common.notShowInList': 'Không hiển thị',

    'common.ja': 'Tiếng Nhật',
    'common.en': 'Tiếng Anh',
    'common.vi': 'Tiếng Việt',

    // common validation
    'validation.duplicate': 'Phải nhập 3 địa chỉ email khác nhau.',
    'validation.required': 'Hãy nhập {{field}}',
    'validation.email': 'Định dạng email đã nhập không đúng',
    'validation.required.choose': 'Hãy chọn {{field}}.',
    'validation.invalid.field': '{{field}} không hợp lệ',
    'validation.invalid.time': 'Ngày tháng không hợp lệ',
    'validation.invalid.timeRange': 'Khung thời gian không hợp lệ',
    'validation.time.more': 'Ngày tháng không hợp lệ',
    'validation.phone': 'Định dạng số điện thoại không hợp lệ',
    'validation.phone.max': 'Hãy nhập số điện thoại có từ 10 đến 15 ký tự.',
    'validation.max': 'Tối đa là {{value}}.',
    'validation.maxString': 'Tối đa là {{value}} ký tự.',
    'validation.number': 'Hãy nhập số.',
    'validation.number.positive': 'Bạn cần nhập số dương',
    'validation.invalid.tejimai.htw': 'Thời gian chốt chuyến HTW không hợp lệ',
    'validation.date_time_range': '{{start}} phải nhỏ hơn {{end}}',
    'validation.required.select_array': 'Xin mời chọn ít nhất một {{element}}',
    'validation.time_equal_greater': '{{field1}} phải lớn hơn hoặc bằng {{field2}}',
    'validation.existed.field': '{{field}} đã tồn tại',
    'validation.between': '{{field}} phải lớn hơn hoặc bằng {{min}} và nhỏ hơn hoặc bằng {{max}}',
    'validation.max.label': '({{value}} kí tự)',
    'validation.limit.password': 'Vui lòng nhập mật khẩu từ 8 đến 32 chữ số',
    'validation.space.not_allowed': 'Không được phép nhập khoảng trống',
    'validation.invalid.value': '{{field}} {{value}} không hợp lệ',
    'validation.creditCardNotExist': 'Người dùng chưa thêm thẻ tín dụng vào tài khoản',
    'validation.required.update': 'Hãy cập nhật {{field}}.',
    'validation.required.select_at_least_one': 'Vui lòng chọn ít nhất 1 {{field}}',
    'validation.latitude': 'Định dạng vĩ độ không hợp lệ',
    'validation.longitude': 'Định dạng kinh độ không hợp lệ',
    'validation.range': 'Thời gian đếm ngược dao động từ {{min}} đến {{max}} giây',
    'validation.base64_first_content': 'Vui lòng ấn vào biểu tượng hình ảnh phía trên ô nhập nội dung để chèn ảnh vào nội dung tin tức.',
    'validation.base64_second_content': 'URL của hình ảnh được tải lên qua chức năng Quản lý hình ảnh có thể tạo ra bằng cách nhấn vào nút Ảnh.',

    // placeholder
    'placeholder.required': 'Hãy nhập {{field}}.',
    'placeholder.required_select': 'Hãy chọn {{field}}.',
    'placeholder.language': 'Japanese/ English/ Vietnamese',

    // Country
    'VN': 'Việt Nam',
    'JP': 'Nhật Bản',
    'SG': 'Singapore',
    'PHI': 'Philippines',
    'TW': 'Đài Loan',
    'MY': 'Malaysia',
    'GU': 'Guam',
    'US': 'Hawaii',
    'ID': 'Bali',

    // Common message code
    'messageCode.createConfirmPlan': 'Bạn có muốn tạo mới Plan không?',
    'messageCode.updateConfirmPlan': 'Bạn có muốn cập nhật plan không?',
    'messageCode.common.updateConfirm': 'Bạn có muốn cập nhật không?',
    'messageCode.updateConfirm': 'Bạn có muốn cập nhật trường {{field}} không?',
    'messageCode.deleteConfirm': 'Bạn có muốn xóa trường {{field}} không?',
    'messageCode.changePasswordConfirm': 'Bạn có muốn cập nhật mật khẩu không?',
    'messageCode.accountInviteConfirm': 'Bạn có muốn gửi mail không?',
    'messageCode.updateAccountType': 'Bạn có muốn thay đổi loại tài khoản không?\r\n ',
    'messageCode.deleteConfirmProduct': 'Bạn có muốn xóa sản phẩm này không?',
    'messageCode.deleteConfirmSupplier': 'Bạn có muốn xóa người vận hành đã chọn không?\r\n',
    'messageCode.deleteConfirmDriver': 'Bạn có muốn xóa tài xế không?',
    'messageCode.deleteConfirmImageMaster': 'Bạn có muốn xóa ảnh này không?',
    'messageCode.updateConfirmDriver': 'Bạn có muốn thay đổi thông tin tài xế không?\r\n',
    'messageCode.createConfirmDriver': 'Đã hoàn tất đăng ký thông tin tài xế. Mã tài xế sẽ được gửi đến địa chỉ mail bên dưới. \r\n {{email}}',
    'messageCode.updateConfirmVehicle': 'Bạn có muốn thay đổi thông tin xe không?',
    'messageCode.createConfirmVehicle': 'Bạn có muốn đăng ký xe không?',
    'messageCode.deleteConfirmVehicle': 'Bạn có muốn xóa xe đã chọn không?',
    'messageCode.isCheckNotDriverDelete': 'Mobi này đang hoạt động và không thể  xóa được.',
    'messageCode.isCheckNotVehicleDelete': 'Phương tiện này đang hoạt động và không thể  xóa được',
    'messageCode.updateConfirmDelivery': 'Bạn có muốn cập nhật không?',
    'messageCode.updateConfirmDeliveryTime': 'Bạn có muốn cập nhật cài đặt thời gian đi chợ không?',
    'messageCode.verifyEmailToken': 'Đã xác thực thành công địa chỉ mail được đăng ký',
    'messageCode.confirmSendEmail': 'Đã hoàn tất đăng ký thông tin tài xế.\r\nMã tài xế sẽ được gửi đến {{field}}.',
    'messageCode.direct.saleCalendar': 'Đã tạo giao hàng!\r\nBạn có muốn chuyển đến trang lịch bán hàng không?',
    'messageCode.selectNote': 'Hãy chọn nội dung lưu ý.',
    'messageCode.exportAlert': 'Tải về hơn 1000 bản ghi sẽ mất nhiều thời gian. Bạn có muốn tải về không?',
    'messageCode.exportAlertLimit': 'Có quá nhiều kết quả tìm kiếm. Hãy thu hẹp điều kiện tìm kiếm và thực hiện lại.',
    'messageCode.deleteConfirmShuttle': 'Bạn có muốn xóa Shuttle không?',
    'messageCode.cantTransferAndNoneCreditCard': 'Người dùng này không có thẻ tín dụng và không thể thanh toán gói này bằng cách chuyển khoản',
    'messageCode.updateMenuApp': 'Bạn có muốn cập nhật Thực đơn Ứng dụng?',
    'messageCode.deleteConfirmCompany': 'Bạn có muốn xóa công ty này không?',
    'messageCode.complete_reservation': 'Bạn có muốn hoàn thành Đặt chỗ này không?',
    'messageCode.can_not_complete_reservation': 'Bạn không thể hoàn thành đặt chỗ này.',
    'messageCode.confirm_delete_credit_card': 'Bạn có chắc chắn muốn xóa thẻ tín dụng này không?',
    'messageCode.confirm_delete_news_template': 'Bạn có muốn xoá template này không?',
    'wrong.num.of.passenger': 'Đặt chuyến vượt quá số lượng chỗ',
    'messageCode.confirmToChange': 'Bạn chắc chắn muốn thay đổi?',
    'messageCode.createConfirmAutoBusBooking': 'Bạn có muốn đăng ký thông tin chuyến buýt tự động không?',
    'messageCode.confirmDeleteAutoBusBooking': 'Bạn có muốn xóa thông tin chuyến buýt tự động không?',

    // Common error field message
    'errorFields.invalidDateMessage': 'Định dạng ngày tháng không hợp lệ',
    'errorFields.maxDateMessage': 'Không thể nhập ngày sau ngày lớn nhất.',
    'errorFields.minDateMessage': 'Không thể nhập ngày trước ngày nhỏ nhất.',
    'errorFields.passwordRequired': 'Hãy nhập mật khẩu.',
    'errorFields.emailRequired': 'Hãy nhập địa chỉ email.',
    'errorFields.emailFormat': 'Định dạng email đã nhập không đúng',
    'errorFields.textLength': 'Hãy nhập trong {{value}} ký tự',
    'errorFields.cannotChange': 'Không thể thay đổi',
    'errorFields.birthdayRequired': 'Hãy nhập ngày tháng năm sinh',
    'errorFields.accountNameRequired': 'Hãy nhập tên tài khoản',
    'errorFields.rePasswordRequired': 'Hãy nhập lại mật khẩu',
    'errorFields.phoneNumberRequired': 'Hãy nhập số điện thoại.',
    'errorFields.confirmPasswordMatch': 'Mật khẩu mới và mật khẩu nhập lại không giống nhau. Hãy nhập lại một lần nữa.',
    'errorFields.oldPasswordRequired': 'Hãy nhập mật khẩu hiện tại.',
    'errorFields.newPasswordRequired': 'Hãy nhập mật khẩu mới.',
    'errorFields.passwordMin': 'Hãy nhập mật khẩu trên 6 kí tự, có chứa cả chữ viết thường.',
    'errorFields.confirmPasswordRequired': 'Hãy nhập lại mật khẩu.',
    'errorFields.personChargeRequired': 'Hãy nhập tên người phụ trách.',
    'errorFields.firstNameRequired': 'Hãy nhập tên.',
    'errorFields.lastNameRequired': 'Hãy nhập họ.',
    'errorFields.firstNameRequired_furigana': 'Hãy nhập tên furigana.',
    'errorFields.lastNameRequired_furigana': 'Hãy nhập họ furigana.',
    'errorFields.addressRequired': 'Hãy nhập địa chỉ.',
    'errorFields.rePasswordMatch': 'Mật khẩu mới và mật khẩu nhập lại không giống nhau. Hãy nhập lại một lần nữa.',
    'errorFields.businessRequired': 'Hãy nhập tên nhà cung cấp',
    'errorFields.mobileRequired': 'Hãy nhập số điện thoại',
    'errorFields.checkPhoneNumberMatch': 'Số điện thoại không khớp. Hãy nhập lại.',
    'errorFields.sizeFile': 'Kích thước ảnh phải lớn hơn 200KB và nhỏ hơn 500KB.',
    'errorFields.csvFile': 'Hãy chọn file CSV',

    // Common constant
    'paymentMethod.CREDIT_CARD': 'Thẻ tín dụng/Ghi nợ',
    'paymentMethod.CASH': 'Thanh toán trên xe',
    'paymentMethod.TRANSFER': 'Chuyển khoản',
    'paymentMethod.COMPLETE_WITHOUT_PAYMENT': 'Hoàn thành không cần thanh toán',
    'paymentMethod.simulation.COMPLETE_WITHOUT_PAYMENT': 'Hoàn thành không cần thanh toán',
    'paymentMethod.OTHER': 'Khác',
    'currencyCode.VND': 'VND',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': 'JPY',
    'currencyCode.SGD': 'SGD',
    'currencyCode.MYR': 'MYR',

    // Message error call API
    'error.vehicle.config.over.lapse.time': 'Tài xế đã được liên kết với một xe khác.',
    'error.time.must.be.in.future': 'Hãy chọn ngày tương lai',
    'error.arrival.time.before.not.less.interval.time': 'Thời gian đến dự kiến phải lớn hơn khoảng thời gian đến dự kiến',
    'error.file.size.less.than.200KB': 'Kích thước tệp không được nhỏ hơn 200KB',
    'error.file.size.is.empty': 'Không thể tải lên tệp rỗng.',
    'error.no.record': 'Không có dữ liệu.',
    'error.already.exist.or.has.been.invited': 'Địa chỉ email đã tồn tại hoặc đã được mời từ trước.',
    'error.account.user.not.found': 'Không tìm thấy tài khoản người dùng.',
    'error.business.not.found': 'Không tìm thấy business.',
    'error.role.not.found': 'Không tìm thấy vai trò.',
    'error.permission.not.found': 'Không tìm thấy quyền.',
    'error.api.role.permission.unknown': 'Quyền của API vai trò không rõ ràng.',
    'error.api.token.not.match': 'API token không khớp.',
    'error.link.not.found': 'Không tìm thấy đường dẫn.',
    'error.user.not.resumed': 'Tài khoản người dùng chưa được mở lại.',
    'error.link.expired': 'Đường dẫn đã hết hạn.',
    'error.email.not.found': 'Không tìm thấy email.',
    'error.email.password.invalid': 'Mật khẩu không hợp lệ.',
    'error.change.password': 'Mật khẩu hiện tại chưa chính xác.',
    'error.newPassword.must.not.equals.currentPassword': 'Mật khẩu hiện tại và mật khẩu mới không được trùng nhau.',
    'error.not.found.user': 'Không tìm thấy người dùng.',
    'error.user.not.exists': 'Người dùng không tồn tại',
    'error.user.not.exist': 'Người dùng không tồn tại',
    'error.current.password.wrong': 'Mật khẩu hiện tại chưa chính xác.',
    'error.email.exists': 'Địa chỉ email không tồn tại',
    'error.email.exist': 'Địa chỉ email đã tồn tại',
    'error.mobile.exists': 'Địa chỉ email không tồn tại',
    'error.mobile.exist': 'Số điện thoại đã tồn tại',
    'error.reissue.password.token_expired': 'Token đã tồn tại từ trước',
    'error.reissue.password.token_not_match': 'Định dạng token không trùng khớp',
    'error.member.not.exists': 'Người dùng không tồn tại',
    'error.time.from.not.after.time.to': 'Thời gian không hợp lệ.',
    'error.check.value.empty': 'Hãy nhập thông tin.',
    'error.500': 'Lỗi phía máy chủ',
    'error.401': 'Không được phép truy cập',
    'error.403': 'Bị cấm: Bạn không có quyền truy cập tài nguyên này.',
    'error.common': 'Đã xảy ra lỗi. Hãy liên lạc với người quản lý hệ thống.',
    'error.waypoint.not.exist': 'Điểm tham chiếu không tồn tại',
    'error.simulation.one.time.already.exists': 'Giá một lần cho geofence này đã tồn tại',
    'error.csv.export': 'Không thể xuất tệp. Hãy thử lại.',
    'error.timeout': 'Quá trình hết thời gian chờ. Vui lòng liên hệ quản trị hệ thống',
    'invalid.email': 'Địa chỉ email không hợp lệ',
    'invalid.timeTo': 'Thời gian kết thúc không hợp lệ',
    'invalid.timeFrom': 'Thời gian bắt đầu không hợp lệ',
    'update.reservation.request.invalid': 'Yêu cầu cập nhật đặt xe không hợp lệ.',
    'update.reservation.request.invalid.to.complete.operator': 'Không thể đổi sang trạng thái đã chọn. \nTrạng thái có thể chuyển thành là "Người điều hành hủy"',
    'update.reservation.request.invalid.to.complete.or.operator': 'Không thể đổi sang trạng thái đã chọn. \nTrạng thái có thể chuyển thành là "Người điều hành hủy hoặc hoàn thành"',
    'status.reservation.request.not.support': 'Trạng thái đặt xe không hợp lệ.',
    'ski.times.is.not.valid': 'Thời gian không hợp lệ. Hãy kiểm tra và thử lại.',
    'time.from.must.before.time.to': 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc.',
    'vehicle.shift.not.exist': 'Trong khoảng thời gian đã chỉ định có ngày JIT không chạy. Hãy cài đặt lại',
    'group.type.is.incorrect': 'Loại xe không đúng',
    'api.mapping.driver.vehicle.success': 'Đã liên kết xe thành công.',
    'user.email.token.invalid': 'Xác thực email token thất bại.',
    'plan.existed': 'Gói đã tồn tại',
    'create.partner.waypoint.failed': 'Tạo mới đối tác thất bại',
    'api.supplier.not.found': 'Không tìm thấy nhà cung cấp.',
    'api.facility.not.found': 'Không tìm thấy chức năng API.',
    'service.already.exist': 'Dịch vụ đã tồn tại',
    'service.not.exist': 'Dịch vụ không tồn tại',
    'cannot.update.service': 'Không thể cập nhật dịch vụ',
    'transfer.status.cannot.be.changed': 'Trạng thái chuyển khoản không thể thay đổi',
    'subscription.not.exists': 'Gói đăng ký không tồn tại',
    'bank.account.not.exists': 'Tài khoản ngân hàng không tồn tại',
    'bank.account.exists': 'Tài khoản ngân hàng đã tồn tại',
    'recommend.shuttle.not.exist': 'Đề xuất xe đưa đón không tồn tại',
    'partner.waypoint.exists': 'Điểm tham chiếu của đối tác Tồn tại',
    'plan.not.support': 'Gói không hỗ trợ',
    'geofence.id.already.exist': 'Id vùng đã tồn tại',
    'qr.payment.error.not.found': 'Không tìm thấy thanh toán QR lỗi.',
    'partner.waypoint.existed': 'Điểm tham chiếu đối tác đã tồn tại',
    'subscription.payment.not.exists': 'Thanh toán gói không tồn tại',
    'mobile.function.not.exists': 'Chức năng điện thoại không tồn tại',
    'user.family.not.exists': 'Gói người dùng không tồn tại',
    'menu.app.not.exists': 'Menu ứng dụng không tồn tại',
    'plan.not.found': 'Không tìm thấy gói',
    'driver.schedule.is.invalid': 'Lịch trình đã chọn không hợp lệ.',
    'time.is.not.specify.period': 'Chưa chọn thời gian, hoặc thời gian đã chọn không hợp lệ.',
    'jit.setting.not.exist': 'JIT chưa được cài đặt.',
    'error.driver.not.exist': 'Lỗi tài xế không tồn tại',
    'error.vehicle.not.exist': 'Lỗi xe không tồn tại',
    'subscription.payment.fail': 'Thanh toán gói đăng ký thất bại',
    'error.permission.function.not.empty': 'Chức năng cho phép không được để trống',
    'booking.users.out.of.limit': 'không thể đặt trước vì chúng tôi đã vượt quá giới hạn số lượng người dùng.',
    'error.vehicle.shift.over.lapse.time': 'Chuyển xe theo thời gian trôi đi',
    'api.send.jit.order.faulty': 'Jit Order số: {{number}} bị lỗi. Xin vui lòng kiểm tra hoặc gửi lại',
    'company.not.exists': 'Công ty không tồn tại',
    'api.csv.upload.failed': 'Tệp CSV bị lỗi',
    'mass.route.existed': 'Tuyến đường này đã tồn tại',
    'error.booking.not.found': 'Không tìm thấy tài nguyên',
    'resource.notfound': 'Không tìm thấy đặt chỗ',
    'error.role.permission.name.already.exist': 'Tên quyền của vai trò đã tồn tại',
    'number_of_unit.must.less.than.max_transfer_days': 'Số ngày thanh toán tối đa phải nhỏ hơn hoặc bằng tổng số ngày',
    'max_transfer_days.must.less.than.reminder_days': 'Số ngày nhắc nhở phải nhỏ hơn số ngày thanh toán',
    'number_of_unit.must.less.than.reminder_days': 'Số ngày nhắc nhở phải nhỏ hơn tổng số ngày',
    'subscription.must.transfer': 'Gói đăng ký phải được thanh toán trước khi thêm chỗ',
    'change.payment.method.not.support': 'Phương thức thanh toán này không được hỗ trợ trong đăng ký này',
    'jit.generate_route_fail': 'Không thể tạo tuyến đường vì chế độ phương tiện không được hỗ trợ',
    'the.process.is.being.performed.by.another.user': 'Hành động đang được thực thi bởi một người dùng khác: {{username}}',
    'error.select.interval_departure': 'Vui lòng chọn khoảng thời gian khởi hành',
    'error.select.interval_arrival': 'Vui lòng chọn khoảng thời gian đến',
    'max.rides.must.not.less.than.additional.rides': 'Giới hạn số lần đi xe không được ít hơn số lần đi xe bổ sung',
    'booking.invalid.geofence.rules': 'điểm bạn chọn không hỗ trợ đặt shuttle, vui lòng chọn điểm khác',
    'error.date.not.in.past': 'Hãy chọn thời gian phát hành trong tương lai',
    'route.not.found': 'Không tìm tháy chuyến',
    'agency.not.found': 'Không tìm thấy nhà cung cấp',
    'station.not.found': 'Không tìm thấy ga',
    'hash.value.is.invalid': 'Giá trị hash không hợp lệ',
    'payment.fail': 'Thanh toán thất bại',
    'duplicate.station': 'Ga chọn đang bị trùng',
    'ticket.not.found': 'Không tìm thấy loại vé',
    'start_date_is_invalid': 'Ngày có hiệu lực vé ko hợp lệ',
    'error.date.validation.failed': 'Ngày bắt đầu phải lớn hơn hoặc bằng ngày phân phối.',
    'api.csv.upload.member.code.duplicate': 'ID hội viên bị trùng lặp.',
    'error.restrict.waypoint': 'Điểm tham chiếu {{fields}} không thể sử dụng do đang nằm trong thời gian hạn chế. Hãy chọn điểm tham chiếu khác ',
    'mass.booking.subscription.expired': 'Không thể sử dụng gói đi không giới hạn này',
    'notification.waypoint.restrict.content': 'Điểm dừng mobi bạn đã chọn hiện không khả dụng. Xin lỗi vì sự bất tiện này, vui lòng chọn lại điểm dừng mobi.',
    'mass.simulation.end.time.invalid': 'Hãy cập nhật thời gian kết thúc simulation nhiều hơn một ngày hoặc vô hiệu hoá simulation này.',
    'geofence.not.support.advance.booking': 'Khu vực không hỗ trợ đặt chỗ trước',
    'error.vehicle.session.default.location.invalid': 'Tọa độ không tồn tại trong vùng này',
    'one.time.price.special.invalid': 'Phí hành khách đặc biệt phải nhỏ hơn phí một lần',
    'mass.card.not.found': 'Thẻ tín dụng đã hết hạn. Vui lòng thử lại!',

    // Message success call API
    'api.delete.recommend.shuttle.success': 'Xóa xe đưa đón Thành công',
    'api.create.recommend.shuttle.success': 'Tạo mới xe đưa đón Thành công',
    'api.update.recommend.shuttle.success': 'Cập nhật xe đưa đón Thành công',
    'api.create.comment.success': 'Tạo bình luận Thành công',
    'api.update.comment.success': 'Cập nhật nhận xét Thành công',
    'api.delete.comment.success': 'Xóa bình luận thành công',
    'api.order.plan.success': 'Đặt gói thành công',
    'api.create.partner.success': 'Tạo đối tác thành công',
    'api.update.partner.success': 'Cập nhật đối tác thành công',
    'api.update.sale.times.success': 'Đã cập nhật thời gian bán thành công.',
    'api.update.enabled.vehicle.success': 'Đã cập nhật đặt chỗ thành công.',
    'api.update.reservation.success': 'Đã cập nhật đặt chỗ thành công.',
    'api.import.vehicle.config.success': 'Nhập CSV thành công.',
    'api.invite.success': 'Đã mời tài khoản thành công.',
    'api.active.account.success': 'Đã kích hoạt tài khoản thành công.',
    'api.updated.account.success': 'Đã cập nhật tài khoản.',
    'api.execute.reissue.success': 'Đã phát hành lại mật khẩu thành công.',
    'Api.success.change.password': 'Đã cập nhật mật khẩu.',
    'api.reissue_password.request.success': 'Đã yêu cầu cấp lại mật khẩu.',
    'api.reissue_password.verify.success': 'Xác minh cấp lại mật khẩu thành công',
    'api.reissue_password.confirm.success': 'Xác nhận cấp lại mật khẩu thành công',
    'api.add.supplier.success': 'Đã thêm nhà cung cấp.',
    'api.updated.supplier.success': 'Đã thêm nhà cung cấp.',
    'api.deleted.supplier.success': 'Đã xóa nhà cung cấp.',
    'api.delete.driver.success': 'Đã xóa tài xế.',
    'api.update.driver.success': 'Đã cập nhật tài xế.',
    'api.create.driver.success': 'Đã tạo tài xế.',
    'api.delete.caution.success': 'Đã xóa item.',
    'api.update.caution.success': 'Đã cập nhật item.',
    'api.create.caution.success': 'Đã tạo item.',
    'api.updated.member.success': 'Đã cập nhật thành viên.',
    'api.created.member.success': 'Đã tạo thành viên.',
    'api.canceled.member.success': 'Thành viên đã bị hủy.',
    'api.deleted.facility.success': 'Đã xóa cơ sở',
    'api.updated.facility.success': 'Đã cập nhật cơ sở',
    'api.add.facility.success': 'Văn phòng kinh doanh - cửa hàng đã được đăng ký.',
    'api.delete.vehicle.success': 'Đã xóa xe của nhà cung cấp.',
    'api.updated.vehicle.success': 'Đã cập nhật xe của nhà cung cấp.',
    'api.created.vehicle.success': 'Đã hoàn thành đăng ký xe của nhà cung cấp.',
    'api.update.calendar.success': 'Đã cập nhật lịch.',
    'api.create.image.success': 'Đã tạo ảnh.',
    'api.update.image.success': 'Đã cập nhật ảnh.',
    'api.delete.image.success': 'Đã xóa ảnh.',
    'api.create.delivery.success': 'Đã tạo sản phẩm thành công.',
    'api.update.delivery.success': 'Đã cập nhật sản phẩm thành công.',
    'api.update.delivery.time.setting.success': 'Bạn có muốn cập nhật cài đặt thời gian giao hàng không?',
    'api.delete.delivery.success': 'Đã xóa sản phẩm thành công.',
    'api.register.congestion.success': 'Đã đăng ký tình trạng đông.',
    'api.update.jit.special.success': 'Đã cập nhật thành công cài đặt JitSpecial.',
    'api.add.role.permission.success': 'Đã tạo thành công loại tài khoản.',
    'api.update.role.permission.success': 'Đã cập nhật thành công loại tài khoản.',
    'api.delete.role.permission.success': 'Đã xóa thành công loại tài khoản.',
    'permission.denied': 'Truy cập bị từ chối.',
    'permission.reservation.denied': 'Bạn không có quyền xem chi tiết đặt chỗ.',
    'api.disable.error.qr.success': 'Đã xóa thành công thanh toán QR lỗi.',
    'api.create.one.time.price.success': 'Tạo mới giá một lần thành công',
    'api.update.one.time.price.success': 'Cập nhật giá một lần thành công',
    'api.create.plan.success': 'Tạo mới gói thành công',
    'api.update.plan.success': 'Cập nhật gói thành công',
    'api.create.recommend.success': 'Tạo đề xuất thành công',
    'api.update.recommend.success': 'Cập nhật đề xuất thành công',
    'api.delete.recommend.success': 'Xóa đề xuất thành công',
    'Api.success': 'Thành công',
    'Api.fail': 'Thất bại',
    'api.create.service.success': 'Tạo mới dịch vụ thành công',
    'api.update.service.success': 'Cập nhật dịch vụ thành công',
    'api.delete.service.success': 'Xóa dịch vụ thành công',
    'api.create.waypoint.success': 'Tạo mới điểm tham chiếu thành công',
    'api.delete.waypoint.success': 'Xóa điểm tham chiếu thành công',
    'change.payment.transfer.status.success': 'Thay đổi trạng thái thanh toán chuyển khoản thành công',
    'payment.transfer.status.cannot.be.changed': 'Thay đổi trạng thái thanh toán chuyển khoản không thành công',
    'user.verify.email.token.success': 'Đã xác thực thành công.',
    'api.create.bank.account.success': 'Tạo mới tài khoản ngân hàng thành công',
    'api.update.bank.account.success': 'Cập nhật tài khoản ngân hàng thành công',
    'api.refresh.cache.success': 'Làm mới bộ nhớ đệm thành công',
    'api.create.partner.waypoint.success': 'Tạo mới điểm tham chiếu đối tác thành công',
    'api.delete.mobile.funciton.success': 'Xóa chức năng điện thoại thành công',
    'api.create.mobile.function.success': 'Tạo mới chức năng điện thoại thành công',
    'api.update.mobile.function.success': 'Cập nhật chức năng điện thoại thành công',
    'api.create.user.family.success': 'Tạo mới gói người dùng thành công',
    'api.update.user.family.success': 'Cập nhật gói người dùng thành công',
    'api.delete.user.family.success': 'Xóa gói người dùng thành công',
    'api.delete.menu.app.success': 'Xóa menu ứng dụng thành công',
    'api.create.menu.app.success': 'Tạo mới menu ứng dụng thành công',
    'api.update.menu.app.success': 'Cập nhật menu ứng dụng thành công',
    'api.copy.plan.success': 'Sao chép gói thành công',
    'api.not.null': '{{field}} không được null',
    'Api.unknown.error': 'Api lỗi chưa xác định',
    'api.create.jit.order.success': 'Tạo mới Jit thành công',
    'api.update.jit.order.success': 'Cập nhật Jit thành công',
    'api.send.jit.order.success': 'Gửi Jit thành công',
    'api.clone.jit.order.success': 'Clone Jit thành công',
    'api.delete.area_geofence.success': 'Xóa vùng thành công',
    'api.create.area_geofence.success': 'Tạo mới vùng thành công',
    'api.update.area_geofence.success': 'Cập nhật vùng thành công',
    'api.delete.company.success': 'Xóa công ty thành công',
    'api.create.company.success': 'Tạo mới công ty thành công',
    'api.update.company.success': 'Cập nhập công ty thành công',
    'api.delete.stop_master.success': 'Xóa điểm dừng thành công',
    'api.create.stop_master.success': 'Tạo mới điểm dừng thành công',
    'api.update.stop_master.success': 'Cập nhật điểm dừng thành công',
    'api.sync.stop_master.success': 'Đồng bộ điểm dừng thành công',
    'api.update.just.in.time.setting.success': 'Cập nhật cài đặt JIT thành công',
    'api.delete.qr_code.success': 'Xóa mã QR thành công',
    'api.create.qr_code.success': 'Tạo mới mã QR thành công',
    'api.update.qr_code.success': 'Cập nhật mã QR thành công',

    // Image selector
    'imageSelector.error': 'Không thể chọn từ 5 ảnh trở lên.',
    'image.maxSize': 'Kích thước ảnh {{imageWidth}}x{{imageHeight}} không hợp lệ. Kích thước tối đa cho phép: {{maxWidth}}x{{maxHeight}}',

    // Custom pagination
    'customPagination.rowsPerPage': 'Số dòng trong mỗi trang',
    'customPagination.startEndRows': '{{start}} - {{end}} của {{rows}}',

    // Login page
    'login.passwordReissue': 'Nhấp vào đây để  cấp lại mật khẩu.',

    // Registration page
    'registration.title': 'Tạo tài khoản',
    'registration.accountType': 'Loại tài khoản',
    'registration.authority': 'Quyền',
    'registration.businessName': 'Tên công ty',
    'registration.contactName': 'Tên người phụ trách liên lạc',
    'registration.rePassword': 'Nhập lại mật khẩu',

    // Change password page
    'changePassword.title': 'Thay đổi mật khẩu',
    'changePassword.msgExpired': '※Thời gian hiệu lực của mật khẩu hiện tại là 30 ngày.',
    'changePassword.currentPassword': 'Mật khẩu hiện tại',
    'changePassword.newPassword': 'Mật khẩu mới',
    'changePassword.confirmPassword': 'Nhập lại mật khẩu',
    'changePassword.labelCurrentPassword': 'Hãy nhập mật khẩu hiện tại.',
    'changePassword.labelNewPassword': 'Hãy nhập mật khẩu trên 6 kí tự, có chứa cả chữ viết thường.',
    'changePassword.labelConfirmPassword': 'Hãy nhập lại mật khẩu một lần nữa để xác nhận.',

    // Password reissue page
    'passwordReissue.title': 'Phát hành lại mật khẩu',
    'passwordReissue.msg': 'Hãy nhập thông tin tài khoản vầ ấn nút gửi.',
    'passwordReissue.msg_child': 'Chúng tôi sẽ gửi email chứa đường dẫn đến trang dùng để cài đặt lại mật khẩu.',
    'passwordReissue.msgFooter': 'Nếu không thể nhận được email, hãy kiểm tra lại địa chỉ email và gửi lại một lần nữa.',

    // Password reissue confirm page
    'passwordReissueConfirm.title': 'Phát hành lại mật khẩu',
    'passwordReissueConfirm.new_password': 'Mật khẩu mới',
    'passwordReissueConfirm.re_password': 'Nhập lại mật khẩu',

    // Account invite Page
    'accountInvite.title': 'Mờ mở tài khoản',
    'accountInvite.accountType': 'Loại tài khoản - Quyền',
    'accountInvite.businessName': 'Tên công ty',
    'accountInvite.contactName': 'Tên người phụ trách',
    'accountInvite.checkInputMsg': '(Tối đa 128 ký tự)',
    'accountInvite.noAccountType': 'Công ty này chưa có loại tài khoản nào',

    // Account management Page
    'accountManagement.title': 'Quản lý tài khoản',
    'accountManagement.titleSearch': 'Điều kiện tìm kiếm',
    'accountManagement.accountType': 'Loại tài khoản',
    'accountManagement.status': 'Trạng thái hội viên',
    'accountManagement.member_status': 'Trạng thái thành viên',
    'accountManagement.verify_mobile_status': 'Trạng thái xác thực số điện thoại',
    'accountManagement.update_info_status': 'Trạng thái cập nhật thông tin cá nhân',
    'accountManagement.verify_email_status': 'Trạng thái xác thực email',
    'accountManagement.signUpType': 'Phương thức đăng ký',
    'accountManagement.registration_platform': 'Nền tảng đăng ký',
    'accountManagement.registration_platform.mobi_app': 'MOBI APP',
    'accountManagement.registration_platform.admin': 'ADMIN',
    'accountManagement.app_version': 'Phiên bản ứng dụng',
    'accountManagement.statusValue.inUse': 'Đang sử dụng',
    'accountManagement.statusValue.stop': 'Ngừng sử dụng',
    'accountManagement.statusValue.inRec': 'Đang mời',
    'accountManagement.contactName': 'Tên người liên lạc',
    'accountManagement.businessName': 'Tên nhà cung cấp',
    'accountManagement.companyName': 'Tên công ty (hiển thị người dùng)',
    'accountManagement.partialMatch': 'Khớp một phần',
    'accountManagement.resendInviteEmail': 'Gửi lại thư mời',
    'accountManagement.accountRestart': 'Mở lại tài khoản',
    'accountManagement.accountSuspension': 'Tạm dừng tài khoản',
    'accountManagement.autoUpdate': 'Tự động cập nhật',
    'accountManagement.autoUpdateValue.inValid': 'Không hợp lệ',
    'accountManagement.autoUpdateValue.valid': 'Hợp lệ',
    'accountManagement.autoUpdateWarn': 'Nếu là bật, tìm kiếm sẽ được tự động thực hiện sau khi cập nhật.',
    'accountManagement.author': 'Quyền',
    'accountManagement.registrationDate': 'Ngày đăng ký/ mời (UTC)',
    'accountManagement.resendInvitation': 'Bạn có muốn gửi lại lời mời đến tài khoản đã chọn không?',
    'accountManagement.restartAccounts': 'Bạn có muốn mở lại tài khoản đã chọn không?',
    'accountManagement.suspendAccounts': 'Bạn có muốn tạm dừng tài khoản đã chọn không?',

    // Update account page
    'updateAccount.before': 'Trước thay đổi',
    'updateAccount.after': 'Sau thay đổi ',
    'updateAccount.memo': 'Ghi chú',
    'updateAccount.memoSize': '(Tối đa 2000 ký tự)',
    'updateAccount.updateModal': 'Cập nhật tài khoản',

    // Account type page
    'accountType.register.title': 'Đăng ký loại tài khoản',
    'accountType.detail.title': 'Chi tiết loại tài khoản',
    'accountType.typeName': 'Tên loại',
    'accountType.supplierName': 'Tên nhà cung cấp',
    'accountType.facilityName': 'Tên văn phòng kinh doanh - cửa hàng',
    'accountType.majorItems': 'Mặt hàng chính',
    'accountType.mediumItem': 'Mặt hàng trung bình',
    'accountType.function': 'Chức năng',
    'accountType.functionalRestriction': 'Giới hạn chức năng',
    'accountType.allow': 'Cho phép',
    'accountType.management.title': 'Loại tài khoản',
    'accountType.typeId': 'ID loại',
    'accountType.titleSearch': 'Điều kiện tìm kiếm',
    'accountType.deleteConfirmAccountType': 'Bạn có muốn xóa không?',

    // Member management page
    'memberManagement.total_one_time': 'Số tiền đã sử dụng',
    'memberManagement.sts.withdraw': 'Đã rút.',
    'memberManagement.sts.banned': 'Đã cấm.',
    'memberManagement.sts.update': 'Chưa cập nhật thông tin',
    'memberManagement.sts.verify': 'Chưa xác thực email',
    'memberManagement.sts.verified': 'Đã xác thực email',
    'memberManagement.sts.un_verified_mobile': 'Chưa xác thực số điện thoại　',
    'memberManagement.sts.verified_mobile': 'Đã xác thực số điện thoại',
    'memberManagement.sts.updated_info': 'Đã cập nhật thông tin cá nhân',
    'memberManagement.status.registration_completed': 'Hoàn thành đăng ký',
    'memberManagement.status.incomplete_registration': 'Chưa hoàn thành đăng ký',
    'memberManagement.status.withdraw': 'Đã thoát hội viên',
    'memberManagement.status.verified': 'Đã xác thực',
    'memberManagement.status.un_verified': 'Chưa xác thực',
    'memberManagement.status.updated': 'Đã cập nhật',
    'memberManagement.status.un_update': 'Chưa cập nhật',
    'memberManagement.sex.none': 'Không có',
    'memberManagement.sex.male': 'Nam',
    'memberManagement.sex.female': 'Nữ',
    'memberManagement.createMember': 'Tạo thành viên',
    'memberManagement.memberType': 'Loại hội viên',
    'memberManagement.memberId': 'ID hội viên',
    'memberManagement.memberEmail': 'Địa chỉ mail hội viên',
    'memberManagement.memberName': 'Tên thành viên',
    'memberManagement.memberPhone': 'Số điện thoại hội viên',
    'memberManagement.sex': 'Giới tính',
    'memberManagement.enrollmentDateUTC': 'Ngày giờ đăng ký (UTC)',
    'memberManagement.enrollmentDate': 'Ngày giờ đăng ký',
    'memberManagement.withdrawalDateTime': 'Ngày giờ chi tiền (UTC)',
    'memberManagement.birthDay': 'Ngày sinh',
    'memberManagement.in_sub': 'Trong gói đăng ký',
    'memberManagement.verified': 'Đã xác thực',
    'memberManagement.uuid': 'Veritrans会員ID',
    'memberManagement.confirm_credit_card': 'Xác thực thẻ tín dụng',
    'memberManagement.cardNumber': 'Số thẻ',
    'memberManagement.cardExpire': 'Ngày hết hạn',
    'memberManagement.cantGetCardInfo': 'Chưa đăng ký thẻ',
    'memberManagement.memberRegistrationDate': 'Ngày đăng ký thành viên',
    'memberManagement.force_logout': 'Bắt buộc đăng xuất',
    'memberManagement.confirm_force_logout': 'Bạn có muốn buộc người dùng này đăng xuất không?',
    'memberManagement.on_booking': 'Người dùng đang đặt chỗ',
    'memberManagement.on_subscription': 'Người dùng đang trong gói đăng ký',
    'memberManagement.can_not_cancel': 'Không thể hủy thành viên',
    'memberManagement.confirm_free_trial': 'Bạn có muốn tiếp tục gói dùng thử miễn phí cho người dùng này không?',
    'memberManagement.no_smart_phone': 'Không có điện thoại',
    'memberManagement.membershipStatus': 'Trạng thái hội viên',
    'memberManagement.membershipType': 'Phân loại hội viên',
    'memberManagement.referrer_code_name': 'Tên mã giới thiệu',
    'memberManagement.nricNumber': 'Số thẻ định danh',
    'memberManagement.passportNumber': 'Số hộ chiếu',
    'memberManagement.identityNumber': 'Số thẻ định danh/ Số thẻ hộ chiếu',
    'memberManagement.nircNumbericValidation': 'Số thẻ định danh/ Số thẻ hộ chiếu',
    'memberManagement.passportNummberValidation': 'Số hộ chiếu đang không đúng định dạng hoặc chứa kí tự đặc biệt',
    'memberManagement.identityVerificationManagement': 'Quản lý xác thực Thẻ định danh/Hộ chiếu',
    'memberManagement.identityVerificationDetail': 'Chi tiết Thẻ định danh/Hộ chiếu',
    'memberManagement.userCode': 'Mã người dùng',
    'memberManagement.registrationTime': 'Thời gian đăng ký',
    'memberManagement.registrationPeriod': 'Thời gian Đăng ký',
    'memberManagement.registrationFrom': 'Từ ngày',
    'memberManagement.registrationTo': 'Đến ngày',
    'memberManagement.applicationDocument': 'Loại Hồ sơ đăng ký',
    'memberManagement.applicationStatus': 'Trạng thái đăng ký',
    'memberManagement.applicationLanguage': 'Ngôn ngữ ứng dụng',
    'memberManagement.nric': 'NRIC',
    'memberManagement.passport': 'Hộ chiếu',
    'memberManagement.mailLanguage': 'Ngôn ngữ mail',
    'memberManagement.reasonNote': 'Lý do/Ghi chú',
    'memberManagement.btnUpdateVerificationResult': 'Cập nhật kết quả xác thực',
    'memberManagement.applicationStatus.unVerified': 'Chờ duyệt',
    'memberManagement.applicationStatus.cancelRegistration': 'Hủy đăng ký',
    'memberManagement.applicationStatus.verified': 'Đã duyệt',
    'memberManagement.applicationStatus.rejected': 'Từ chối',
    'memberManagement.profileImage': 'Ảnh hồ sơ',
    'memberManagement.nricImage': 'Ảnh thẻ định danh',
    'memberManagement.passportImage': 'Ảnh hộ chiếu',
    'memberManagement.memoField': 'Lịch sử duyệt',
    'memberManagement.registrationPeriod.within30days': 'Ngày bắt đầu và ngày kết thúc phải trong vòng 30 ngày',
    'memberManagement.hasNewAvatar': 'Người dùng gần đây đã thay ảnh hồ sơ khác:',
    'memberManagement.selectUserCodeOrStatus': 'Hãy nhập user code hoặc trạng thái duyệt',

    // Update member page
    'updateMember.province': 'Tỉnh thành',
    'updateMember.district': 'Quận huyện',
    'updateMember.updateModal': 'Cập nhật thành viên?',
    'updateMember.createModal': 'Thêm mới thành viên?',
    'updateMember.note': 'Mục chú ý',
    'updateMember.firstName': 'Tên',
    'updateMember.lastName': 'Họ',
    'updateMember.firstName_furigana': 'Tên furigana',
    'updateMember.lastName_furigana': 'Họ furigana',
    'updateMember.gender': 'Giới tính',
    'updateMember.app_version': 'Phiên bản ứng dụng',
    'updateMember.os': 'Hệ điều hành',
    'updateMember.screen_size': 'Kích thước màn hình',
    'updateMember.cancelModal': 'Hủy thành viên',
    'updateMember.cancelNotifyEmail': 'Tài khoản sẽ bị khóa, người dùng sẽ được thông báo qua email.',
    'updateMember.cancelNotify': 'Email thông báo',

    // Member referrer list
    'memberReferrer.completedDateTime': 'Ngày giờ liên kết thành công',
    'memberReferrer.canceledDateTime': 'Ngày giờ hủy liên kết',
    'memberReferrer.inactiveReason': 'Lí do hủy liên kết',
    'memberReferrer.inactivatedByMember': 'Hủy liên kết bởi hội viên',
    'memberReferrer.active': 'Đã liên kết',
    'memberReferrer.inactive': 'Huỷ liên kết',

    // member's favorite waypoint
    'favoriteWaypoint.add_waypoint': 'Thêm điểm tham chiếu',
    'favoriteWaypoint.waypoint_list': 'Danh sách điểm tham chiếu yêu thích',
    'favoriteWaypoint.favorite_tag': 'Tên đăng ký yêu thích',
    'favoriteWaypoint.location': 'Vị Trí',
    'favoriteWaypoint.pick_up': 'Điểm lên',
    'favoriteWaypoint.drop_off': 'Điểm xuống',
    'favoriteWaypoint.pick_up_name': 'Tên điểm tham chiếu lên',
    'favoriteWaypoint.drop_off_name': 'Tên điểm tham chiếu xuống',
    'favoriteWaypoint.home': 'Nhà',
    'favoriteWaypoint.create_by': 'Tạo bởi',
    'favoriteWaypoint.form': 'Biểu mẫu Điểm tham chiếu yêu thích',
    'favoriteWaypoint.uploadModal': 'Bạn có muốn tải lên Điểm tham chiếu yêu thích này không?',
    'favoriteWaypoint.deleteModal': 'Bạn có muốn xóa Điểm tham chiếu yêu thích này không?',
    'favoriteWaypoint.changeLatLongAndName': 'Tên và vị trí của địa điểm đã bị thay đổi',
    'favoriteWaypoint.changeLatLong': 'Vị trí của địa điểm đã bị thay đổi',
    'favoriteWaypoint.changeName': 'Tên địa điểm đã bị thay đổi',

    // Business management page
    'businessType.other': 'Khác',
    'businessRole.global': 'Quản lí hệ thống (toàn cầu)',
    'businessRole.country': 'Quản lí hệ thống (theo quốc gia)',
    'businessRole.business': 'Quản lí hệ thống riêng lẻ',
    'businessType.taxi': 'Công ty taxi',
    'businessType.bus': 'Công ty xe buýt',
    'businessStatus.contracted': 'Đã kí hợp đồng',
    'businessStatus.suspended': 'Dừng bán',
    'businessStatus.unsigned': 'Chưa ký hợp đồng',
    'businessType.1': 'Khác',
    'businessType.2': 'Đi chợ',
    'businessType.3': 'Vận chuyển người',
    'businessIndustry.0': 'Siêu thị',
    'businessIndustry.1': 'Vận chuyển',
    'businessIndustry.2': 'Cửa hàng đồ lưu niệm',
    'businessIndustry.3': 'Nhà hàng',
    'businessCountry.jp': 'Nhật Bản',
    'businessCountry.vn': 'Việt Nam',
    'businessCountry.sg': 'Singapore',
    'businessCountry.my': 'Malaysia',
    'businessLanguage.ja': 'Tiếng Nhật',
    'businessLanguage.eng': 'Tiếng Anh',
    'businessLanguage.vie': 'Tiếng Việt',
    'businessLanguage.chi': 'Tiếng Trung',
    'businessInformation.title': 'Thông tin nhà cung cấp',
    'businessInformation.searchTitle': 'Điều kiện tìm kiếm',
    'businessInformation.id': 'ID nhà cung cấp',
    'businessInformation.status': 'Trạng thái vận hành',
    'businessInformation.type': 'Loại nhà cung cấp',
    'businessInformation.role': 'Quyền của nhà cung cấp',
    'businessInformation.cannotRegisterSaleOffice': '*Nhà cung cấp có quyền quản lý hệ thống toàn cầu/theo quốc gia sẽ không thể đăng ký văn phòng kinh doanh - cửa hàng',
    'businessInformation.industry': 'Ngành',
    'businessInformation.code': 'ID nhà cung cấp',
    'businessInformation.name': 'Tên nhà cung cấp',
    'business.question.confirm.update': 'Bạn có muốn đăng ký xe không?',
    'business.question.confirm.create': 'Bạn có muốn đăng ký nhà cung cấp không?',
    'company.question.confirm.update': 'Bạn có muốn thay đổi công ty không?',
    'business.question.confirm.paymentRetry': 'Bạn có muốn thử thanh toán lại không?',
    'company.question.confirm.create': 'Bạn muốn tạo mới công ty không?',
    'coupon.question.confirm.update': 'Bạn có muốn cập nhật phiếu mua hàng?',
    'coupon.question.confirm.create': 'Bạn có muốn tạo phiếu mua hàng?',
    'coupon.question.confirm.delete': 'Bạn có muốn xóa phiếu mua hàng?',

    // Business Detail page
    'businessDetail.form': 'Biểu mẫu tạo nhà cung cấp mới',
    'businessDetail.title': 'Thông tin chi tiết nhà cung cấp',
    'businessDetail.id': 'ID nhà cung cấp',
    'businessDetail.languages': 'Ngôn ngữ',
    'businessDetail.fee': 'Chi phí',
    'businessDetail.other': 'Khác',
    'businessDetail.rate': 'Tỉ lệ',

    // Business Vehicle
    'businessVehicle.title': 'Thông tin xe',
    'businessVehicle.add': 'Đăng ký xe',
    'businessVehicle.form': 'Đăng ký xe',
    'businessVehicle.detail': 'Chi tiết xe',
    'businessVehicle.searchTitle': 'Điều kiện tìm kiếm',
    'businessVehicle.code': 'Số xe',
    'businessVehicle.code_id': 'ID xe',
    'businessVehicle.status': 'Trạng thái vận hành',
    'businessVehicle.businessCode': 'Mã nhà cung cấp',
    'businessVehicle.businessName': 'Tên nhà cung cấp',
    'businessVehicle.businessId': 'ID nhà cung cấp',
    'businessVehicle.sales_office_store_code': 'ID văn phòng kinh doanh/cửa hàng',
    'businessVehicle.sales_office_store_name': 'Tên văn phòng kinh doanh/cửa hàng',
    'businessVehicle.type': 'Loại xe',
    'businessVehicle.sheetType': 'Loại ghế',
    'businessVehicle.brand': 'Hãng',
    'businessVehicle.vehicleName': 'Tên xe',
    'businessVehicle.vehicleNumber': 'Số xe',
    'businessVehicle.vehicleInformation': 'Thông tin xe',
    'businessVehicle.numberOfSeats': 'Số ghế',
    'businessVehicle.insuranceType': 'Loại bảo hiểm',
    'businessVehicle.displayVehicle': 'Hiển thị',
    'businessVehicle.numberOfRows': 'Số hàng',
    'businessVehicle.seatsPerRow': 'Số ghế mỗi hàng',
    'businessVehicle.driverSeat': 'Lái xe',
    'businessVehicle.seatYear': 'Có',
    'businessVehicle.seatNo': 'Không',
    'businessVehicle.chairAvailable': 'Ghế đã được đặt',
    'businessVehicle.chairNotUse': 'Ghế trống',
    'businessVehicle.blankSpace': 'Khoảng trống',
    'businessVehicle.type.large': 'Lớn',
    'businessVehicle.type.medium': 'Trung bình',
    'businessVehicle.type.small': 'Nhỏ',
    'businessVehicle.type.other': 'Khác',
    'businessVehicle.status.operable': 'Có thể vận hành',
    'businessVehicle.status.stopping': 'Ngừng sử dụng',
    'businessVehicle.seatType.standard': 'Tiêu chuẩn',
    'businessVehicle.seatType.premium': 'Cao cấp',
    'businessVehicle.color': 'Màu xe',
    'businessVehicle.usagePattern': 'Loại sử dụng',
    'businessVehicle.mobiType': 'Loại mobi',
    'businessVehicle.color.yellow': 'Màu vàng',
    'businessVehicle.color.orange': 'Màu cam',
    'businessVehicle.color.red': 'Màu đỏ',
    'businessVehicle.color.purple': 'Màu tím',
    'businessVehicle.color.blue': 'Màu xanh lam',
    'businessVehicle.color.green': 'Màu xanh lục',
    'businessVehicle.color.white': 'Màu trắng',
    'businessVehicle.color.black': 'Màu đen',
    'businessVehicle.color.brown': 'Màu nâu',
    'businessVehicle.color.gray': 'Màu xám',
    'businessVehicle.color.pink': 'Màu hồng',
    'businessVehicle.mobiType.TAXI': 'TAXI',
    'businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND': 'Xe buýt đưa đón theo yêu cầu',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'JIT HTW',
    'businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'JIT WTH',
    'businessVehicle.mobiType.UNITRAND': 'UNITRAND',
    'businessVehicle.mobiType.searchResult.TAXI': 'TAXI',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_ON_DEMAND': 'Theo yêu cầu',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK': 'JIT HTW',
    'businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME': 'JIT WTH',
    'businessVehicle.operatingHours': 'Thời gian vận hành xe',
    'businessVehicle.jit': 'Cài đặt chi tiết JIT',
    'businessVehicle.date': 'Ngày tháng',
    'businessVehicle.title_shift': 'Dừng ca JIT',
    'businessVehicle.vir': 'Xe ảo',
    'businessVehicle.driver_schedule_register': 'Đăng ký lịch trình tài xế',
    'businessVehicle.driver_schedule_register_form': 'Form đăng ký lịch trình tài xế',
    'businessVehicle.applyFromTo': 'Bạn có muốn áp dụng cho thời gian từ {{start_date}} tới {{end_date}} không?',
    'businessVehicle.applyFromToOver': 'Bạn có muốn áp dụng cho thời gian từ {{start_date}} tới {{end_date}} không?\nVì khoảng thời gian này dài hơn một tháng nên có thể mất chút thời gian để xử lý. Bạn có muốn chạy nó không?',
    'businessVehicle.title_jit_special_setting': 'Cài đặt đặc biệt cho JIT',
    'businessVehicle.start_date': 'Ngày bắt đầu',
    'businessVehicle.end_date': 'Ngày kết thúc',
    'businessVehicle.vehicle_mode': 'Chế độ xe',
    'businessVehicle.modal.add.row': 'Hãy thêm dòng.',
    'vehicle.registration_number': 'Biển số xe',
    'businessVehicle.auto_config': 'Tự động cấu hình',
    'businessVehicle.confirm_delete_shift': 'Bạn có chắc muốn xóa các ca này?',
    'businessVehicle.trackingGPSTitle': 'Theo dõi GPS phương tiện thời gian thực',
    'businessVehicle.trackingTitle': 'Theo dõi phương tiện vận hành',
    'businessVehicle.vehicle_not_in_geofence': 'Phương tiện này không nằm trong hàng rào địa lý',
    'businessVehicle.validate.between_a_day': 'Ngày kết thúc và bắt đầu phải trong 24 giờ',
    'businessVehicle.validate.from_to': 'Ngày kết thúc phải lớn hơn ngày bắt đầu',
    'businessVehicle.pickup_date': 'Ngày lựa chọn',
    'businessVehicle.vehicle_selection_period': 'Thời gian chọn xe',
    'businessVehicle.driver_shift_config': 'Tạo ca cho tài xế',
    'businessVehicle.driver_vehicle_association': 'Liên kết tài xế - xe',
    'businessVehicle.view_shift_config': 'Xem danh sách tạo ca',
    'businessVehicle.vehicleAddition': 'Thông tin xe bổ sung',
    'businessVehicle.description_notice': 'Hãy nhập Chi tiết',
    'businessVehicle.description_name': 'Chi tiết',
    'businessVehicle.title_notice': 'Hãy nhập Tiêu đề',
    'businessVehicle.title_name': 'Tiêu đề ',
    'businessVehicle.field': 'Trường thông tin ',
    'businessVehicle.line_break': '(Có thể xuống)',
    'businessVehicle.delete_additional': 'Bạn chắc chắn muốn xóa trường thông tin này?',
    'businessVehicle.booking_normal': 'ODM Đặt ngay',
    'businessVehicle.booking_advanced': 'ODM Đặt trước / Đặt ngay',
    'businessVehicle.cancel_all_booking': 'Một số chuyến đặt sẽ bị hủy nếu thay đổi trạng thái đặt trước của ca. \n Số lượng chuyến bị ảnh hưởng: {{field}} chuyến.',
    'businessVehicle.defaultLocation': 'Vị trí mặc định',
    'businessVehicle.latitude': 'Vĩ độ',
    'businessVehicle.longtitude': 'Kinh độ',
    'businessVehicle.locationOfDriver': '* Vị trí của tài xế (xe) trước ca làm việc',
    'businessVehicle.loadingText': 'Quá trình này có thể mất hơn 3 phút. Vui lòng đợi.',
    'businessVehicle.deleteVehicle': 'Một số chuyến sẽ bị hủy khi xóa xe này.\n Số chuyến bị ảnh hưởng: {{field}} chuyến',
    'businessVehicle.deleteVehicleConfirm': 'Bạn chắc chắc muốn xóa xe?',

    // Business Partner
    'businessPartner.searchTitle': 'Tìm kiếm điều kiện',
    'businessPartner.code': 'Mã',
    'businessPartner.description': 'Mô tả',
    'businessPartner.discount': 'Giảm giá',
    'businessPartner.discount_type': 'Loại giảm giá',
    'businessPartner.alertDelete': 'Bạn có muốn xóa Shop Subscription này?',
    'businessPartner.alertUpdate': 'Bạn có muốn cập nhật Subscription này?',
    'businessPartner.alertCreate': 'Bạn có muốn thêm Subscription này?',
    'businessPartner.alertChangeDiscount': 'Để áp dụng giảm giá cho điểm tham chiếu, hãy thay đổi giá giảm ở màn hình Thông tin điểm tham chiếu.',
    'businessPartner.discount.1': '%',
    'businessPartner.discount.2': 'SỐ TIỀN',
    'partner_payment_status.unpaid': 'CHƯA THANH TOÁN',
    'partner_payment_status.paid': 'ĐÃ THANH TOÁN',
    'partner_payment_status.need_refund': 'CẦN HOÀN TIỀN',
    'partner_payment_status.refuned': 'ĐÃ HOÀN TIỀN',
    'partner_payment_status.over': 'ĐÃ THANH TOÁN',
    'businessPartner.usage_status': 'Trạng thái sử  dụng',
    'businessPartner.qr_code': 'Mã QR',
    'businessPartner.number_of_free_rides': 'Miễn phí sử dụng',
    'businessPartner.shop_free': 'Cửa hàng miễn phí',
    'businessPartner.number_of_waypoint': 'Số lượng điểm tham chiếu',
    'businessPartner.waypoint': 'Điểm tham chiếu',
    'businessPartner.payment_transfer_status': 'Trạng thái thanh toán chuyển khoản',
    'businessPartner.transfer_deadline_day': 'Thời hạn chuyển khoản',
    'businessPartner.plan_code': 'Mã gói',
    'businessPartner.subscription_code': 'Mã đăng ký',
    'businessPartner.information': 'Thông tin',
    'businessPartner.business_time': 'Thời gian kinh doanh',
    'businessPartner.website': 'Trang chủ',

    'businessPartner.name': 'Tên đối tác',
    'businessPartner.campaignInfor': 'Hiển thị thông tin khuyến mãi',
    'businessPartner.waypointDisplay': 'Hiển thị giá tiền/phần trăm giảm giá',
    'businessPartner.commentDisplay': 'Hiển thị bình luận',
    'businessPartner.comment': 'Vui lòng nhập bình luận',
    'businessPartner.noteCampaignInfor': '*Nếu bạn muốn cài đặt hiển thị thông tin khuyến mãi , vui lòng nhập giá tiền/phần trăm giảm giá.',

    'businessPartner.restrict': 'Hạn chế',
    'businessPartner.restrict_description': 'Mô tả hạn chế',
    'businessPartner.restrict_photo': 'Ảnh hạn chế',
    'businessPartner.restrict_type': 'Loại hạn chế',
    'businessPartner.restrict_days': 'Ngày hạn chế',
    'businessPartner.repeat_setting': 'Cài đặt lặp lại ',
    'businessPartner.repeat_start_date': 'Ngày bắt đầu lặp lại',
    'businessPartner.repeat_end_date': 'Ngày kết thúc lặp lại',
    'businessPartner.repeat_weekly_on': 'Ngày lặp lại mỗi tuần',
    'businessPartner.repeat_monthly_on': 'Ngày lặp lại mỗi tháng',
    'businessPartner.exceptional_days': 'Ngày loại trừ',
    'businessPartner.restrict_time': 'Thời gian hạn chế',
    'businessPartner.required_restrict_description': 'Hãy nhập Mô tả hạn chế',
    'businessPartner.required_restrict_type': 'Hãy chọn Loại hạn chế',
    'businessPartner.required_restrict_days': 'Hãy chọn Ngày hạn chế',
    'businessPartner.required_exceptional_days': 'Hãy chọn Ngày loại trừ',
    'businessPartner.required_repeat_start_date': 'Hãy chọn Ngày bắt đầu lặp lại',
    'businessPartner.required_repeat_end_date': 'Hãy chọn Ngày kết thúc lặp lại',
    'businessPartner.required_repeat_weekly_on': 'Hãy chọn Ngày lặp lại mỗi tuần',
    'businessPartner.required_repeat_monthly_on': 'Hãy chọn Ngày lặp lại mỗi tháng',
    'businessPartner.duplicated_days': 'Trùng lặp ngày với Ngày cụ thể',
    'businessPartner.specific_days': 'Ngày cụ thể',
    'businessPartner.repeat': 'Lặp lại',
    'businessPartner.daily': 'Hàng ngày',
    'businessPartner.weekly': 'Hàng tuần',
    'businessPartner.monthly': 'Hàng tháng',
    'businessPartner.waypoint_restrict': 'Hạn chế điểm tham chiếu',
    'businessPartner.place_holder_waypoint_restrict': 'Hãy chọn Hạn chế điểm tham chiếu',
    'businessPartner.date_of_month': 'Ngày trong tháng',
    'businessPartner.delete': 'Xóa',
    'businessPartner.never': 'Không bao giờ',
    'businessPartner.on': 'Vào ngày',
    'businessPartner.restrict_setting': 'Cài đặt hạn chế',
    'businessPartner.waypoint_display_type': 'Loại hiển thị điểm tham chiếu',
    'businessPartner.partner_display': 'Hiển thị theo đối tác',
    'businessPartner.normal_display': 'Hiển thị thông thường',
    'businessPartner.error_repeat_start_date': 'Ngày bắt đầu lặp lại phải lớn hơn Ngày giờ bắt đầu',
    'businessPartner.error_repeat_end_date': 'Ngày kết thúc lặp lại phải nhỏ hơn Ngày giờ kết thúc',
    'businessPartner.error_discount': 'Hãy set giảm giá bằng 0 nếu chọn Hiển thị thông thường',
    'businessPartner.popup_error_discount': 'Để áp dụng hiển thị thông thường cho điểm tham chiếu, hãy thay đổi giá giảm bằng 0 ở màn hình Thông tin điểm tham chiếu',
    //
    'businessPartner.referrer_setting': 'Cài đặt đại lí',
    'businessPartner.referrer_id': 'ID đại lí',
    'businessPartner.referrer_name': 'Tên đại lí',
    'businessPartner.referrer_code': 'Mã giới thiệu',
    'businessPartner.plan_setting': 'Cài đặt vé tặng',
    'businessPartner.referrer_description': 'Mô tả mã giới thiệu',
    'businessPartner.total_associated_memmber_amount': 'Số lượng hội viên liên kết',
    'businessPartner.list_member': 'Danh sách hội viên',
    'businessPartner.list_associated_disassociated_members': 'Danh sách hội viên đã liên kết/ hủy liên kết',
    'businessPartner.status': 'Trạng thái liên kết',
    'businessPartner.active': 'Đã liên kết',
    'businessPartner.inActive': 'Đã hủy liên kết',
    'businessPartner.distribution_period': 'Thời gian phát vé',
    'businessPartner.question.confirm.create': 'Bạn có muốn tạo mã giới thiệu này không?',
    'businessPartner.eg': 'VD, A12345',
    'businessPartner.editAndEnable': 'Cập nhật & Khôi phục',
    'businessPartner.confirm': 'Xác nhận',
    // eslint-disable-next-line quotes
    'businessPartner.question.confirm.disabled': "Có {{count}} hội viên đang liên kết. Bạn có muốn vô hiệu hóa đại lí không?",
    'businessPartner.question.confirm.update': 'Bạn có muốn cập nhật mã giới thiệu này không?',
    'businessPartner.referrerNameRequired': 'Hãy nhập tên đại lí',
    'businessPartner.referrerCodeRequired': 'Hãy nhập mã giới thiệu',
    'businessPartner.referrerMailRequired': 'Hãy nhập Email',
    'businessPartner.referrerDescriptionPlaceHolder': 'Hãy nhập mô tả mã giới thiệu',
    'businessPartner.distributionPeriodRequired': 'Hãy chọn thời gian phát vé',
    'businessPartner.errorUseTime': 'Thời gian phát vé phải nằm trong khoảng thời gian sử dụng của gói',
    'businessPartner.checkFromTime': '(Từ) phải lớn hơn hoặc bằng thời gian hiện tại ',
    'businessPartner.checkToTime': '(Đến) phải lớn hơn hoặc bằng thời gian hiện tại',
    'businessPartner.planSettingYes': 'Có sử dụng',
    'businessPartner.planSettingNo': 'Không sử dụng',
    'businessPartner.mail_notification': 'Mail thông báo',
    'businessPartner.userAuthenCompleted': 'Hội viên liên kết thành công',
    'businessPartner.userAuthenCanceled': 'Hội viên hủy liên kết',
    'businessPartner.distribution_status': 'Trạng thái phát vé',
    'businessPartner.distribution_fail_reason': 'Lí do phát vé không thành công',
    'businessPartner.inactive_reason': 'Lí do hủy liên kết',
    'businessPartner.displayRecord': 'Hiển thị bản ghi',
    'businessPartner.waypointName': 'Tên waypoint',

    // Business
    'business.invalidUseTime': 'Thời gian sử dụng trùng lặp',
    'business.referralCodeFormat': 'Hãy nhập mã giới thiệu với ký tự đầu tiên là một chữ cái tiếng Anh in hoa, 5 ký tự còn lại là số.',
    'business.referralCodeFormatIncorrect': 'Mã giới thiệu không đúng theo quy tắc',
    'business.checkPlanCodeExist': 'Mã gói không chính xác',
    'business.checkReferrerCodeExist': 'Mã giới thiệu đã được sử dụng',
    'business.checkPlanCodeRequired': 'Hãy nhập mã gói',
    'business.memberCode': 'ID hội viên ',
    'business.activatedDateTime': 'Ngày giờ liên kết thành công',
    'business.inactivatedDateTime': 'Ngày giờ hủy liên kết',
    'business.planCode': 'Mã gói',
    // Member list distribution/active status reason
    'reason.distribution.system.error': 'Lỗi hệ thống',
    'reason.distribution.fail.date.not.in.plan': 'Thời gian liên kết không nằm trong khoảng thời gian phát vé',
    'reason.distribution.fail.already.has.ticket': 'Hội viên đã có vé này',
    'reason.distribution.fail.already.has.ticket.from.this.referrer': 'Hội viên đã được nhận vé từ đại lí này',
    'reason.distribution.fail.because.same.geofence.but.difference.discount.header': 'Hội viên này đã có ticket giảm giá cho người đi cùng với mức giảm giá khác cho cùng một geofence:',
    'reason.distribution.fail.because.same.geofence.but.difference.discount.content': '(Geofence {{geofenceName}}, ID mua: {{subCode}}, Người lớn: {{adultAmount}}, Trẻ em: {{childAmount}})',
    'reason.inactive.because.shop.deleted': 'Hủy liên kết do đại lí bị vô hiệu hóa',
    'reason.inactive.by.member': 'Hủy liên kết bởi hội viên',
    'reason.inactive.because.member.withdrew': 'Hủy liên kết do người dùng thoát hội viên',
    'reason.inactive.because.this.member.withdrew': 'Hủy liên kết do người dùng này đã thoát hội viên',
    'reason.inactive.because.that.member.withdrew': 'Hủy liên kết do người dùng {{memberCode}} thoát hội viên',
    'reason.reactivate.because.this.member.recover.account': 'Liên kết lại lúc {{datetime}} do user khôi phục tài khoản',

    // Waypoint
    'waypoint.id': 'ID',
    'waypoint.latitude': 'Vĩ độ',
    'waypoint.longitude': 'Kinh độ',
    'waypoint.description': 'Mô tả',
    'waypoint.product_photos': 'Ảnh sản phẩm',
    'waypoint.confirm_create': 'Bạn có muốn thêm điểm tham chiếu này không?',
    'waypoint.confirm_update': 'Bạn có muốn cập nhật điểm tham chiếu này không?',
    'waypoint.confirm_delete': 'Bạn có muốn xóa điểm tham chiếu này không?',
    'waypoint.confirm_update.reservations': 'Một số chuyến đặt sẽ bị hủy nếu thay đổi giờ hạn chế của điểm tham chiếu\r\nSố lượng chuyến bị ảnh hưởng: {{noReservations}} chuyến.',
    'waypoint.display_name': 'Tên hiển thị',
    'waypoint.street_name': 'Tên phố',
    'waypoint.not_found': 'Không tìm thấy điểm tham chiếu nào',
    'waypoint.transit_stop_swat_id': 'Id dừng chuyển tuyến Swat',
    'waypoint.select': 'Chọn điểm tham chiếu',
    'waypointMessage.updated': 'Đã cập nhật điểm tham chiếu thành công',
    'waypointMessage.created': 'Đã tạo mới điểm tham chiếu thành công',
    'waypoint.not.exists': 'Điểm tham chiếu không tồn tại',
    'waypoint.choose_lat_long': 'Vĩ độ phải là một số từ -90 đến 90, kinh độ phải là một số từ -180 đến 180',
    'waypoint.waypoint_restrict': 'Hạn chế điểm tham chiếu',
    'waypoint.restrict': 'Hạn chế',
    'waypoint.restrict.description': 'Mô tả hạn chế',
    'waypoint.restrict.photo': 'Ảnh hạn chế',
    'waypoint.restrict.type': 'Loại hạn chế',
    'waypoint.restrict.repeat': 'Lặp lại',
    'waypoint.restrict.specific_days': 'Ngày cụ thể',
    'waypoint.restrict.date_of_monthly': 'Ngày trong tháng',
    'waypoint.restrict.day': 'Ngày hạn chế',
    'waypoint.restrict.setting': 'Cài đặt hạn chế',
    'waypoint.restrict.on': 'Vào ngày',
    'waypoint.restrict.never': 'Không bao giờ',
    'waypoint.restrict.no_restrict': 'Không hạn chế',
    'waypoint.restrict.override_partner': 'Đè lên cài đặt của đối tác',
    'waypoint.restrict.take_over_partner': 'Sử dụng cài đặt của đổi tác',
    'waypoint.restrict.partner_display': 'Hiển thị theo đối tác',
    'waypoint.restrict.normal_display': 'Hiển thị thông thường',
    'waypoint.type.display': 'Hiển thị điểm tham chiếu ngoài thời gian hạn chế',
    'waypoint.restrict.normal_display_error': 'Hãy set giảm giá bằng 0 nếu chọn Hiển thị thông thường',

    // Business Store Management
    'business.facility_delivery.title': 'Quản lý cửa hàng',
    'business.facility_delivery.store_id': 'ID cửa hàng',
    'business.facility_delivery.store_name': 'Tên cửa hàng',
    'business.facility_delivery.products_section_title': 'Sản phẩm',
    'business.facility_delivery.update_confirm_message': 'Bạn có muốn cập nhật cửa hàng này không?',
    'business.facility_delivery.product_id': 'ID sản phẩm',
    'business.facility_delivery.product_name': 'Tên sản phẩm',
    'business.facility_delivery.supplier_facility_name': 'Tên cửa hàng',
    'business.facility_delivery.supplier_id': 'ID nhà cung cấp',
    'business.facility_delivery.sale_status': 'Trạng thái bán',

    // Bank
    'bankManagement.code': 'ID ngân hàng',
    'bankManagement.bankName': 'Ngân hàng',
    'bankManagement.branchName': 'Chi nhánh',
    'bankManagement.branchCode': 'Mã chi nhánh',
    'bankManagement.accountType': 'Loại tài khoản',
    'bankManagement.accountName': 'Tên tài khoản',
    'bankManagement.accountNumber': 'Số tài khoản',
    'bankManagement.transferContent': 'Nội dung chuyển khoản',
    'bankManagement.confirm_create': 'Bạn có muốn thêm thông tin ngân hàng này không?',
    'bankManagement.confirm_update': 'Bạn có muốn cập nhật thông tin ngân hàng này không?',

    // Service Group
    'serviceGroup.project_id': 'ID dự án',
    'serviceGroup.dataset_id': 'ID tập dữ liệu',
    'serviceGroup.routing_profile_id': 'ID hồ sơ định tuyến',
    'serviceGroup.walking_profile_id': 'ID hồ sơ đi bộ',
    'serviceGroup.transit_stop_set_id': 'ID đặt điểm dừng chuyển tuyến',
    'serviceGroup.route_network': 'Mạng lưới tuyến đường',
    'serviceGroup.description': 'Mô tả',
    'serviceGroup.confirm_create': 'Bạn có muốn thêm thông tin nhóm dịch vụ này không?',
    'serviceGroup.confirm_update': 'Bạn có muốn cập nhật thông tin nhóm dịch vụ này không?',
    'serviceGroup.confirm_delete': 'Bạn có muốn xóa thông tin nhóm dịch vụ này không?',

    // JIT setting
    'jit.overlap': 'Thời gian trùng lặp.',
    'jit.setPast': 'Hãy chọn ngày trong tương lai.',
    'jit.setting': 'Màn hình cài đặt JIT',
    'jit.collect_setting': 'Cài đặt đồng loạt',
    'jit.brandOrVehicleName': 'Tên hãng/Tên xe',
    'jit.saleOfficeName': 'Tên văn phòng kinh doanh',
    'jit.vehicleNo': 'Số xe',
    'jit.vehicleColor': 'Màu xe',
    'jit.mobiType': 'Loại mobi',
    'jit.serviceArea': 'Khu vực vận hành JIT',
    'jit.virtualBusStop': 'Tên điểm đỗ xe buýt đầu',
    'jit.virtualBusStopHtw': 'Tên điểm đỗ xe buýt đầu HTW',
    'jit.virtualBusStopWth': 'Tên điểm đỗ xe buýt đầu WTH',
    'jit.specifyPeriod': 'Chỉ định thời gian',
    'jit.fromWhen': 'Từ',
    'jit.untilWhen': 'Đến',
    'jit.closeTimeDeparture': 'Thời gian chốt chuyến (Giờ xuất phát cố định)',
    'jit.closeTimeArrival': 'Thời gian chốt chuyến (Giờ đến cố định)',
    'jit.scheduledDeparture': 'Xuất phát theo lịch',
    'jit.firstTrain': 'Chuyến đầu',
    'jit.lastTrain': 'Chuyến cuối',
    'jit.timeInterval': 'Khoảng thời gian',
    'jit.bufferTime': 'Thời gian đệm',
    'jit.calendar_detail_setting': 'Lịch vận hành',
    'jit.arrivalOnTime': 'Giờ đến cố định',
    'jit.tableDeparture': 'Xuất phát (Sớm nhất) / Xuất phát (Muộn nhất)',
    'jit.tableInterval': 'Khoảng thời gian',
    'jit.tableArrival': 'Đến (Chuyến đầu) / Đến (Chuyến cuối)',
    'jit.tableImplement': 'Bảng triển khai',
    'jit.tableMinute': 'Phút',
    'jit.dayAgo': 'ngày trước',
    'jit.untilTime': 'đến',
    'jit.hourAgo': 'giờ trước',
    'jit.minuteAgo': 'phút trước',
    'jit.timeMustBe': 'Thời gian phải trong khoảng từ {{time_start}} đến {{time_end}}',
    'jit.htwBreakTime': 'Đang nghỉ (Đến giờ cố định)',
    'jit.wthBreakTime': 'Đang nghỉ (Đi giờ cố định)',
    'jit.stopped_flights': 'Chuyến đã dừng',
    'jit.updateSettingMsg': 'Bạn có muốn cập nhật bảng giờ JIT không?',
    'jit.updateSettingMsgHavingShift': 'Bạn có muốn cập nhật không?\r\n      Dữ liệu đã có sẵn của tài xế và xe sẽ bị mất nếu cập nhật.',
    'jit.only1month': 'Chỉ cài đặt cho khoảng thời gian 1 tháng',
    'jit.title': 'Quản lý JIT Order',
    'jit.status.draft': 'Bản nháp',
    'jit.status.sent': 'Đã gửi',
    'jit.csv.import': 'Nhập CSV',
    'jit.csv.export': 'Xuất CSV',
    'jit.title.update': 'Cập nhật Jit Order',
    'jit.title.create': 'Tạo mới Jit Order',
    'jit.confirm.create': 'Bạn có muốn tạo mới Jit Order không ?',
    'jit.confirm.update': 'Bạn có muốn cập nhật Jit Order không ?',
    'jit.confirm.detete': 'Bạn có muốn xóa Jit Order không ?',
    'jit.confirm.send': 'Bạn có muốn gửi Jit Order không ?',
    'jit.confirm.clone': 'Bạn có muốn clone Jit Order không ?',
    'jit.masterData': 'Dữ liệu tổng thể JIT',
    'jit.date': 'Ngày',
    'jit.time': 'Thời gian',
    'jit.time.htw': 'Thời gian HTW',
    'jit.time.wth': 'Thời gian WTH',
    'jit.template.csv': 'Bản mẫu CSV',
    'jit.waypoint.name': 'Tên điểm dừng',
    'jit.waypoint.code': 'Mã điểm dừng',
    'jit.country.code': 'Mã quốc gia',
    'jit.geofence.code': 'Mã khu vực',
    'jit.pickup_address.code': 'Mã địa chỉ đón khách',
    'jit.dropoff_address.code': 'Mã địa chỉ trả khách',
    'jit.dateTime': 'Ngày và giờ',
    'jit.code': 'Mã',
    'jit.order_id': 'Mã đặt chỗ JIT',
    'jit.order_number': 'Số đặt chỗ JIT',
    'jit.transit_stop_points': 'Điểm dừng chuyển tuyến',
    'jit.list_pickup_point': 'Danh sách điểm lên',
    'jit.list_dropoff_point': 'Danh sách điểm xuống',
    'jit.setting_route': 'Thiết lập tuyến đường',
    'jit.route_name': 'Tên tuyến đường',
    'jit.confirm_create_route': 'Bạn có muốn tạo tuyến đường JIT này không?',
    'jit.confirm_update_route': 'Bạn có muốn cập nhật tuyến đường JIT này không?',
    'jit.confirm_delete_route': 'Bạn có muốn xóa tuyến đường JIT này không?',
    'jit.no_direction': 'Hãy nhấn nút để tạo tuyến đường',
    'jit.20_different': 'SWAT và GOOGLE khác biệt 20%, bạn có chắc muốn tiếp tục？',
    'jit.geofence_no_mode': 'Khu vực này chưa có chế độ',
    'jit.pickup': 'Nơi lên xe',
    'jit.dropoff': 'Nơi xuống xe',
    'jit.duration': 'Khoảng thời gian',
    'jit.generate_direction': 'Tạo đường đi',
    'jit.distance': 'Khoảng cách',
    'jit.pickup_location_name': 'Nơi lên xe',
    'jit.dropoff_location_name': 'Nơi xuống xe',
    'jit.route_id': 'ID tuyến đường',
    'jit.no_service_notify': 'Dịch vụ đang không khả dụng, không có waypoint nào phù hợp!',
    'jit.route_duration': 'Thời gian tuyến đường',
    'jit.time_minimum': 'Giá trị nhỏ nhất phải lớn hơn hoặc bằng {{field}}',
    'jit.missing_point': 'Một số điểm tham chiếu bị thiếu do thay đổi hoặc xóa dữ liệu',
    'jit.onlyForOldApp': 'Cài đặt hiển thị tuyến đường',
    'jit.routeType': 'Loại tuyến',
    'jit.singleWork': 'JIT 1 to N',
    'jit.multiWork': 'JIT N to N',
    // eslint-disable-next-line max-len
    'jit.noteSetting': 'Để trống hộp cài đặt trừ các trường hợp sau:\r\n(Trường hợp cần đánh dấu)\r\nNếu bạn muốn thiết lập một tuyến đường N-N, để trống hộp kiểm này và thực hiện tạo nhiều tuyến đường 1-N sau.\n Vui lòng đánh dấu vào hộp kiểm khi tạo các tuyến đường 1-N này.',
    'jit.estimate_time': 'Thời gian ước tính',
    'jit.estimate_time_title': 'Thời gian ước tính (phút)',
    'jit.estimate_option': 'Phương án ước tính',
    'jit.estimate_option.swat': 'Ước tính theo SWAT',
    'jit.estimate_option.manual': 'Ước tính thủ công',
    'jit.estimate_time.duration': 'Khoảng thời gian ước tính thủ công',
    'jit.estimate_time.min.workToHome': 'Hãy nhập thời gian ước tính của chặng nối giữa điểm Home và điểm Work lớn hơn 0',

    // Business Image
    'businessImage.formData': 'Biểu mẫu quản lý ảnh',
    'businessImage.titleUpload': 'Chọn ảnh',
    'businessImage.titleCrop': 'Tải ảnh lên',
    'businessImage.id': 'ID ảnh',
    'businessImage.type': 'Loại',
    'businessImage.image': 'Ảnh',
    'businessImage.type.store_appearance': 'Cửa hàng (Bên ngoài)',
    'businessImage.type.store_inside_view': 'Cửa hàng (Bên trong)',
    'businessImage.type.person': 'MM',
    'businessImage.type.product': 'Sản phẩm',
    'businessImage.type.vehicle_appearance': 'Xe (Bên ngoài)',
    'businessImage.type.vehicle_inside_view': 'Xe (Bên trong)',
    'businessImage.type.logo_shop': 'LOGO CỬA HÀNG',
    'businessImage.type.avatar_shop': 'ẢNH ĐẠI DIỆN CỬA HÀNG',
    'businessImage.crop.button': 'Cắt',
    'businessImage.crop.upload': 'Tải lên',
    'businessImage.crop.preview': 'Xem trước',

    // Sales Office/Stores page
    'facility.title': 'Thông tin văn phòng kinh doanh - cửa hàng',
    'facility.title.detail': 'Form đăng ký thông tin văn phòng kinh doanh - cửa hàng',
    'facility.searchTitle': 'Điều kiện tìm kiếm',
    'facility.id': 'ID văn phòng kinh doanh/cửa hàng',
    'facility.status': 'Trạng thái hợp đồng',
    'facility.type': 'Loại dịch vụ',
    'facility.industry': 'Ngành',
    'facility.industry.transportation': 'Phương tiện giao thông',
    'facility.industry.shop': 'Cửa hàng đồ lưu niệm',
    'facility.industry.supermarket': 'Siêu thị',
    'facility.code': 'Code văn phòng kinh doanh/cửa hàng',
    'facility.name': 'Tên văn phòng kinh doanh/cửa hàng',
    'facility.business.code': 'ID nhà cung cấp',
    'facility.business.name': 'Tên nhà cung cấp',
    'facility.person_charge': 'Người phụ trách',
    'facility.home_page': 'Trang chủ',
    'facility.mobile_operator': 'Số điện thoại của người vận hành',
    'facility.email': 'Địa chỉ email ①',
    'facility.email_2': 'Địa chỉ email ②',
    'facility.email_3': 'Địa chỉ email ③',
    'facility.language': 'Ngôn ngữ',
    'facility.holiday': 'Ngày nghỉ',
    'facility.business_hours_1': 'Giờ làm việc ①',
    'facility.business_hours_2': 'Giờ làm việc ②',
    'facility.take_over_operator': 'Tiếp quản thông tin nhà cung cấp',
    'facility.fee': 'Tiền phí',
    'facility.other': 'Khác',
    'facility.rate': 'Tỉ lệ',
    'facility.question.confirm.delete': 'Bạn có muốn xóa văn phòng kinh doanh/cửa hàng đã chọn không?',
    'facility.question.confirm.update': 'Bạn có muốn thay đổi thông tin văn phòng kinh doanh/cửa hàng không?',
    'facility.question.confirm.create': 'Bạn có muốn đăng ký văn phòng kinh doanh/cửa hàng?',
    'facility.select_driver_name': 'Chọn tên tài xế (Trên ứng dụng tài xế)',
    'facility.countDownTime': ' Thời gian đếm ngược (giây)',
    'facility.countDownTime2': 'Thời gian đếm ngược',

    // Driver Registration page
    'driverStatus.currentlyEnrolled': 'HIÊN ĐÃ ĐĂNG KÝ',
    'driverStatus.retired': 'Đã nghỉ',
    'driverStatus.closed': 'Đang đóng cửa',
    'driverType.delivery': 'Đi chợ',
    'driverType.move': 'NGƯỜI DI CHUYỂN',
    'driver.partialMatch': 'Khớp một phần',
    'driver.title': 'Thông tin tài xế',
    'driver.create.title': 'Tạo tài xế',
    'driver.detail.title': 'Chi tiết tài xế',
    'driver.title.detail': 'Form tài xế',
    'driver.searchTitle': 'Điều kiện tìm kiếm',
    'driver.code': 'Mã tài xế',
    'driver.id': 'ID tài xế',
    'driver.name_search': 'Tên (App và AD)',
    'driver.name': 'Tên tài xế (Dùng cho nhà cung cấp)',
    'driver.status': 'Tình trạng',
    'driver.language': 'Ngôn ngữ hiển thị cho APP',
    'driver.lang': 'Ngôn ngữ',
    'driver.type': 'Loại',
    'driver.facility.code': 'ID văn phòng kinh doanh/cửa hàng',
    'driver.facility.name': 'Tên văn phòng kinh doanh/cửa hàng',
    'driver.supplier.code': 'ID nhà cung cấp',
    'driver.supplier.name': 'Tên nhà cung cấp',
    'driver.gender': 'Giới tính',
    'driver.male': 'Nam',
    'driver.female': 'Nữ',
    'driver.name_app': 'Tên tài xế (Hiển thị ứng dụng)',
    'driver.vehicle_binding': 'Liên kết xe',
    'driver.reference': 'Tham khảo',
    'driver.defaultVehicle': 'Xe mặc định',

    // Product delivery Page
    'delivery.settingProductListTime': 'Cài đặt thời gian danh sách sản phẩm',
    'delivery.listTimeSettingProduct': 'Kiểm tra cài đặt',
    'delivery.title': 'Quản lý sản phẩm',
    'delivery.caution_title': 'Chọn lưu ý',
    'delivery.sort_column': 'Thứ tự hiển thị',
    'delivery.product_id': 'ID sản phẩm',
    'delivery.product_name': 'Tên sản phẩm',
    'delivery.sale_status': 'Tình trạng bán',
    'delivery.language': 'Ngôn ngữ',
    'delivery.sales_store': 'Tên văn phòng kinh doanh/cửa hàng',
    'delivery.business_person': 'Tên nhà cung cấp',
    'delivery.arrangement_method': 'Cách sắp xếp',
    'delivery.sales_contact': 'Văn phòng kinh doanh - địa chỉ liên lạc',
    'delivery.sale_period': 'Thời gian bán',
    'delivery.sale_period_start': 'Thời gian bán sản phẩm (Từ)',
    'delivery.sale_period_end': 'Thời gian bán sản phẩm (Đến)',
    'delivery.display_period': 'Thời gian hiển thị',
    'delivery.display_period_start': 'Thời gian hiển thị (Từ)',
    'delivery.display_period_end': 'Thời gian hiển thị (Đến)',
    'delivery.operating_time': 'Thời gian hoạt động',
    'delivery.operating_time_start': 'Thời gian hoạt động (Từ)',
    'delivery.operating_time_end': 'Thời gian hoạt động (Đến)',
    'delivery.service_type': 'Loại service',
    'delivery.product_type': 'Phân loại sản phẩm đi chợ/Vừa',
    'delivery.product_type_small': 'Phân loại sản phẩm đi chợ/Nhỏ',
    'delivery.basic_inform': 'Thông tin cơ bản',
    'delivery.description': 'Mô tả sản phẩm',
    'delivery.product_photo_data': 'Dữ liệu ảnh sản phẩm',
    'delivery.reservation_date': 'Ngày đặt',
    'delivery.cancellation_fee': 'Phí chấm dứt hợp đồng',
    'delivery.information': 'Thông tin',
    'delivery.precautions': 'Chú ý',
    'delivery.changer': 'Người thay đổi',
    'delivery.update_date': 'Ngày cập nhật',
    'delivery.created_date': 'Ngày tạo',
    'delivery.author': 'Người tạo',
    'delivery.content': 'Nội dung',
    'delivery.title_msg_label': 'Khớp một phần',
    'delivery.whether_sales_date_is_specified': 'Ngày bán có được chỉ định không',
    'delivery.designated_sales_date_calendar': 'Lịch ngày bán được chỉ định',
    'deliveryStatus.sale': 'Đang bán',
    'deliveryStatus.in_preparation': 'Đang chuẩn bị',
    'deliveryStatus.end_of_sale': 'Kết thúc bán',
    'deliveryStatus.stopped': 'Đang dừng',
    'deliveryServiceType.on_demand': 'Mobi',
    'deliveryServiceType.delivery': 'Đi chợ',
    'deliveryServiceType.taxi': 'Taxi',
    'deliveryServiceType.qr': 'Mã QR',
    'deliveryArrangement.money': 'Tiền mặt',
    'deliveryArrangement.WillerPay': 'WillerPay',
    'delivery.specified': 'Ngày bán có được chỉ định không',
    'delivery.storeCode': 'ID văn phòng kinh doanh - cửa hàng',
    'delivery.storeName': 'Tên văn phòng kinh doanh - cửa hàng',

    // Product vehicle Page
    'vehicles.title': 'Quản lý sản phẩm xe',
    'vehicles.sort_column': 'Thứ tự hiển thị',
    'vehicles.product_id': 'ID sản phẩm',
    'vehicles.product_name': 'Tên sản phẩm',
    'vehicles.sales_status': 'Tình trạng bán',
    'vehicles.language': 'Ngôn ngữ',
    'vehicles.sales_store_contact': 'Văn phòng kinh doanh - Địa chỉ liên lạc',
    'vehicles.sales_store': 'Văn phòng kinh doanh - cửa hàng',
    'vehicles.sales_period': 'Thời gian bán',
    'vehicles.display_period': 'Thời gian hiển thị',
    'vehicles.from': '(Từ)',
    'vehicles.to': '(Đến)',
    'vehicles.operating_time': 'Thời gian hoạt động',
    'vehicles.product_type': 'Loại sản phẩm',
    'vehicles.description': 'Mô tả',
    'vehicles.product_photo_data': 'Dữ liệu ảnh sản phẩm',
    'vehicles.reservation_start_date': 'Ngày đặt',
    'vehicles.reservation_date': 'Ngày đặt',
    'vehicles.cancellation_fee': 'Phí chấm dứt hợp đồng',
    'vehicles.information': 'Thông tin',
    'vehicles.precautions': 'Chú ý',
    'vehicles.author': 'Người tạo',
    'vehicles.changer': 'Người thay đổi',
    'vehicles.update_date': 'Ngày cập nhật',
    'vehicles.created_date': 'Ngày tạo',
    'vehicles.content': 'Nội dung',
    'vehicles.title_msg_label': 'Khớp một phần',
    'vehicles.basic_inform': 'Thông tin cơ bản',

    // Product Cancellation fee
    'cancellation_fee.title': 'Quản lý phí hủy',
    'cancellation_fee.titleSearch': 'Điều kiện tìm kiếm',
    'cancellation_fee.basic_info': 'Thông tin cơ bản',
    'cancellation_fee.id': 'ID phí hủy',
    'cancellation_fee.name': 'Tên phí hủy',
    'cancellation_fee.period': 'Thời hạn',
    'cancellation_fee.rate': 'Tỉ lệ',
    'cancellation_fee.number': 'Số tiền',
    'cancellation_fee.grown_up': 'Người lớn',
    'cancellation_fee.children': 'Trẻ em',
    'cancellation_fee.update': 'Cập nhật phí hủy',
    'cancellation_fee.create': 'Tạo phí hủy',
    'cancellation_fee.rateType.yen': 'Yên',
    'cancellation_fee.rateType.percentage': '%',
    'cancellation_fee.fee_code': 'Code phí hủy',
    'cancellation_fee.information': 'Thông tin phí hủy',
    'cancellation_fee.price': 'Giá',
    'cancellation_fee.from': 'Thời gian hủy (Từ)',
    'cancellation_fee.to': 'Thời gian hủy (Đến)',
    'cancellation_fee.periodType.day': 'Ngày',
    'cancellation_fee.periodType.hour': 'Tiếng',
    'cancellation_fee.periodType.indefinite': 'Không giới hạn',
    'cancellation_fee.periodType.reservationDay': 'Ngày đặt',
    'cancellation_fee.periodType.afterReservationDay': 'Ngày sau ngày đặt',
    'cancellation_fee.facility.id': 'ID văn phòng kinh doanh - cửa hàng',
    'cancellation_fee.facility.name': 'Tên văn phòng kinh doanh - cửa hàng',
    'cancellation_fee.add': 'Thêm',
    'cancellation_fee.delete': 'Xóa',
    'cancellation_fee.detailTitle': 'Cài đặt chi tiết phí hủy',

    // Product Information fee
    'caution.id': 'ID mục chú ý',
    'caution.name': 'Tên mục chú ý',
    'caution.display_time': 'Thời gian hiển thị (Từ ~ đến) (UTC)',
    'caution.from_time': 'Thời gian hiển thị (Từ)',
    'caution.to_time': 'Thời gian hiển thị (Đến)',
    'caution.content': 'Nội dung',
    'caution.update': 'Chi tiết mục chú ý',
    'caution.create': 'Đăng ký mục chú ý',
    'caution.item': 'Sản phẩm',
    'caution.searchTitle': 'Điều kiện tìm kiếm',

    // Business Driver&Vehicle Association
    'driverAssociation.id': 'ID tài xế',
    'driverAssociation.name': 'Tên tài xế',
    'driverAssociation.vehicle': 'Số xe',
    'driverAssociation.name.app': 'Tên tài xế (App)',
    'driverAssociation.name.ad': 'Tên tài xế (AD)',
    'driverAssociation.online': 'Trực tuyến',
    'driverAssociation.offline': 'Ngoại tuyến',
    'driverAssociation.activated_times': 'Thời gian sử dụng',
    'driverAssociation.last_device_info': 'Thông tin thiết bị',
    'driverAssociation.os_version': 'OS version',
    'driverAssociation.screen_size': 'Màn hình',
    'driverAssociation.delete_shift': 'Xóa ca',
    'driverAssociation.onDemandRealTime': 'Xe theo yêu cầu đặt ngay',
    'driverAssociation.onDemandAdvance': 'Xe theo yêu cầu đặt trước / đặt ngay',

    // Product price calendar
    'calendar.month': 'Tháng',
    'calendar.years': 'Năm',
    'calendar.Monday': 'Thứ 2',
    'calendar.Tuesday': 'Thứ 3',
    'calendar.Wednesday': 'Thứ 4',
    'calendar.Thursday': 'Thứ 5',
    'calendar.Friday': 'Thứ 6',
    'calendar.Saturday': 'Thứ 7',
    'calendar.Sunday': 'Chủ nhật',
    'calendar.Holiday': 'Ngày nghỉ',
    'calendar.csv_output': 'Xuất CSV',
    'calendar.csv_input': 'Nhập CSV',
    'calendar.csv_upload': 'Tải lên CSV',
    'calendar.csv_title': '※Có thể tải tệp CSV cho 1 năm',
    'calendar.price': 'Giá',
    'calendar.tax': 'Thuế',
    'calendar.en': 'Yên',
    'calendar.checkAll': 'Chọn tất cả',
    'calendar.batch_register_all': 'Đăng ký đồng loạt tiền phí',
    'calendar.batch_register_all_tax': 'Tiền thuế',
    'calendar.time_from': 'Giờ/Từ Cài đặt đồng loạt',
    'calendar.time_to': 'Giờ/Đến Cài đặt đồng loạt',
    'calendar.all_day': 'Tất cả các ngày trong tuần',
    'calendar.jan': 'Tháng Một',
    'calendar.feb': 'Tháng Hai',
    'calendar.march': 'Tháng Ba',
    'calendar.apr': 'Tháng Tư',
    'calendar.may': 'Tháng Năm',
    'calendar.june': 'Tháng Sáu',
    'calendar.july': 'Tháng Bảy',
    'calendar.aug': 'Tháng Tám',
    'calendar.sep': 'Tháng Chín',
    'calendar.oct': 'Tháng Mười',
    'calendar.nov': 'Tháng Mười Một',
    'calendar.dec': 'Tháng Mười Hai',

    // Setting price taxi form
    'settingFeeTaxiForm.titleRegister': 'Đăng kí phí taxi',
    'settingFeeTaxiForm.titleDetails': 'Chi tiết phí taxi',
    'settingFeeTaxiForm.form': 'Biểu mẫu đăng ký phí taxi',
    'settingFeeTaxiForm.businessName': 'Nhà cung cấp',
    'settingFeeTaxiForm.facilityName': 'Tên văn phòng kinh doanh - cửa hàng',
    'settingFeeTaxiForm.vehicleType': 'Phân loại xe',
    'settingFeeTaxiForm.general': 'Thông thường',
    'settingFeeTaxiForm.nightShift': 'Ca đêm',
    'settingFeeTaxiForm.startingFee': 'Phí bắt đầu',
    'settingFeeTaxiForm.continuingFee': 'Phí tiếp tục',
    'settingFeeTaxiForm.minute': 'phút/',
    'settingFeeTaxiForm.meter': 'mét/',
    'settingFeeTaxiForm.yen': 'Yên',
    'settingFeeTaxiForm.openingFee': 'Phí mở cửa',
    'settingFeeTaxiForm.distanceUnit': 'Đơn vị độ dài',
    'settingFeeTaxiForm.fee': 'Phí',
    'settingFeeTaxiForm.shiftTime': 'Giờ làm việc',
    'settingFeeTaxiForm.period': 'Giai đoạn',

    // One time price
    'oneTimePrice.informationTitle': 'Thông tin giá một lần',
    'oneTimePrice.detailsTitle': 'Chi tiết giá một lần',
    'oneTimePrice.registrationTitle': 'Đăng ký giá một lần',
    'oneTimePrice.form': 'Biểu mẫu giá một lần',
    'oneTimePrice.no': 'Số.',
    'oneTimePrice.geofence': 'Khu vực',
    'oneTimePrice.vehicle_group_type': 'Loại nhóm xe',
    'oneTimePrice.price': 'Giá',
    'oneTimePrice.total': 'Tổng',
    'oneTimePrice.taxRate': 'Thuế',
    'oneTimePrice.currency': 'Đơn vị tiền tệ',
    'oneTimePrice.paymentMethods': 'Phương thức thanh toán',
    'oneTimePrice.confirmRegister': 'Bạn có muốn đăng ký one time price không?',
    'oneTimePrice.confirmUpdate': 'Bạn có muốn cập nhật one time price không?',
    'oneTimePrice.adult_price': 'Giá người lớn',
    'oneTimePrice.child_price': 'Giá trẻ em',
    'oneTimePrice.one_time_discount_error': 'Geofence này đã được setting giảm giá cho người đi cùng. Hãy setting giá tiền sau khi giảm giá cho người đi cùng ít hơn giá tiền đi một lần trước khi cập nhật.',
    'oneTimePrice.plan_with_discount': '(Mã gói: {{Id}}, Người lớn: {{Adult}}, Trẻ em: {{Child}})',
    'oneTimePrice.operating_hour_geofence': 'Thời gian hoạt động của khu vực',
    'oneTimePrice.start_time': 'Thời gian bắt đầu',
    'oneTimePrice.end_time': 'Thời gian kết thúc',
    'oneTimePrice.time_range': 'Khoảng thời gian',
    'oneTimePrice.overlap_time_range': 'Khoảng thời gian lặp lại',
    'oneTimePrice.adult_charge': 'Phụ phí người lớn',
    'oneTimePrice.child_charge': 'Phụ phí trẻ em',
    'oneTimePrice.require_charge': 'Hãy nhập giá cho ít nhất 1 đối tượng (Người lớn/Trẻ em)',
    'oneTimePrice.clear_all_current_setting': 'Bạn chắc chắn muốn xóa tất cả các thiếp lập?',
    'oneTimePrice.surcharge_setting': 'Thiết lập phụ phí',
    'oneTimePrice.additional_fee': 'Phí đặt trước',
    'oneTimePrice.time_range_surcharge_discount_setting': 'Thiết lập phụ phí theo giờ - Giảm giá theo giờ',
    'oneTimePrice.setting_time_range_discount': 'Thiếp lập giảm giá theo giờ bằng cách thêm dấu (-) trước giá trị số',
    'oneTimePrice.all_time_ranges_discount_smaller_one_time_discounts':
      'Giá trị Thanh toán một lần sau khi áp dụng giảm giá theo giờ phải nhỏ hơn giá tiền sau giảm cho người đi cùng của tất cả vé đi xe trong khu vực',
    'oneTimePrice.time_range_discount_smaller_one_time_discount': 'Giá trị giảm giá theo giờ cần nhỏ hơn giá trị Thanh toán một lần',
    'oneTimePrice.time_range_discount_smaller_specical_fee': 'Giá trị giảm giá theo giờ cần nhỏ hơn giá trị giá tiền cho đối tượng đặc biệt',
    'oneTimePrice.description_detail_price': 'Mô tả (Chi tiết giá tiền)',
    'oneTimePrice.description_onetime_banner': 'Mô tả (Thông báo giá một lần)',
    'oneTimePrice.description_subscription_banner': 'Mô tả (Thông báo gói đăng ký người dùng)',
    'oneTimePrice.description_ticket_banner': 'Mô tả (Thông báo vé đi xe)',
    'oneTimePrice.time_range_surcharge_discount': 'Phụ phí/Giảm giá theo giờ',
    'oneTimePrice.validatePaymentMethods': 'Phương thức thanh toán cần bao gồm Tiền mặt hoặc Thẻ tín dụng',
    'oneTimePrice.special_passengers_fee_setting': 'Thiết lập giá tiền cho đối tượng đặc biệt',
    'oneTimePrice.name': 'Tên',
    'oneTimePrice.popup': 'Thông báo',
    'oneTimePrice.popup_title': 'Tiêu đề thông báo',
    'oneTimePrice.popup_content': 'Nội dung thông báo',
    'oneTimePrice.setting': 'Thiết lập',
    'oneTimePrice.type': 'Loại',
    'oneTimePrice.surcharge_discount_adult': 'Phụ phí, giảm giá như người lớn',
    'oneTimePrice.surcharge_discount_child': 'Phụ phí, giảm giá như trẻ em',
    'oneTimePrice.no_surcharge_discount': 'Không áp dụng phụ phí, giảm giá',
    'oneTimePrice.display': 'Cờ hiển thị',
    'oneTimePrice.description': 'Mô tả',
    'oneTimePrice.on_user_app': 'Trên ứng dụng người dùng',
    'oneTimePrice.charge': 'Giá tiền',

    // congestion
    'congestion': 'Đông đúc',
    'congestion.detail': 'Cài đặt chi tiết',
    'congestion.setting': 'Cài đặt đồng loạt',
    'congestion.setting.1': 'Tất cả các ngày',
    'congestion.setting.2': 'Theo thứ',
    'congestion.setting.3': 'Theo khung giờ',
    'congestion.setting.4': 'Cài đặt chi tiết',
    'congestion.1v': 'Không đông',
    'congestion.2v': 'Không đông',
    'congestion.3v': 'Hơi đông',
    'congestion.4v': 'Đông đúc',
    'congestion.5v': 'Rất đông',
    'congestion.1': '1',
    'congestion.2': '2',
    'congestion.3': '3',
    'congestion.4': '4',
    'congestion.5': '5',
    'congestion.status': 'Status đăng ký tình trạng đông khách',
    'congestion.status.1': 'Chưa đăng ký',
    'congestion.status.2': 'Đã đăng ký',

    // reservationManagement
    'reservationManagement.user_sub': 'Người dùng gói đăng ký',
    'reservationManagement.adult': 'Người lớn',
    'reservationManagement.child': 'Trẻ em',
    'reservationManagement.infant': 'Trẻ sơ sinh',
    'reservationManagement.adult_one_time_discount': 'Giảm giá cho người lớn',
    'reservationManagement.child_one_time_discount': 'Giảm giá cho trẻ em',
    'reservationManagement.pickup_address_search': 'Địa chỉ đón khách',
    'reservationManagement.dropoff_address_search': 'Địa chỉ trả khách',
    'reservationManagement.pickup_date': 'Thời gian đi',
    'reservationManagement.pickup_date_from': 'Ngày đón khách dự kiến (Từ)',
    'reservationManagement.pickup_date_to': 'Ngày đón khách dự kiến (Đến)',
    'reservationManagement.dropoff_date': 'Thời gian đến',
    'reservationManagement.pickup_date_scheduled': 'Thời gian đón dự tính',
    'reservationManagement.dropoff_date_scheduled': 'Thời gian trả dự tính',
    'reservationManagement.booking_flight_pickup_time': 'Thời gian đón',
    'reservationManagement.booking_flight_dropoff_time': 'Thời gian trả',
    'reservationManagement.dropoff_date_from': 'Ngày trả khách dự kiến (Từ)',
    'reservationManagement.dropoff_date_to': 'Ngày trả khách dự kiến (Đến)',
    'reservationManagement.sale_office': 'Tên văn phòng kinh doanh - cửa hàng',
    'reservationManagement.detail.1': 'Địa chỉ và số điện thoại nơi lấy hàng',
    'reservationManagement.detail.2': 'Đại chỉ và số điện thoại nơi giao hàng',
    'reservationManagement.product_type': 'Loại hàng hóa',
    'reservationManagement.service_type': 'Loại dịch vụ',
    'reservationManagement.business_name': 'Tên nhà cung cấp',
    'reservationManagement.facility_name': 'Văn phòng bán hàng - Cửa hàng',
    'reservationManagement.company_name': 'Tên văn phòng kinh doanh/cửa hàng',
    'reservationManagement.reservation_date_from': 'Ngày đặt chỗ (Ngày bắt đầu)',
    'reservationManagement.reservation_date_to': 'Ngày đặt chỗ (Ngày kết thúc)',
    'reservationManagement.usage_date_from': 'Ngày sử dụng (Ngày bắt đầu)',
    'reservationManagement.usage_date_to': 'Ngày sử dụng (Ngày kết thúc)',
    'reservationManagement.booking_status': 'Trạng thái đặt chỗ',
    'reservationManagement.deposit_status': 'Trạng thái thanh toán',
    'reservationManagement.notification_complete': 'Thông báo đến user',
    'reservationManagement.message_fail_change_payment': 'Thanh toán thẻ tín dụng không thành công.\nVui lòng thử lại hoặc thay đổi phương thức thanh toán.',
    'reservationManagement.completeReservation': 'Thay đổi trạng thái đặt chỗ',
    // eslint-disable-next-line max-len
    'reservationManagement.completeReservation.title': 'Trạng thái đặt chỗ đang được chuyển sang hoàn thành. \nVui lòng chọn phương thức thanh toán.',
    'reservationManagement.completeReservation.errorMessage': 'Không thể chuyển trạng thái đặt chỗ sang hoàn thành do không có phương thức thanh toán hỗ trợ',
    'reservationManagement.notificationMethod.operatorCancel': 'Trạng thái đặt chỗ đang được chuyển sang người điều hành hủy.',
    'reservationManagement.notificationMethod.subTitle': 'Gửi thông báo đến người dùng ( màn hình ThankYou,...)',
    'reservationManagement.member_name_csv': 'Tên (Hệ thống cũ)',
    'reservationManagement.reservation_number': 'Mã đặt chỗ',
    'reservationManagement.reservation_number_search': 'Mã đặt chỗ',
    'reservationManagement.payment_id': 'ID thanh toán',
    'reservationManagement.get_onoff_pairkey': 'Nhận cặp khóa tắt mở',
    'reservationManagement.firstName_csv': 'Tên',
    'reservationManagement.lastName_csv': 'Họ',
    'reservationManagement.member_type': 'Phân loại hội viên',
    'reservationManagement.product_name': 'Tên sản phẩm',
    'reservationManagement.name': 'Tên',
    'reservationManagement.booker_information': 'Thông tin người đặt chuyến',
    'reservationManagement.booker_name': 'Tên người đặt chuyến',
    'reservationManagement.representative_name': 'Tên người đại diện',
    'reservationManagement.representative_infor': 'Thông tin người đại diện',
    'reservationManagement.product_reservation_number': 'Mã đặt chỗ sản phẩm',
    'reservationManagement.reservation_date': 'Ngày đặt chỗ',
    'reservationManagement.the_date_of_use': 'Ngày sử dụng',
    'reservationManagement.categories': 'Phân loại sản phẩm',
    'reservationManagement.dropoff_address': 'Địa chỉ trả khách',
    'reservationManagement.dropoff_time': 'Thời gian trả khách thực tế',
    'reservationManagement.dropoff_time_search': 'Thời gian trả khách dự kiến',
    'reservationManagement.get_on_agency_name': 'Công ty vận hành tàu lên',
    'reservationManagement.get_off_agency_name': 'Công ty vận hành tàu xuống',
    'reservationManagement.exportAlert': ' Tải về hơn 1000 bản ghi sẽ mất thời gian. Bạn có muốn tải về không?',
    'reservationManagement.exportAlertLimit': 'Có quá nhiều kết quả tìm kiếm.\nHãy thu hẹp điều kiện tìm kiếm và thực hiện lại.\nHãy giới hạn xuống dưới 50000 kết quả.',
    'reservationManagement.exportAlert.limitRequest': 'Có quá nhiều người dùng đang đồng thời xuất CSV, hãy đợi một lúc trước khi xuất CSV lại.',
    'reservationManagement.exportAlert.narrowDateFromTo': 'Vui lòng giới hạn Ngày đặt, Thời gian đi hoặc Thời gian đến trong vòng 1 năm trước khi tải xuống.',
    'reservationManagement.get_on_busstop_code': 'Mã ga lên',
    'reservationManagement.get_off_busstop_code': 'Mã ga xuống',
    'reservationManagement.get_on_use_date': 'Ngày lên',
    'reservationManagement.get_off_use_date': 'Ngày xuống',
    'reservationManagement.get_on_flag': 'Cờ lên xe',
    'reservationManagement.get_off_flag': 'Cờ xuống xe',
    'reservationManagement.payment_time': 'Thời gian thanh toán',
    'reservationManagement.qr_fee': 'Phí QR',
    'reservationManagement.order_id': 'ID thanh toán',
    'reservationManagement.qr_payg_id': 'QR payg ID',
    'reservationManagement.get_on_stop_name': 'Tên ga lên',
    'reservationManagement.get_off_stop_name': 'Tên ga xuống',
    'reservationManagement.pickup_location_booking_jit': 'Địa điểm đón khách',
    'reservationManagement.dropoff_location_booking_jit': 'Địa điểm trả khách',
    'reservationManagement.message_not_enough_seats_left': 'Không còn đủ ghế trống',
    'reservationManagement.select_flight': 'Chọn chuyến',
    'reservationManagement.select_the_ride': 'Vui lòng chọn chuyến đi bạn thích',
    'reservationManagement.booking_deadline': 'Thời gian chốt chuyến',
    'reservationManagement.available_seats': 'Số ghế trống',
    'reservation.booking_jit_nodata': 'Không có chuyến',
    'reservation.arrival': 'Chiều đến',
    'reservation.departure': 'Chiều đi',
    'reservation.booking.0': 'Đặt chỗ tạm (Chỉ JIT)',
    'reservation.booking.1': 'Người dùng đặt chỗ',
    'reservation.booking.2': 'Người điều hành đặt chỗ',
    'reservation.booking.3': 'Người dùng hủy',
    'reservation.booking.4': 'Người điều hành hủy',
    'reservation.booking.5': 'Tài xế hủy',
    'reservation.booking.6': 'Hệ thống hủy',
    'reservation.booking.7': 'Hoàn thành',
    'reservation.booking.8': 'Đặt mới',
    'reservation.booking.9': 'Đặt chỗ chưa được thiết lập',
    'reservation.booking.10': 'Người dùng đặt chỗ ( chưa lên xe)',
    'reservation.booking.11': 'Người dùng đặt chỗ ( đã lên xe)',
    'reservation.booking.12': 'Người điều hành đặt chỗ ( chưa lên xe)',
    'reservation.booking.13': 'Người điều hành đặt chỗ ( đã lên xe)',
    'reservation.booking.14': 'Người điều hành hoàn thành',
    'reservation.booking.15': 'Đặt chỗ',
    'reservation.delivery.1': 'Chưa thanh toán',
    'reservation.delivery.2': 'Đã thanh toán',
    'reservation.delivery.3': 'Chưa hoàn tiền',
    'reservation.delivery.4': 'Đã hoàn tiền',
    'reservation.mobi.1': 'Xe buýt theo yêu cầu',
    'reservation.mobi.2': 'Xe buýt JIT',
    'reservationManagement.registration_number': 'Biển số xe',
    'reservationManagement.vehicle_id': 'Mã xe',
    'reservationManagement.vehicle_no': 'Biển số xe',
    'reservationManagement.driver_rating': 'Điểm xếp hạng',
    'reservationManagement.description_rating': 'Nhận xét về đánh giá',
    'reservationManagement.driver_average_rating': 'Đánh giá trung bình của tài xế',
    'reservationManagement.applicable_plan': 'Gói áp dụng',
    'reservationManagement.number_of_adults': 'Số người lớn',
    'reservationManagement.number_of_children': 'Số trẻ em',
    'reservationManagement.number_of_subscribers': 'Số người dùng gói đăng kí',
    'reservationManagement.payment_method': 'Phương thức thanh toán',
    'reservationManagement.amount': 'Tổng tiền',
    'reservationManagement.desired_pickup_date_and_time': 'Thời gian đón khách theo dự định ban đầu',
    'reservationManagement.pickup_final_presentation_date_and_time': 'Thời gian nhận ETA đón khách lần cuối cùng',
    'reservationManagement.dropoff_final_presentation_date_and_time': 'Thời điểm nhận ETA trả khách lần cuối cùng',
    'reservationManagement.pickup_date_time': 'Thời gian đón khách xong',
    'reservationManagement.dropoff_date_time': 'Thời gian trả khách xong',
    'reservationManagement.estimated_pickup_date_and_time': 'Thời gian đón khách theo dự định ban đầu',
    'reservationManagement.estimated_dropoff_date_and_time': 'Thời gian trả khách theo dự định ban đầu',
    'reservationManagement.cancellation_time': 'Thời gian hủy chuyến',
    'reservationManagement.pickup_address_csv': 'Địa chỉ đón khách',
    'reservationManagement.dropoff_address_csv': 'Địa chỉ trả khách',
    'reservationManagement.pickup_waypoint_latitude': 'Vĩ độ địa chỉ đón khách',
    'reservationManagement.pickup_waypoint_longitude': 'Kinh độ địa chỉ đón khách',
    'reservationManagement.dropoff_waypoint_latitude': 'Vĩ độ địa chỉ trả khách',
    'reservationManagement.dropoff_waypoint_longitude': 'Kinh độ địa chỉ trả khách',
    'reservationManagement.number_of_carpools': 'Số lượng booking đi cùng xe',
    'reservationManagement.number_of_people': 'Số lượng người đi cùng xe',
    'reservationManagement.number_of_stops_before_pickup': 'Số lượt đỗ xe trước khi đón',
    'reservationManagement.number_of_stops_while_boarding': 'Số lượt đỗ xe sau khi đón',
    'reservationManagement.number_of_chats_user': 'Số lượt chat (hội viên)',
    'reservationManagement.number_of_chats_driver': 'Số lượt chat (tài xế)',
    'reservationManagement.number_of_calls_completed': 'Số lượt gọi thành công',
    'reservationManagement.number_of_lost_calls_user': 'Số lượt gọi thất bại (hội viên)',
    'reservationManagement.number_of_lost_calls_driver': 'Số lượt gọi thất bại (tài xế)',
    'reservationManagement.subscription_id': 'ID mua',
    'reservationManagement.plan_type': 'Phân loại gói',
    'reservationManagement.plan_status': 'Tình trạng gói',
    'reservationManagement.enrollment_date': 'Ngày giờ đăng kí',
    'reservationManagement.free_period_start_date': 'Ngày bắt đầu dùng thử',
    'reservationManagement.free_period_end_date': 'Ngày kết thúc dùng thử',
    'reservationManagement.payment_date_end_time': 'Ngày giờ thanh toán',
    'reservationManagement.cancellation_date_end_time': 'Ngày giờ hủy',
    'reservationManagement.update_off_date': 'Ngày tắt gia hạn',
    'reservationManagement.automatic_updating': 'Tự động gia hạn',
    'reservationManagement.payment_status': 'Trạng thái thanh toán',
    'reservationManagement.payment_code': 'Mã thanh toán',
    'reservationManagement.family_member_1': 'Thành viên gia đình 1',
    'reservationManagement.family_member_2': 'Thành viên gia đình 2',
    'reservationManagement.family_member_3': 'Thành viên gia đình 3',
    'reservationManagement.family_member_4': 'Thành viên gia đình 4',
    'reservationManagement.family_member_5': 'Thành viên gia đình 5',
    'reservationManagement.family_member_6': 'Thành viên gia đình 6',
    'reservationManagement.for_coupon_tickets': 'Dành cho vé giảm giá',
    'reservationManagement.number_of_tickets': 'Số lượng vé',
    'reservationManagement.remaining_number_of_coupon_tickets': 'Số lượng vé giảm giá còn lại',
    'reservationManagement.age': 'Tuổi',
    'reservationManagement.email_address': 'Địa chỉ email',
    'reservationManagement.created_by': 'created_by',
    'reservationManagement.canceled_by': 'canceled_by',
    'reservationManagement.rating_csv_output': 'Xuất CSV Đánh Giá',
    'reservationManagement.rating': 'Đánh giá',
    'reservationManagement.rating_text': 'Nội dung đánh giá',
    'reservationManagement.infantsCanBeAdd': 'Có thể thêm tối đa 2 trẻ sơ sinh cho mỗi người lớn/trẻ em',
    'reservationManagement.maxNumberOfPassenger': 'Số người tối đa có thể đặt cho khu vực này là {{maxPassenger}} người.',
    'reservationManagement.expectPickUpTime': 'Thời gian đón mong muốn',
    'reservationManagement.reservationInformation': 'Thông tin chuyến',
    'reservationManagement.operationCompany': 'Công ty vận hành',
    'reservationManagement.reservationTime': 'Ngày giờ đặt chỗ',
    'reservationManagement.companyName': 'Tên công ty vận hành',
    'reservationManagement.routeName': 'Tên tuyến',
    'reservationManagement.pickupTime': 'Ngày giờ đón',
    'reservationManagement.dropOffTime': 'Ngày giờ trả',
    'reservationManagement.busRoute': 'Tuyến',
    'reservationManagement.busReservationDateTime': 'Ngày giờ đặt chỗ',
    'reservationManagement.driverNameDefault': 'Tên tài xế (Mặc định)',
    'reservationManagement.driverNameSelectApp': 'Tên tài xế (Chọn trên ứng dụng)',

    // reservationManagement detail
    'reservationManagement.usage_status': 'Trạng thái sử dụng',
    'reservationManagement.use_start_date': 'Ngày giờ bắt đầu sử dụng',
    'reservationManagement.use_end_date': 'Ngày giờ kết thúc sử dụng',
    'reservationManagement.member_infomation': 'Thông tin khách hàng',
    'reservationManagement.delivery_status': 'Trạng thái giao hàng',
    'reservationManagement.booking_detail': 'Chi tiết đặt xe',
    'reservationManagement.basic_information': 'Thông tin cơ bản',
    'reservationManagement.reservation_date_and_time': 'Ngày giờ đặt chỗ',
    'reservationManagement.reservation_confirm_date_and_time': 'Ngày giờ xác nhận đặt',
    'reservationManagement.reservation_reception_deadline': 'Thời gian chốt chuyến (JIT)',
    'reservationManagement.cancellation_date_and_time': 'Ngày giờ hủy',
    'reservationManagement.date_of_use_start': 'Ngày giờ (bắt đầu) sử dụng',
    'reservationManagement.total_amount_paid': 'Tổng số tiền đã nộp',
    'reservationManagement.membership_information': 'Thông tin hội viên',
    'reservationManagement.membership_number': 'Mã hội viên',
    'reservationManagement.memberName': 'Tên',
    'reservationManagement.sex': 'Giới tính',
    'reservationManagement.birthday': 'Ngày sinh',
    'reservationManagement.current_age': 'Tuổi hiện tại',
    'reservationManagement.change_mail_address': 'Thay đổi địa chỉ email',
    'reservationManagement.delivery_collection_delivery_service': 'Dịch vụ đi chợ/Lấy hàng/Giao hàng',
    'reservationManagement.number': 'Số lượng',
    'reservationManagement.price': 'Tiền hàng',
    'reservationManagement.per_price': '(Đơn vị 1 món)',
    'reservationManagement.service_providing_company_name': '(Tên công ty cung cấp dịch vụ)',
    'reservationManagement.collection_origin_address': 'Địa chỉ nơi lấy hàng',
    'reservationManagement.collection_origin_telephone_number': 'Số điện thoại nơi lấy hàng',
    'reservationManagement.delivery_address': 'Nơi giao hàng',
    'reservationManagement.delivery_phone_number': 'Số điện thoại của nơi giao hàng',
    'reservationManagement.collection_date_and_time': 'Ngày giờ lấy hàng',
    'reservationManagement.scheduled_delivery_date': 'Ngày giờ giao hàng dự kiến',
    'reservationManagement.delivery_completion_date_and_time': 'Ngày giờ hoàn tất giao hàng',
    'reservationManagement.taxi_on_demand_service': 'Dịch vụ xe theo yêu cầu - Taxi',
    'reservationManagement.taxi_jit_service': 'Taxi-MOBI',
    'reservationManagement.number_of_users': 'Số người sử dụng',
    'reservationManagement.per_person': '(Đơn vị 1 người)',
    'reservationManagement.pickup_address': 'Địa chỉ đón khách',
    'reservationManagement.drop_off_address': 'Địa chỉ trả khách',
    'reservationManagement.scheduled_pickup_time': 'Thời gian đón khách dự kiến',
    'reservationManagement.scheduled_drop_off_time': 'Thời gian trả khách dự kiến',
    'reservationManagement.pickup_time': 'Thời gian đón khách xong',
    'reservationManagement.pickup_time_search': 'Thời gian đón khách dự kiến',
    'reservationManagement.drop_off_time': 'Thời gian trả khách xong',
    'reservationManagement.new_drop_off_time': 'Thời gian trả khách xong',
    'reservationManagement.cancellation_information_rules': 'Thông tin - Quy tắc hủy',
    'reservationManagement.cancellation_fee': 'Phí hủy',
    'reservationManagement.deposit_information': 'Thông tin thanh toán',
    'reservationManagement.deposit_method': 'Phương thức thanh toán',
    'reservationManagement.total_billed_amount': 'Tổng số tiền',
    'reservationManagement.deposited_amount': 'Số tiền đã thanh toán',
    'reservationManagement.refunded_amount': 'Số tiền đã hoàn trả',
    'reservationManagement.passenger': 'Số khách',
    'reservationManagement.number_passenger': 'Số khách lên xe',
    'reservationManagement.people': 'Người',
    'reservationManagement.settlement_date': 'Ngày giờ thanh toán',
    'reservationManagement.refund_method': 'Phương thức hoàn tiền',
    'reservationManagement.refund_date_and_time': 'Ngày giờ hoàn tiền',
    'reservationManagement.internal_memo': 'Ghi chú nội bộ',
    'reservationManagement.business_memo': 'Ghi chú của nhà cung cấp',
    'reservationManagement.up_to_characters': '(Giới hạn trong xxxx ký tự)',
    'reservationManagement.update': 'Cập nhật',
    'reservationManagement.cancellation_of_arrangement_contents': 'Hủy nội dung chuẩn bị',
    'reservationManagement.chat_history': 'Lịch sử trò chuyện',
    'reservationManagement.no_message_yet': 'Chưa có tin nhắn nào',
    'reservationManagement.loading': 'Đang tải',
    'reservationManagement.voice_chat': 'Cuộc gọi thoại',
    'reservationManagement.cancel_call': 'Cuộc gọi bị hủy',
    'reservationManagement.miss_call': 'Cuộc gọi nhỡ',
    'reservationManagement.polyline_tracking': 'Theo dõi tuyến đường',
    'reservationManagement.no_polyline_data': 'Không có dữ liệu tuyến đường',
    'reservationManagement.estimated_pickup_time': 'Thời gian đón khách theo dự định ban đầu',
    'reservationManagement.estimated_drop_off_time': 'Thời gian trả khách theo dự định ban đầu',
    'reservationManagement.otherPaymentRetry': 'Thay đổi trạng thái nộp tiền',
    'reservationManagement.creditCardRetry': 'Thử lại thẻ tín dụng',
    'card.not.found': 'Không tìm thấy thẻ',
    'amount.not.support': 'Số tiền không được hỗ trợ',
    'reservationManagement.message.creditCardError': 'Thanh toán không thành công. Vui lòng thử lại.',
    'reservationManagement.update_memo_modal': 'Bạn có muốn cập nhật ghi chú đặt chỗ của bạn?',
    'reservationManagement.number_of_users_origin': 'Số lượng hành khách (Ban đầu)',
    'reservationManagement.number_of_users_latest': 'Số lượng hành khách (Mới nhất)',
    'reservationManagement.amount_origin': 'Tổng tiền (Ban đầu)',
    'reservationManagement.amount_latest': 'Tổng tiền (Mới nhất)',

    // create reservation
    'reservationManagement.create_reservation': 'Tìm kiếm ghế trống',
    'reservationManagement.store_sales_office': 'Cửa hàng - Văn phòng kinh doanh',
    'reservationManagement.date_and_time_of_use': 'Ngày giờ sử dụng',
    'reservationManagement.gender': '(Giới tính)',
    'reservationManagement.choose_a_location_on_the_map': 'Hãy chọn một địa điểm trên bản đồ',
    'reservation.please_select_router': 'Hãy chọn tuyến đường di chuyển từ các tuyến bên trên',
    'reservation.error_waypoint_not_associate': 'Điểm tham chiếu đã chọn không còn khả dụng. Vui lòng thay đổi điểm tham chiếu.',
    'reservationManagement.service_time': 'Thời gian dịch vụ',
    'reservationManagement.density_rate': 'Tình trạng đông khách',
    'reservationManagement.detail': 'Chi tiết',
    'reservationManagement.product_information': 'Thông tin sản phẩm',
    'reservationManagement.items': 'Item',
    'reservationManagement.quantity': 'Số lượng',
    'reservationManagement.order_details': 'Nội dung đặt hàng',
    'reservationManagement.change_order': 'Chạm vào sản phẩm để thay đổi nội dung đặt hàng',
    'reservationManagement.view_cart': 'Xem giỏ hàng',
    'reservationManagement.btnSwap': 'Hoán đổi vị trí',
    'reservationManagement.discount_target': 'Đối tượng giảm giá',
    'reservationManagement.amount_after_discount': 'Số tiền sau khi giảm giá',
    'reservationManagement.applied_number': 'Số người giảm giá',
    'reservationManagement.one_time_discount': 'Giảm giá cho người đi cùng',
    'reservationManagement.max_number_people': 'Số lượng tối đa số lượng người được giảm giá',
    'reservationManagement.remaining_number_people': 'Số lượng người còn lại được giảm giá',
    'reservationManagement.discountable_per_tiket': '{{person}} người được giảm giá trên mỗi vé',
    'reservationManagement.plan_out_of_service': 'Gói nằm ngoài thời gian hoạt động của geofence',
    'reservationManagement.unlimitedRides': 'Chuyến không giới hạn (Bao gồm cả option gia đình)',
    'reservationManagement.unitCost': 'Đơn giá',
    'reservationManagement.peopleTypeAmount': 'Thành tiền',
    'reservationManagement.partnerDiscount': 'Giảm giá của Đối tác',
    'reservationManagement.total': 'Tổng tiền',
    'reservationManagement.totalDiscount': 'Tổng giảm giá',
    'reservationManagement.finalTotal': 'Tổng tiền thanh toán',
    'reservationManagement.infantInvalid': 'Trẻ sơ sinh cần lên xe cùng ít nhất một người lớn.',

    // new Reservation
    'newReservation.dateTime': 'Ngày giờ đặt',
    'newReservation.number': 'Mã đặt hàng',
    'newReservation.orderer': 'Người đặt hàng',
    'newReservation.totalFee': 'Số tiền',
    'newReservation.registerer': 'Người đặt',
    'newReservation.cardNumber': 'Mã số thẻ',
    'newReservation.cardCompany': 'Công ty thẻ',
    'newReservation.expiredDate': 'Thời hạn hiệu lực',
    'newReservation.month': 'Tháng',
    'newReservation.year': 'Năm',
    'newReservation.back': 'Quay lại',
    'newReservation.confirm': 'Xác nhận',
    'newReservation.complete': 'Hoàn thành đăng ký thanh toán',
    'newReservation.operator': 'Người vận hành',
    'newReservation.linkMap1': 'Đưỡng dẫn Google Map',
    'newReservation.linkMap2': 'Đưỡng dẫn Google Map',
    'newReservation.polyline_for_booking': 'Tuyến đường đặt chỗ',

    // taxi on-demand
    'reservationManagement.taxi_on_demand': 'Taxi/Theo yêu cầu',
    'reservationManagement.early_order': 'Thứ tự sớm',
    'reservationManagement.cheapest': 'Thứ tự rẻ',
    'reservationManagement.easy_order': 'Thứ tự dễ dàng',
    'reservationManagement.shuttle': 'Xe buýt đưa đón',
    'reservationManagement.charge_forecast': 'Dự đoán tiền phí',
    'reservationManagement.estimated_required_time': 'Thời gian cần dự tính',
    'reservationManagement.taxi': 'Taxi',
    'reservationManagement.fee': 'Chi phí',
    'reservationManagement.time_required': 'Thời gian cần thiết',
    'reservationManagement.number_transfer': 'Số lần chuyển tiền',
    'reservationManagement.counts': 'lần',
    'reservationManagement.route': 'Tuyến đường',
    'reservationManagement.present_location': 'Vị trí hiện tại',
    'reservationManagement.waiting_time': 'Thời gian chờ',
    'reservationManagement.pick_up_time_varies': ' ',
    'reservationManagement.pick_up_time': 'Thời gian đón',
    'reservationManagement.arrival_time': 'Thời gian đến',
    'reservationManagement.dankai_bus': 'Xe buýt Tankai',
    'reservationManagement.departure_time': 'Giờ xuất phát',
    'reservationManagement.call_a_taxi': 'Gọi taxi',
    'reservationManagement.miyazu_station': 'Ga Miyazu',

    // Reservation delivery
    'reservationManagement.delivery': 'Giao hàng',
    'reservationManagement.restaurant': 'Nhà hàng',
    'reservationManagement.grocery_store': 'Thực phẩm - Siêu thị',
    'reservationManagement.pharmacy_drugstore': 'Nhà thuốc',
    'reservationManagement.near_by_grocery_store': 'Cửa hàng thực phẩm gần đây',
    'reservationManagement.restaurant_detail': 'Chi tiết nhà hàng',
    'reservationManagement.supermarket': 'Siêu thị',
    'reservationManagement.restaurant_name': 'Tên nhà hàng',
    'reservationManagement.restaurant_description': 'Mô tả cửa hàng',
    'reservationManagement.if_you_order_now': 'Nếu đặt hàng bây giờ',
    'reservationManagement.delivery_to': 'sẽ đến lúc',
    'reservationManagement.social_distance': 'Khoảng cách xã hội',
    'reservationManagement.check_store': 'Kiểm tra cửa hàng',
    'reservationManagement.vegetables': 'Rau',
    'reservationManagement.fruit': 'Hoa quả',
    'reservationManagement.meat': 'Thịt',
    'reservationManagement.item_1': 'item_1',
    'reservationManagement.item_name_1': 'item_name_1',
    'reservationManagement.item_name_2': 'item_name_2',
    'reservationManagement.item_name_3': 'item_name_3',
    'reservationManagement.customer_info': 'Thông tin khách hàng',
    'reservationManagement.customer_confirm': 'Xác nhận đặt chỗ',
    'reservationManagement.passenger_detail': 'Chi tiết khách hàng',
    'reservationManagement.member_information_selection': 'Chọn thông tin hội viên',
    'reservationManagement.member_information': 'Thông tin khách hàng',
    'reservationManagement.for_member': '(Dành cho hội viên)',
    'reservationManagement.name_furigana': 'Furigana',
    'reservationManagement.tel': 'ĐIỆN THOẠI',
    'reservationManagement.member_id': 'ID thành viên',
    'reservationManagement.used_subscription': 'Đã sử dụng gói đăng ký',
    'reservationManagement.subscriptions': 'Gói đăng ký',
    'reservationManagement.subscriptions_main_user': 'Người dùng chính',
    'reservationManagement.subscriptions_using': 'Đang sử dụng',
    'reservationManagement.name_info': 'Tên',
    'reservationManagement.confirmation': 'Xác nhận',
    'reservationManagement.date_and_time_of_us': 'Ngày giờ sử dụng',
    'reservationManagement.boarding_place': 'Địa điểm lên xe',
    'reservationManagement.get_off_place': 'Địa điểm xuống xe',
    'reservationManagement.boarding_datetime': 'Ngày giờ lên xe',
    'reservationManagement.boarding_datetime_from': 'Ngày giờ lên xe (từ)',
    'reservationManagement.boarding_datetime_to': 'Ngày giờ lên xe (đến)',
    'reservationManagement.get_off_datetime': 'Ngày giờ xuống xe',
    'reservationManagement.get_off_datetime_from': 'Ngày giờ xuống xe (từ)',
    'reservationManagement.get_off_datetime_to': 'Ngày giờ xuống xe (đến)',
    'reservationManagement.download_csv': 'Tải về CSV',
    'reservationManagement.upload_csv': 'Tải lên CSV',
    'reservationManagement.number_people': 'Số người',
    'reservationManagement.status': 'Trạng thái',
    'reservationManagement.destination': 'Nơi đến',
    'reservationManagement.amount_of_money': 'Số tiền',
    'reservationManagement.customer_information': 'Thông tin khách hàng',
    'reservationManagement.select_member_info': 'Chọn thông tin hội viên',
    'reservationManagement.book': 'Đặt chỗ',
    'reservationManagement.send_mail': 'Gửi email',
    'reservationManagement.loginId': 'ID đăng nhập',
    'reservationManagement.member': 'Thành viên',
    'reservationManagement.departure_taxi': 'Hãy chọn nơi xuất phát',
    'reservationManagement.departure_delivery': 'Hãy chọn cửa hàng',
    'reservationManagement.destination_taxi': 'Hãy chọn nơi đến',
    'reservationManagement.destination_delivery': 'Hãy chọn địa chỉ giao hàng',
    'reservationManagement.next': 'Trang tiếp theo',
    'reservationManagement.search': 'Tìm kiếm',
    'reservationManagement.cancel': 'Hủy',
    'reservationManagement.reservation': 'Đặt chỗ',
    'reservationManagement.absent': 'Vắng mặt',
    'reservationManagement.take_out_office': 'Đưa ra khỏi văn phòng',
    'reservationManagement.pickup_location': 'Nơi lên xe',
    'reservationManagement.dropoff_location': 'Nơi xuống xe',
    'reservationManagement.no_result': 'Không tìm thấy người dùng tương ứng',
    'reservationManagement.ride_rate': 'Tỉ lệ lấp đầy xe',
    'reservationManagement.estimate_on_board': 'Dự kiến trên xe',
    'reservationManagement.discount_percent': 'Tỉ lệ giảm giá',
    'reservationManagement.discount_price': 'Số tiền giảm giá',
    'reservationManagement.fee_breakdown': 'Kê chi tiết giá tiền',
    'reservationManagement.family_subscription_price': 'Thành viên gói mobi đi không giới hạn (Bao gồm cả option gia đình) ',
    'reservationManagement.in_plan': 'tính vào giá gói',
    'reservationManagement.onetime_pride': 'mobi phí theo lượt',
    'reservationManagement.discount': 'Giảm giá',
    'reservationManagement.total_amount': 'Tổng tiền',
    'reservationManagement.mobi_type': 'Loại Mobi',
    'reservationManagement.jit_pride': 'Chuyến JIT',
    'reservationManagement.search_route': 'Tìm chuyến',
    'reservationManagement.estimate_contact_time': 'Ước tính thời gian liên hệ',
    'reservationManagement.seats': ' (ghế)',

    // waypoint association
    'reservationManagement.reset_waypoint': 'Đặt lại điểm tham chiếu',
    'reservationManagement.drop_off_not_associated': 'Điểm tham chiếu xuống đang không được liên kết với điểm tham chiếu lên đã chọn',
    'reservationManagement.pickup_not_associated': 'Điểm tham chiếu lên đang không được liên kết với điểm tham chiếu xuống đã chọn',
    'reservationManagement.pickup_not_associated_drop_off': 'Điểm tham chiếu lên và xuống đang không được liên kết với nhau',
    'reservationManagement.location_is_not_support': 'Địa điểm này không được hỗ trợ',

    // Advance booking
    'reservationManagement.advance.select.ride': 'Chọn thời gian đặt chuyến',
    'reservationManagement.advance.pickup.date': 'Ngày đón',
    'reservationManagement.advance.pickup.time': 'Giờ đón',
    'reservationManagement.advance.pickup.time.not.past': 'Giờ đón là giờ hiện tại hoặc tương lai',
    'reservationManagement.advance.booking.type': 'Loại đặt chuyến',
    'reservationManagement.advance.booking.type.real-time': 'Đặt ngay',
    'reservationManagement.advance.booking.type.advance': 'Đặt trước',
    'reservationManagement.advance.no.offer': 'Hiện tại không có chuyến khả dụng, vui lòng thử lại hoặc thay đổi thông tin đặt chuyến!',
    'reservationManagement.advance.route.information': 'Thông tin đặt chuyến',
    'reservationManagement.advance.ride.options': 'Các lựa chọn đặt chuyến',
    'reservationManagement.advance.estimated.pickup.time': 'Thời gian đón khách dự kiến',
    'reservationManagement.advance.estimated.dropoff.time': 'Thời gian trả khách dự kiến',
    'reservationManagement.advance.adult.one-time.price': 'Giá dành cho {{passenger}} người lớn (bao gồm phí đặt trước: {{price}}/người)',
    'reservationManagement.advance.one_time_fee': 'Bao gồm phí đặt trước, {{fee}}/người',
    'reservationManagement.advance.one_time_fee_adult_child': 'Phí đặt trước sẽ được áp dụng ({{fee_adult}}/ người lớn, {{fee_child}}/ trẻ em)',
    'reservationManagement.advance.valid_operating_of_plan': 'Gói có hiệu lực từ {{operating_hour}}',
    'reservationManagement.advance.booking_fee': 'Phí đặt trước',
    'reservationManagement.advance.booking_cost': 'Phí đặt trước',
    'reservationManagement.advance.available_booking_time': 'Thời gian đặt chỗ khả dụng: ',
    'reservationManagement.advance.pickup_time_outside_available': 'Thời gian đặt chỗ trên không khả dụng',
    'reservationManagement.advance.booking_in_tejimai_time': 'Có thể đặt chuyến sau {{minutes}} phút từ thời điểm hiện tại',
    'reservationManagement.one_time_surcharge': 'Phụ phí theo giờ sẽ được áp dụng đối với thời gian đón đã chọn ({{surcharge_adult}}/ người lớn, {{surcharge_child}}/ trẻ em)',
    'reservationManagement.including_time_range_surcharge': 'Phụ phí theo giờ ({{surcharge_fee}}/người)',
    'reservationManagement.including_time_range_discount': 'Giảm giá theo giờ ({{surcharge_fee}}/người)',
    'reservationManagement.time_range_surcharge': 'Phụ phí theo giờ sẽ được áp dụng từ {{from}} đến {{to}}',
    'reservationManagement.apply_time_range_surcharge': 'Phụ phí theo giờ sẽ được áp dụng đối với thời gian đón trên',
    'reservationManagement.surcharge': 'Phụ phí',

    // Reservation message
    'reservationManagement.out_geofence': 'Địa điểm bạn đã chọn nằm ngoài khu vực cung cấp dịch vụ',
    'successful_booking': 'Đặt chỗ đã thành công',
    'booking_failed': 'Đặt chỗ thất bại',
    'booking.time.so.far': 'Thời gian đặt quá xa',
    'booking.retry': 'Đặt chỗ thất bại, bạn có muốn thử lại không?',
    'reservationManagement.select_member': 'Số thành viên bạn chọn lớn hơn số khách hàng đã đăng ký. Xin mời chọn lại.',
    'reservationManagement.select_main_user': 'Xin mời chọn người dùng chính sử dụng gói đăng ký',
    'reservationManagement.no_rating': 'Không có đánh giá cho chuyến đi này được nêu ra.',
    'mass.shift.not.available.for.booking': 'Ca không còn để đặt trước.',
    'mass.booking.not.support.booking.twice': 'Chúng tôi không hỗ trợ đặt chỗ 2 lần.',
    'mass.booking.overlaps.time.with.another': 'Không hỗ trợ đặt trước 2 chuyến có sự chồng chéo về thời gian đón trả',
    'mass.booking.time.so.far': 'Thời gian đặt xe quá xa. Vui lòng liên hệ quản trị viên để được hỗ trợ.',
    'mass.admin.booking.exceed.max.number.of.booking.per.day': 'Khách hàng đã đặt trước {{maxNumberOfBookings}} chuyến vào ngày này. Vui lòng thay đổi ngày đặt chuyến!',
    'mass.booking.no.available.seat': 'Chuyến không còn đủ ghế khả dụng. Vui lòng chọn chuyến khác!',
    'mass.booking.exceed.offers.timeout': 'Thông tin đặt chuyến có thể đã bị thay đổi. Vui lòng thử lại!',

    // Delivery time setting
    'deliverySetting.list_delivery': 'Danh sách thời gian nhận đi chợ',
    'deliverySetting.collect_setting': 'Cài đặt đồng loạt',
    'deliverySetting.calendar_detail_setting': 'Cài đặt chi tiết lịch',
    'deliverySetting.acceptance_time': 'Thời gian nhận giao hàng',
    'deliverySetting.status.unregister': 'Chưa đăng ký',
    'deliverySetting.status.customize': 'Cài đặt riêng',

    // QR Payment
    'qr_payment.confirm_delete_items': 'Xóa {{numSelected}} món?',
    'qr_payment.confirm_delete_item': 'Bạn có muốn xóa item này không?',
    'qrPayment.list_error_qr_payment': 'Danh sách lỗi thanh toán QR code',
    'qrPayment.use_date': 'Ngày sử dụng thanh toán mã QR',
    'qrPayment.payg_id': 'Payg ID',
    'qrPayment.qr_pair_key': 'Pair key của thanh toán mã QR',
    'qrPayment.stop_name': 'Ga dùng thanh toán mã QR',
    'qrPayment.user_email': 'Địa chỉ mail của người dùng thanh toán mã QR',
    'qrPayment.on_of_lag': 'Gắn cờ tắt mở thanh toán bầng mã QR',
    'qrPayment.details_error_qr_payment': 'Chi tiết thanh toán mã QR',
    'qrPayment.user_name': 'Tên người dùng thanh toán mã QR',
    'qrPayment.user_uuid': 'UUID người dùng thanh toán mã QR',
    'qrPayment.nodata': 'Không có dữ liệu',

    // simulation management page
    'simulationManagement.title': 'Quản lý mô phỏng',
    'simulationManagement.titleSearch': 'Điều kiện tìm kiếm',
    'simulationManagement.vehicleGroupType': 'Loại nhóm xe',
    'simulationManagement.simulation_id': 'ID mô phỏng',
    'simulationManagement.service_group_id': 'ID nhóm dịch vụ',
    'simulationManagement.service_id': 'ID dịch vụ',
    'simulationManagement.allow_vehicle_late': 'Cho phép xe muộn',
    'simulationManagement.enable_curb': 'Kích hoạt curb',
    'simulationManagement.detail': 'Chi tiết mô phỏng',
    'simulationManagement.information': 'Thông tin mô phỏng',
    'simulationManagement.clone': 'Sao chép mô phỏng',
    'simulationManagement.start_time': 'Thời gian bắt đầu',
    'simulationManagement.end_time': 'Thời gian kết thúc',
    'simulationManagement.time': 'Thời gian bắt đầu - Thời gian kết thúc',
    'simulationManagement.old_simulation_id': 'ID mô phỏng cũ',
    'simulationManagement.force': 'Lực lượng',
    'simulationManagement.transportation_type': 'Loại phương tiện giao thông',
    'simulationManagement.vehicle_capacity': 'Công suất phương tiện',
    'simulationManagement.max_additional_journey_time': 'Thời gian hành trình bổ sung tối đa',
    'simulationManagement.max_additional_journey_time_percent': 'Phần trăm thời gian hành trình bổ sung tối đa',
    'simulationManagement.max_walking_distance': 'Khoảng cách đi bộ tối đa',
    'simulationManagement.description': 'Mô tả',
    'simulationManagement.confirm_delete': 'Bạn có muốn xóa mô phỏng này không?',
    'simulationManagement.limit_user_booking': 'Giới hạn số người đặt xe',
    'simulationManagement.manual_manage_transit_stop': 'Quản lý thủ công điểm dừng chuyển tuyến',
    'simulationManagement.waypoint_mode': 'Chế độ điểm tham chiếu',
    'simulationManagement.nearest_distance': 'Khoảng cách gần nhất',
    'simulationManagement.enable_waypoints_cache': 'Bật bộ nhớ đệm điểm tham chiếu',
    'simulationManagement.door_to_door_mode': 'Chế độ cửa đến cửa',
    'simulationManagement.acceptable_waiting_time': 'Thời gian chờ đợi chấp nhận được',
    'simulationManagement.pickup_service_time': 'Thời gian dịch vụ đón khách',
    'simulationManagement.dropoff_service_time': 'Bỏ qua thời gian dịch vụ',
    'simulationManagement.shrink_time_delta': 'Thu hẹp thời gian delta',
    'simulationManagement.reverse_shrink_time_delta': 'Đồng bằng thời gian thu hẹp ngược',
    'simulationManagement.offer_auto_cancellation_timeout': 'Đề xuất thời gian chờ hủy tự động',
    'simulationManagement.stop_set_id': 'Dừng đặt ID',
    'simulationManagement.start_time_end_time_limitations': 'Giới hạn thời gian bắt đầu - kết thúc',
    'simulationManagement.currency_code': 'Mã tiền tệ',
    'simulationManagement.payment_support': 'Hỗ trợ thanh toán',
    'simulationManagement.willer_stop_set': 'Bộ dừng WILLER',
    'simulationManagement.swat_transit_stop_set_id': 'ID đặt điểm dừng chuyển tuyến SWAT',
    'simulationManagement.display_name': 'Tên hiển thị',
    'simulationManagement.performance_tracker_enabled': 'Đã bật trình theo dõi hiệu suất',
    'simulationManagement.check_simulation_swat': 'Kiểm tra mô phỏng SWAT',
    'simulationManagement.max_pool_size': 'Kích thước tối đa',
    'simulationManagement.trip_cost': 'Chi phí chuyến đi',
    'simulationManagement.time_factor': 'Time factor',
    'simulationManagement.waiting_time_cost_factor': 'Chi phí thời gian chờ',
    'simulationManagement.vehicle_mode': 'Chế độ xe',
    'mass.clone.simulation.failed': 'Nhân bản mô phỏng không thành công',
    'mass.service.not.found': 'Không tìm thấy dịch vụ',
    'simulationManagement.clone.failedProcess': 'Quá trình nhân bản vẫn chưa kết thúc, vui lòng nhấp vào "Có" để tiếp tục.',
    'simulationManagement.show_walking_result': 'Hiện kết quả đi bộ',
    'simulationManagement.show_waypoint_in_map': 'Hiện điểm trên bản đồ',
    'simulationManagement.use_path_equalizer': 'Sử dụng cân bằng đường dẫn',

    // shuttle management
    'shuttleSetting.headerTitle': 'Cài đặt menu bên phải',
    'shuttleSetting.searchTitle': 'Điều kiện tìm kiếm',
    'shuttleSetting.header': 'Tiêu đề',
    'shuttleSetting.title': 'Tiêu đề',
    'shuttleSetting.description': 'Mô tả',
    'shuttleSetting.createTitle': 'Tạo mới',
    'shuttleSetting.detailTitle': 'Chi tiết',
    'shuttleSetting.formTitle': 'Biểu mẫu Cài đặt menu bên phải',
    'shuttleSetting.question.confirm.create': 'Bạn muốn tạo mới Cài đặt menu bên phải',
    'shuttleSetting.question.confirm.update': 'Bạn muốn cập nhật Cài đặt menu bên phải',

    // Geofences & services
    'geofence.none': 'Không có',
    'geofence.geofence_id': 'ID khu vực',
    'geofence.status': 'Trạng thái của khu vực',
    'geofence.simulation_id': 'ID mô phỏng',
    'geofence.name': 'Tên khu vực',
    'geofence.jit_home_to_work_sim_id': 'HTW',
    'geofence.jit_work_to_home_sim_id': 'WTH',
    'geofence.on_demand_sim_id': 'Theo yêu cầu',
    'geofence.simulation_taxi': 'Taxi',
    'geofence.service_group': 'Nhóm dịch vụ',
    'geofence.detail_title': 'Chi tiêt khu vực',
    'geofence.create_title': 'Thêm khu vực',
    'geofence.title': 'Quản lý khu vực',
    'geofence.description': 'Mô tả',
    'geofence.bg_color': 'Màu nền',
    'geofence.geofence_last_sync': 'Lần đồng bộ gần đây nhất:  {{last_sync}}',
    'geofence.enable_for_admin': 'Dành cho admin',
    'geofence.enable_for_app': 'Dành cho ứng dụng',
    'geofence.confirm_update': 'Bạn có muốn cập nhật khu vực này không?',
    'geofence.confirm_redister': 'Bạn có muốn thêm khu vực này không?',
    'geofence.confirm_sync': 'Bạn có muốn đồng bộ khu vực này không?',
    'geofence.swat_geofence_id': 'ID vùng Swat',
    'geofence.swat_geofence_key': 'Key vùng Swat',
    'geofence.in_hole': 'In hole',
    'geofence.unitrand_sim_id': 'Unitrand sim ID',
    'geofence.service_group_id': 'ID nhóm dịch vụ',
    'geofence.sync_swat': 'Đồng bộ swat',
    'geofence.money_config': 'Cấu hình tiền mặt',
    'geofence.opacity': 'Độ trong suốt',
    'geofence.border_color': 'Màu đường viền',
    'geofence.opacity_border': 'Độ trong suốt đường viền',
    'geofence.operating_start_time': '(Từ)',
    'geofence.operating_end_time': '(Đến)',
    'geofence.warning.set_overlapped_plan_time': 'Bạn có muốn cập nhật geofence này không?\nGeofence này đã được tạo trong gói. Hãy setting giờ hoạt động của geofence đè lên giờ hoạt động của gói trước khi cập nhật.',
    'geofence.min_walking_distance_show': 'Min walking distance show',
    'geofence.max_walking_distance_show': 'Max walking distance show',
    'geofence.mapbox_path_segment': 'Mapbox path segment',
    'geofence.auto_renew_services': 'Auto renew services',
    'geofence.offer_estimation_timeout': 'Thời gian kết quả search hết hạn (phút)',
    'geofence.offer_estimation_timeout_invalid': 'Thời gian kết quả search hết hạn không đúng định dạng',
    'geofence.display_for_app': 'Hiển thị trên app',
    'geofence.color_in_top_map': 'Màu ở Top Map',
    'geofence.color_in_my_page': 'Màu ở Chi tiết gói',
    'geofence.geofence_preview': 'Xem trước geofence',
    'geofence.geofence_preview_top_map': 'Top map',
    'geofence.geofence_preview_my_page': 'Chi tiết gói',
    'geofence.allow_infant': 'Hiển thị trẻ sơ sinh',
    'geofence.enableForAdmin': 'Kích hoạt (Dành cho admin)',
    'geofence.settingScreenDisplay': 'Hiển thị tại màn Cài đặt',
    'geofence.historyScreenDisplay': 'Hiển thị tại màn Lịch sử',
    'geofence.lastOrderTime': 'Thời điểm nhận chuyến cuối',
    'geofence.settingLastOrderTime': 'Cài đặt thời điểm nhận chuyến cuối',

    // Service
    'service.title': 'Quản lý dịch vụ',
    'service.id': 'ID dịch vụ',
    'service.geofence_key': 'Key vùng',
    'service.transportation_type': 'Loại thanh toán',
    'service.service_mode': 'Chế độ dịch vụ',
    'service.times': 'Thời gian dịch vụ',
    'service.vehicle_group_type': 'Loại nhóm xe',
    'service.confirm_update': 'Bạn có muốn cập nhật nhóm dịch vụ này không?',
    'service.detail_title': 'Chi tiết dịch vụ',
    'service.dataset_id': 'ID tập dữ liệu',
    'service.routing_profile_id': 'ID hồ sơ định tuyến',
    'service.transit_stop_set_id': 'ID đặt điểm dừng chuyển tuyến',
    'service.walking_profile_id': 'ID hồ sơ đi bộ',
    'geofence.geometry': 'Hình học',
    'service.willerManagement': 'Willer Services Management',
    'service.enable': 'Kích hoạt',
    'service.type': 'Service Type',
    'service.notFound': 'No willer services found',
    'service.addLanguage': 'Thêm mới ngôn ngữ',
    'service.note': 'Ghi chú',
    'service.language': 'Ngôn ngữ',

    // Business Plan
    'business_plan.form': 'Biểu mẫu đăng ký người dùng',
    'business_plan.active': 'Hoạt động',
    'business_plan.invite_date': 'Ngày không hợp lệ',
    'business_plan.message_clone': 'Bạn có muốn sao chép gói đã chọn không?',
    'business_plan.id': 'ID',
    'business_plan.code': 'Mã gói',
    'business_plan.package_name': 'Tên gói',
    'business_plan.partner_id': 'Đối tác',
    'business_plan.price': 'Số tiền',
    'business_plan.unit': 'Tiền tệ',
    'business_plan.geofence': 'Khu vực',
    'business_plan.company': 'Công ty',
    'business_plan.type': 'Loại',
    'business_plan.days': 'Ngày',
    'business_plan.number_day': 'Ngày',
    'business_plan.number_of_people': 'Giới hạn người dùng gói',
    'business_plan.free_time': 'Thời gian dùng thử miễn phí',
    'business_plan.hour': 'GIỜ',
    'business_plan.day': 'NGÀY',
    'business_plan.status': 'Trạng thái',
    'business_plan.username': 'Tên người dùng',
    'business_plan.mail': 'Email',
    'business_plan.user_code': 'Mã hội viên',
    'business_plan.deposit_status': 'Trạng thái tiền gửi',
    'business_plan.operation_time': 'Thời gian hoạt động',
    'business_plan.start_time': 'Ngày giờ bắt đầu',
    'business_plan.end_time': 'Ngày giờ kết thúc',
    'business_plan.start_date': 'Ngày bắt đầu',
    'business_plan.end_date': 'Ngày kết thúc',
    'business_plan.copy': 'Đã sao chép gói.',
    'business_plan.family_option': 'Lựa chọn gói gia đình',
    'business_plan.family_option_amount': 'Tổng số tiền gói mua thêm',
    'business_plan.extra_fee': 'Số tiền bổ sung thêm 1',
    'business_plan.enable_for_landing_page': 'Hiển thị cho ứng dụng',
    'business_plan.description': 'Mô tả',
    'business_plan.main_plan': 'Gói chính',
    'business_plan.display_times': 'Thời gian hiển thị',
    'business_plan.fullname': 'Tên đầy đủ',
    'business_plan.created': 'Đã tạo',
    'business_plan.use_time': 'Thời gian sử dụng',
    'business_plan.msg_show_main': 'Đặt làm gói chính trong vùng đã chọn.',
    'business_plan.msg_show_main_create': 'Đặt gói đã tạo thành gói chính.',
    'business_plan.banking': 'Ngân hàng',
    'business_plan.registered_user': 'Số lượng đăng ký family-option',
    'business_plan.user_added': 'Số lượng liên kết family-option',
    'business_plan.date_of_payment': 'Ngày thanh toán',
    'business_plan.max_transfer_days': 'Ngày thanh toán tối đa',
    'business_plan.reminder_days': 'Ngày nhắc nhở',
    'business_plan.max_transfer_days_validate': 'Ngày chuyển khoản phải lớn hơn ngày nhắc nhở',
    'business_plan.confirm_create': 'Bạn có muốn thêm gói đăng ký này?',
    'choose.bank_account': 'Chọn tài khoản ngân hàng',
    'business_plan.bank_transfer': 'Phương thức thanh toán: Chuyển khoản ngân hàng',
    'business_plan.validate_start_time_use_time': 'Thời gian bắt đầu sử dụng phải nằm trong khoảng thời gian sử dụng của gói này',
    'business_plan.validate_amount': 'Vui lòng nhập số tiền vào vị trí {{position}}',
    'business_plan.validate_extra_fee': 'Vui lòng nhạp số tiền bổ sung vào vị trí {{position}}',
    'transfer.not_transfer': 'CHƯA CHUYỂN KHOẢN',
    'transfer.transfer': 'ĐÃ CHUYỂN KHOẢN',
    'transfer.cancel': 'HỦY',
    'transfer.refuned': 'HOÀN TIỀN',
    'business_plan.addCompany': 'Thêm công ty',
    'business_plan.add_company_confirm': 'Bạn có muốn thêm những công ty này vào Plan không?',
    'business_plan.category': 'Danh mục gói',
    'business_plan.unlimited_rides': 'Chuyến đi không giới hạn',
    'business_plan.ride_ticket': 'Vé đi xe',
    'business_plan.operation_information': 'Thông tin hoạt động',
    'business_plan.additional_information': 'Thông tin thêm',
    'business_plan.applicable_object': 'Đối tượng áp dụng',
    'business_plan.show_promotion_app': 'Hiển thị thông tin khuyến trên app',
    'business_plan.promotion_title': 'Tiêu đề khuyến mại',
    'business_plan.promotion_description': 'Nội dung khuyến mại',
    'business_plan.one_time_discount': 'Giảm giá người đi cùng',
    'business_plan.list_detail': 'Chi tiết giảm giá',
    'business_plan.number_of_user': 'Số lượng người dùng',
    'business_plan.one_time_discount_icon': 'Biểu tượng giảm giá',
    'business_plan.icon': 'Ảnh',
    'business_plan.one_time_discount_description': 'Nội dung giảm giá',
    'business_plan.validate_time': 'Thời gian hoạt động của gói phải đè lên thời gian hoạt động của tất cả các geofence đã chọn ',
    'business_plan.error_referral_time': 'Gói đã được thiết lập cho Mã giới thiệu',
    'business_plan.validate_user': 'Hãy cài đặt Đối tượng áp dụng > 0 cho ít nhất 1 geofence',

    'ticket.name': 'Tên vé',
    'ticket.add': 'Phát vé',
    'ticket.type': 'Loại vé',
    'ticket.all_user': 'Tất cả người dùng',
    'ticket.never_used_mobi': 'Chưa bao giờ sử dụng mobi',
    'ticket.no_plan': 'Không có gói',
    'ticket.withdraw': 'Đã rút',
    'ticket.registration_period': 'Thời gian đăng ký',
    'ticket.period': 'Thời hạn vé',
    'ticket.adult': 'Người lớn',
    'ticket.child': 'Trẻ em',
    'ticket.discount.adult': 'Giảm giá cho người lớn',
    'ticket.discount.child': 'Giảm giá cho trẻ em',
    'ticket.ride_limit': 'Giới hạn đi xe',
    'ticket.amount_type': 'Đối tượng',
    'ticket.code': 'Mã vé',
    'ticket.days': 'Ngày',
    'ticket.automatic_update': 'Tự động gia hạn',
    'ticket.additional_purchase': 'Số lượt mua thêm',
    'ticket.confirm_register': 'Bạn có chắc chắn muốn tạo vé này không?',
    'ticket.confirm_update': 'Bạn có chắc chắn muốn cập nhật vé này không?',
    'ticket.confirm_delete': 'Bạn có chắc chắn muốn xóa vé này không?',
    'ticket.confirm_clone': 'Bạn có chắc chắn muốn tạo bản sao của vé này không?',
    'ticket.add_ticket_for_member': 'Thêm vé cho thành viên',
    'ticket.confirm_add_ticket': 'Bạn có chắc chắn muốn phát vé cho {{count}} thành viên?',
    'ticket.validate_ride_limit': 'Vui lòng nhập giới hạn đi xe vào vị trí {{position}}',
    'ticket.ride': 'lượt đi',
    'ticket.user_ticket_id': 'ID vé người dùng',
    'ticket.registration_date': 'Ngày đăng ký',
    'ticket.total_ride_limit': 'Tổng số lượt đi',
    'ticket.used_count': 'Số lượt đi đã sử dụng',
    'ticket.remain_count': 'Số lượt đi còn lại',
    'ticket.validate_start_date_time': 'Ngày và giờ bắt đầu phải trong khoảng thời gian sử dụng',
    'ticket.start_date_time': 'Ngày bắt đầu sử dụng',
    'ticket.additional_purchase_ride_ticket': 'Mua thêm lượt',
    'ticket.history_additional': 'Lịch sử mua lượt',
    'ticket.history_additional_purchase': 'Lịch sử mua thêm lượt',
    'ticket.payment_code': 'Mã thanh toán',
    'ticket.state': 'Trạng thái',
    'ticket.created_date': 'Ngày tạo',
    'ticket.created_by': 'Người tạo',
    'ticket.additional': 'Thêm',
    'ticket.select_additional_purchase': 'Chọn số lượng mua thêm',
    'ticket.confirm_select_additional_purchase': 'Bạn có muốn mua thêm {{count}} lượt ({{amount}}) không?',
    'ticket.no_additional_purchase': 'Không có dữ liệu mua bổ sung',
    'ticket.available_ride': 'Có sẵn {{count}} lượt',
    'ticket.suggest_rides': 'gợi ý số chuyến đi',
    'ticket.add_credit_card': 'Vui lòng thêm thông tin thẻ tín dụng vào tài khoản của bạn',
    'ticket.member_list_title': 'Hội viên từ CSV',
    'ticket.csv_success': 'Nhập CSV thành công',
    'ticket.csv_failed': 'Nhập CSV không thành công',
    'ticket.member_code_incorrect': 'ID thành viên không đúng',
    // eslint-disable-next-line no-template-curly-in-string, max-len
    'ticket.different_discount_amount': 'Hội viên này đã có ticket giảm giá cho người đi cùng với mức giảm giá khác cho geofence A\n (ID mua: {{Subscription_ID}},  Người lớn: {{Adult}}, Trẻ em: {{Child}})',
    'ticket.sub.different_discount_amount_header': 'Hội viên này đã có ticket giảm giá cho người đi cùng với mức giảm giá khác cho cùng  một geofence',
    'ticket.sub.different_discount_amount_end': '(Geofence {{GeofenceName}}, ID mua: {{Id}} ,  Người lớn: {{Adult}}, Trẻ em: {{Child}})',
    'ticket.different_discount_amount_header': 'Hội viên này đã có ticket giảm giá cho người đi cùng với mức giảm giá khác cho cùng một geofence:',
    'ticket.different_discount_amount_end': '(Geofence: {{Geofence}}, ID mua: {{Subscription_ID}}, Người lớn: {{Adult}}, Trẻ em: {{Child}})',
    'ticket.for_gift_only': 'Chỉ dùng để tặng',
    'ticket.ticket_name_gift': 'Vé quà tặng',
    'ticket.adult_ticket': 'Vé người lớn',
    'ticket.child_ticket': 'Vé trẻ em',
    'ticket.distribution_date': 'Ngày phát vé',
    'ticket.use_ride_ticket': 'Thông tin sử dụng vé',
    'ticket.add_csv_success': 'Phát vé thành công \n Tổng số bản ghi: {{count}} thành viên',
    'mass.booking.cannot.use.ticket': 'Không thể sử dụng vé lượt này',
    'ticket.validate_ticket_distribution_date': 'Ngày và giờ bắt đầu phải trong khoảng thời gian sử dụng',
    'ticket.ticket_distribution_date_note': 'Vui lòng lưu ý múi giờ khi phát vé cho từng quốc gia.',
    'ticket.ticket_distribution_date_note_time_range': 'Hãy điền Ngày phát vé vào khoảng 00:31 ~ 23:29',

    // ticket distribution history
    'ticket.history.distributed': 'Đã phát hành',
    'ticket.history.undistributed': 'Chưa phát hành',
    'ticket.history.distributed.with.error': 'Đã phát hành một phần',
    'ticket.history.messageConfirm': 'Bạn có muốn hủy lịch phát vé không ?',
    'ticket.history.messageConfirm.totalRecord': 'Tổng số thành viên: {{count}} thành viên',
    'ticket.history.totalMember': 'Tổng số thành viên',
    'ticket.history.listMember': 'Danh sách thành viên',
    'ticket.history.searchMember': 'Mã thành viên / Tên thành viên',
    'ticket.history.distributionStatus': 'Tình trạng phát vé',
    'ticket.history.distributionFailReason': 'Lí do phát vé thất bại',
    'ticket.history.success': 'Thành công',
    'ticket.history.fail': 'Thất bại',

    // Subscriptions
    'subscription.create_modal': 'Bạn có muốn thêm gói đăng ký của bạn?',
    'subscription.update_modal': 'Bạn có muốn cập nhật gói đăng ký của bạn?',
    'subscription.update_memo_modal': 'Bạn có muốn cập nhật ghi chú đăng ký của bạn?',
    'sub.last_name': 'Họ',
    'sub.first_name': 'Tên',
    'sub.firstname_furigana': 'Tên furigana',
    'sub.lastname_furigana': 'Họ furigana',
    'sub.has_family_options': 'Có lựa chọn gia đình',
    'sub.effective_date': 'Ngày mua gói đăng ký',
    'sub.start_date': 'Ngày bắt đầu gói đăng ký',
    'sub.next_update_date': 'Ngày cập nhật gói đăng ký tới',
    'sub.end_date': 'Ngày kết thúc gói đăng ký',
    'sub.acceptance_date': 'Ngày hủy',
    'sub.effective_date_from': 'Từ',
    'sub.effective_date_to': 'Đến',
    'sub.plan_cost': 'Chi phí Plan',
    'sub.payment_id': 'ID Thanh toán',
    'sub.subscription_id': 'ID mua',
    'sub.price_sub': 'Phí gói đăng ký',
    'sub.plan_fee': 'Giá gói',
    'sub.price_family_option': 'Giá gói gia đình',
    'sub.total_price': 'Tổng giá',
    'sub.enable_disable': 'Bạn có muốn kích hoạt hoặc hủy kích hoạt gói đăng ký này?',
    'sub.date_bank': 'Ngày chuyển khoản',
    'sub.register_sub': 'Thêm gói đăng ký',
    'sub.register_success': 'Đăng ký gói thành công',
    'sub.add_family_confirm': 'Bạn có muốn thêm những thành viên này vào gói?',
    'sub.delete_family_user': 'Bạn có muốn xóa thành viên này không?',
    'sub.enable_main': 'Bạn có muốn vô hiệu hóa không?',
    'sub.disable_main': 'Bạn có muốn kích hoạt không?',
    'sub.number_member': 'Số người',
    'sub.start_time': 'Thời gian bắt đầu',
    'sub.family_option_amount': 'Tổng số tiền gói mua thêm',
    'sub.total_amount': 'Tổng số tiền',
    'sub.reminder_days': 'Ngày nhắc nhở',
    'sub.automatic_update': 'Tự động gia hạn',
    'sub.enable_auto_update': 'Bật tự động gia hạn',
    'sub.disable_auto_update': 'Tắt tự động gia hạn',
    'sub.enable_renewable_confirm': 'Bạn có muốn bật gia hạn tự động không?',
    'sub.disable_renewable_confirm': 'Bạn có muốn tắt gia hạn tự động không?',
    'sub.true': 'ĐÚNG',
    'sub.false': 'SAI',
    'sub.status': 'Trạng Thái',
    'sub.active_status': 'Trạng thái hoạt động',
    'sub.free_trial_start': 'Ngày giờ bắt đầu dùng thử',
    'sub.free_trial_end': 'Ngày giờ kết thúc dùng thử',
    'sub.number_family': 'Tổng số thành viên (Bao gồm cả chủ tài khoản)',
    'sub.number_family_added': 'Số thành viên đã thêm (Bao gồm cả chủ tài khoản)',
    'sub.continue_free_trial': 'Tiếp tục dùng thử miễn phí',
    'sub.canceled_by': 'Người Hủy',
    'sub.canceled_time': 'Thời gian hủy',
    'sub.family_member_code': 'ID thành viên',
    'sub.confirm_resend_mail': 'Bạn có muốn gửi lại mail không?',
    'sub.confirm_remove_slot': 'Bạn có muốn xóa vị trí này không?',
    'sub.not_payment': 'KHÔNG THANH TOÁN',
    'sub.extended': 'MỞ RỘNG',
    'sub.renewal_failed': 'GIA HẠN THẤT ​​BẠI',
    'sub.expired': 'HẾT HẠN',
    'sub.batch': 'Batch',
    'sub.sent_mails': 'Danh sách thư đã gửi',
    'sub.title': 'Tiêu đề',
    'sub.content': 'Nội dung',
    'sub.type': 'Loại',
    'sub.remove_slot': 'Xóa chỗ',
    'sub.add_slot': 'Thêm chỗ',
    'sub.add_member': 'Thêm thành viên',
    'sub.select_slot': 'Tổng {{number}} người (+{{count}} người)',
    'sub.confirm_add_slot': 'Bạn có chắc chắn muốn thêm {{count}} chỗ vào gói gia đình với tổng phụ phí là {{amount}} không?',
    'sub.additional_slot': 'Chỗ bổ sung',
    'sub.maximum_slot': 'Số chỗ trong gói đã đạt đến mức tối đa',
    'sub.total_extra_fee': 'Tổng phụ phí',
    'sub.confirm_change_payment_method': 'Bạn có chắc chắn muốn thay đổi phương thức thanh toán không?',
    'sub.has_one_time_discount': 'Giảm giá người đi cùng',
    'sub.applicable_user': 'Đối tượng áp dụng \n (※Số người được giảm giá trên mỗi vé)',
    'sub.amount_discount': 'Số tiền sau khi giảm giá',
    'sub.validate.max_child': 'Số tiền sau khi giảm giá của trẻ em phải nhỏ hơn giá tiền thanh toán một lần',
    'sub.validate.max_adult': 'Số tiền sau khi giảm giá của người lớn phải nhỏ hơn giá tiền thanh toán một lần',
    'sub.validate.compare_max_child_adult': 'Số tiền sau khi giảm giá của người lớn hoặc trẻ em phải nhỏ hơn giá tiền thanh toán một lần',
    'sub.applicable_users': 'Đối tượng áp dụng',
    'sub.extended_sub_notification': 'Đây là gói vé được chuyển lượt từ một gói vé khác (gói vé ban đầu: ',

    // Bank
    'bank.account_name': 'Tên tài khoản',
    'bank.account_number': 'Số tài khoản',
    'bank.bank_name': 'Tên ngân hàng',
    'bank.transfer_content': 'Nội dung chuyển khoản',

    // History Sub
    'history.state': 'Trạng Thái',
    'history.payment_code': 'Mã thanh toán',
    'history.created_date': 'Ngày tạo',

    // Popup Recommend
    'popup_recommend.header': 'Tiêu đề',
    'popup_recommend.title': 'Tiêu đề',
    'popup_recommend.description': 'Mô tả',
    'popup_recommend.service_type': 'Loại dịch vụ',
    'popup_recommend.recommend_type': 'Loại đề xuất',
    'popup_recommend.user_id': 'ID Người dùng',
    'popup_recommend.recommend_id': 'Id đề xuất',

    'recommend_type.not_used': 'KHÔNG ĐƯỢC SỬ DỤNG',
    'recommend_type.one_time': 'MỘT LẦN',
    'recommend_type.sub': 'SUB KHÔNG CÓ GIA ĐÌNH',
    'recommend_type.not_sub': 'KHÔNG CÓ SUB',
    'recommend_type.trial': 'KHÔNG ĐĂNG KÝ DÙNG THỬ',
    'recommend_type.one_time_trial': 'KHÔNG SUB DÙNG THỬ',
    'recommend_type.not_sub_trial': 'NOT SUB TRIAL',

    // News Management
    'news_management.title': 'Tin tức',
    'news_management.form_title': 'Mẫu tin tức',
    'news_management.key_word': 'Từ khóa',
    'news_management.note': 'Hiển thị thời gian (mobile)',
    'news_management.active_status': 'Active status',
    'news_management.active': 'Active',
    'news_management.inactive': 'Inactive',
    'news_management.delete': 'Delete',
    'news_management.display_time': 'Thời gian hiển thị',
    'news_management.create_time': 'Thời gian tạo',
    'news_management.all_geofence': 'Tất cả các giới hạn địa lý hoạt động',
    'news_management.time_out': 'Hết giờ',
    'news_management.valid_multiple_languages': 'Vui lòng chọn ít nhất một ngôn ngữ và nhập đủ thông tin hợp lệ',
    'news_management.display_method': 'Phương thức hiển thị',
    'news_management.template_list': 'Cài đặt template',
    'news_management.serious_error': 'Thông báo chướng ngại vật, tai nạn ( pop-up màu đỏ)',
    'news_management.announcement': 'Khôi phục và các thông báo quan trọng ( với cửa sổ bật lên màu xanh lá cây)',
    'news_management.promotion_inform': 'Thông báo như thông tin chiến dịch (hiển thị thông báo khi ứng dụng khởi động)',
    'news_management.news_inform': 'Thông báo thông tin bình thường (chỉ mô tả trong cột thông báo)',
    'news_management.display_on': 'Hiển thị',
    'news_management.push_notification': 'Thông báo đẩy',
    'news_management.using_template': 'Sử dụng template',
    'news_management.yes': 'Sử dụng',
    'news_management.no': 'Không sử dụng',
    'news_management.messageCode.createConfirm': 'Bạn có muốn đăng kí tin tức này không?',
    'news_management.messageCode.updateConfirm': 'Bạn có muốn cập nhật Tin tức không?',
    'news_management.push_notification_description': '* Hãy đặt Thời gian hiển thị (Từ) là hôm nay để cài đặt thông báo đẩy',

    // Template Management
    'template_management.list': 'Danh sách template',
    'template_management.name': 'Tên template',
    'template_management.title': 'Tiêu đề',
    'template_management.content': 'Nội dung',
    'template_management.read_more': 'Xem thêm',
    'template_management.read_less': 'Rút gọn',
    'template_management.template': 'template',
    'template_management.template_form': 'Mẫu template',
    'template_management.language': 'Ngôn ngữ',
    'template_management.lowercase_language': 'ngôn ngữ',
    'template_management.registerConfirm': 'Bạn có muốn đăng kí template này không?',

    // Message API Popup
    'could.update.recommend': 'Không thể cập nhật đề xuất',
    'recommend.not.exist': 'Khuyến nghị không tồn tại',
    'recommend.exist': 'Đề xuất tồn tại',
    'recommend.question.confirm.update': 'Đề xuất cập nhật xác nhận câu hỏi',
    'recommend.question.confirm.create': 'Đề xuất câu hỏi xác nhận tạo',
    'recommend.question.confirm.delete': 'Đề xuất câu hỏi xác nhận xóa',
    'over.max.slide.per.country.allowed': 'Vượt quá số lượng trang trình bày tối đa cho phép ở mỗi quốc gia',
    'slide.not.exist': 'Trang trình bày không tồn tại',
    'api.create.slide.success': 'Đăng ký hình ảnh thành công',
    'api.update.slide.success': 'Cập nhật hình ảnh thành công',
    'api.delete.slide.success': 'Xóa trang trình bày thành công',

    // Menu App Management
    'menu_app.titleManagement': 'Quản lý Menu Ứng dụng',
    'menu_app.titleForm': 'Biểu mẫu Menu ứng dụng',
    'menu_app.titleSearch': 'Điều kiệm tìm kiếm',
    'menu_app.title': 'Tiêu đề',
    'menu_app.link': 'Đường dẫn',
    'menu_app.order': 'Trật tự',
    'menu_app.action': 'Thao tác',
    'menu_app.image_master_url': 'Ảnh',
    'menu_app.country_setting': 'Cài đặt cho Quốc gia',
    'menu_app.geofence_setting': 'Cài đặt cho Geofence',
    'menu_app.displaying': 'ĐANG HIỂN THỊ',
    'menu_app.not_displaying': 'KHÔNG HIỂN THỊ',
    'menu_app.add_image_information': 'Vui lòng thêm thông tin hình ảnh',
    'menu_app.register_image_success': 'Đăng ký hình ảnh thành công',
    'menu_app.update_image_success': 'Cập nhật hình ảnh thành công',
    'menu_app.display': 'Hiên thị',

    // Area Geofence
    'area_geofence.form': 'Biểu mẫu vùng',
    'area_geofence.detailTitle': 'Chi tiết vùng',
    'area_geofence.createTitle': 'Tạo mới vùng',
    'area_geofence.question.confirm.create': 'Bạn có muốn tạo mới vùng không ?',
    'area_geofence.question.confirm.update': 'Bạn có muốn thay đổi thông tin vùng không ?',
    'area_geofence.question.confirm.delete': 'Bạn có muốn xóa vùng không ?',
    'area_geofence.center_point': 'Tọa độ trung tâm',
    'area_geofence.geofence': 'Khu vực',
    'area_geofence.area': 'Vùng',

    // Stop Master
    'stop_master.managementTitle': 'Quản lý điểm dừng',
    'stop_master.stopSetData': 'Dữ liệu điểm dừng chuyển tuyến',
    'stop_master.stopSet': 'Điểm dừng chuyển tuyến',
    'stop_master.detailTitle': 'Chi tiết điểm dừng',
    'stop_master.createTitle': 'Tạo mới điểm dừng',
    'stop_master.titleForm': 'Biểu mẫu điểm dừng',
    'stop_master.code': 'Mã',
    'stop_master.work': 'Điểm Work',
    'stop_master.description': 'Mô tả',
    'stop_master.hotline': 'Đường dây nóng',
    'stop_master.ref_link': 'Liên kết giới thiệu',
    'stop_master.street_name': 'Tên đường',
    'stop_master.type': 'Loại điểm dừng',
    'stop_master.stop_sets': 'Stop Sets',
    'stop_master.name_translations': 'Tên bản dịch',
    'stop_master.street_translations': 'Bản dịch đường phố',
    'stop_master.question.confirm.update': 'Bạn có muốn thay đổi thông tin điểm dừng không ?',
    'stop_master.question.confirm.delete': 'Bạn có muốn xóa điểm dừng không ?',
    'stop_master.question.confirm.create': 'Bạn có muốm tạo mới điểm dừng không ?',

    //  Company Management
    'company.name': 'Tên công ty',
    'company.usageStatus': 'Trạng thái',
    'company.code': 'Mã công ty',
    'company.id': 'ID',
    'company.form': 'Mẫu công ty',
    'company.registration': 'Đăng ký công ty',
    'company.update': 'Chi tiết công ty',
    'company.createdBy': 'Người tạo',
    'company.userList': 'Danh sách thành viên',
    'company.addMember': 'Thêm thành viên',
    'company.deleteConfirmMember': 'Bạn có muốn xóa thành viên này không?',
    'company.add_members_confirm': 'Bạn có muốn thêm những thành viên này vào công ty?',
    'company.memberCsvImport': 'Nhập CSV',
    'company.errorUsers': 'Thành viên lỗi',
    'company.note': 'Ghi chú',
    'company.errorMemberList': 'Danh sách thành viên lỗi',
    'company.successMemberList': 'Xem lại danh sách thành viên',
    'company.message.errorImport': 'Mã thành viên hoặc kiểu đang không đúng.',
    'company.type': 'Kiểu',
    'company.import.success': 'Nhập CSV thành công!',
    'company.message.total': 'Tổng',
    'company.message.add': 'Thêm',
    'company.message.delete': 'Xóa',
    'company.message.errorAdded': 'Thành viên đã được thêm vào danh sách',

    // QR code
    'qr_code.managementTitle': 'Quản lý mã QR',
    'qr_code.detailTitle': 'Chi tiết mã QR',
    'qr_code.historyTitle': 'Lịch sử sử dụng mã QR',
    'qr_code.createTitle': 'Tạo mới mã QR',
    'qr_code.titleForm': 'Biểu mẫu mã QR',
    'qr_code.owner': 'Người tạo',
    'qr_code.partner': 'Đối tác / Tên công ty',
    'qr_code.name': 'Tên mã QR',
    'qr_code.usage_date': 'Thời gian bắt đầu / kết thúc sử dụng',
    'qr_code.usage_start': 'Bắt đầu sử dụng',
    'qr_code.usage_end': 'Kết thúc sử dụng',
    'qr_code.usage_limit': 'Giới hạn sử dụng',
    'qr_code.question.confirm.update': 'Bạn có muốn thay đổi thông tin mã QR?',
    'qr_code.question.confirm.delete': 'Bạn có muốn xóa mã QR?',
    'qr_code.question.confirm.create': 'Bạn có muốn tạo mới mã QR?',
    'qr_code.category': 'Thể loại',
    'qr_code.member_list': 'Danh sách thành viên',
    'qr_code.all_member': 'Áp dụng cho tất cả thành viên',
    'qr_code.total_quantity': 'Tổng số lượng',
    'qr_code.total_usage_count': 'Tổng số lượng sử dụng',
    'qr_code.total_remain_count': 'Tổng số còn lại',
    'qr_code.remain_count': 'Số lượng còn lại',
    'qr_code.usage_count': 'Số lượng sử dụng',
    'qr_code.place': 'Địa điểm',

    'owner.type.partner': 'Đối tác',
    'owner.type.company': 'Công ty',

    'ticket.departure_station': 'Ga khởi hành',
    'ticket.arrival_station': 'Ga đến',
    'ticket.ticket_type': 'Loại vé',
    'ticket.commuter_pass_type': 'Loại vé tháng',
    'ticket.coupon_ticket_type': 'Loại vé lượt',
    'ticket.ticket_use_type': 'Loại vé sử dụng',
    'ticket.start_date_of_use': 'Ngày hiệu lực',
    'ticket.end_date_of_use': 'Ngày hết hiệu lực',
    'ticket.payment_method': 'Phương thức thanh toán',
    'ticket.search_route': 'Tìm chuyến',
    'ticket.route_information': 'Thông tin chuyến',
    'ticket.credit_card': 'Thẻ tín dụng',
    'ticket.buy_ticket': 'Mua vé',
    'ticket.transfer_station': 'Ga trung chuyển',
    'ticket.route': 'Chuyến',
    'ticket.transfer': 'Trung chuyển',
    'ticket.fare': 'Phí',
    'ticket.time': 'Lần',
    'ticket.commuter_pass': 'Vé tháng',
    'ticket.coupon_ticket': 'Vé lượt',
    'ticket.confirm': 'Xác nhận',
    'ticket.titleSearch': 'Vé QR đã mua',
    'ticket.already.exists': 'Vé đã tồn tại',
    'ticket.amount': 'Giá',
    'ticket.notification_1': 'Vé lượt của bạn chưa hết hạn bạn không thêr mua thêm bất kỳ loại vé nào',
    'ticket.notification_2': 'Vé tháng của bạn vẫn còn hạn hơn 14 ngày nên bạn không thể mua bất kỳ loại vé nào',
    'ticket.notification_3': 'Vé tháng của bạn có hiệu lực đến {{date}}. Hãy chọn ngày bắt đầu sau ngày hết hiệu lực',

    // QR Ticket
    'qrTicket.title': 'Vé tàu của người sử dụng',
    'qrTicket.searchCondition': 'Điều kiện tìm kiếm',
    'qrTicket.detailTitle': 'Chi tiết vé tàu của người sử dụng',
    'qrTicket.detailForm': 'Biểu mẫu vé tàu của người sử dụng',
    'qrTicket.country': 'Quốc gia',
    'qrTicket.businessName': 'Tên nhà cung cấp',
    'qrTicket.memberCode': 'ID thành viên',
    'qrTicket.username': 'Tên người sử dụng',
    'qrTicket.fullName': 'Tên đầy đủ',
    'qrTicket.phoneNumber': 'Số điện thoại',
    'qrTicket.mail': 'Thư điện tử',
    'qrTicket.ticketType': 'Loại vé',
    'qrTicket.couponTicketType': 'Loại vé lượt',
    'qrTicket.commuterPassType': 'Loại vé tháng',
    'qrTicket.userTicketId': 'ID vé của người sử dụng',
    'qrTicket.amount': 'Số tiền',
    'qrTicket.rideLimit': 'Tổng số lượt đi',
    'qrTicket.paymentMethod': 'Phương thức thanh toán',
    'qrTicket.paymentId': 'ID thanh toán',
    'qrTicket.registrationDate': 'Ngày đăng ký',
    'qrTicket.startDate': 'Ngày bắt đầu',
    'qrTicket.endDate': 'Ngày kết thúc',
    'qrTicket.status': 'Trạng thái',
    'qrTicket.currency': 'Đơn vị tiền tệ',
    'qrTicket.usedCount': 'Đã sử dụng',
    'qrTicket.remainCount': 'Còn lại',
    'qrTicket.departureStation': 'Ga khởi hành',
    'qrTicket.arrivalStation': 'Ga đến',
    'qrTicket.routeName': 'Trung chuyển',
    'qrTicket.disable': 'Vô hiệu hoá',
    'qrTicket.credit_card': 'Thẻ tín dụng',
    'qrTicket.number': 'Số thứ tự',
    'qrTicket.departureDate': 'Ngày khởi hành',
    'qrTicket.arrivalDate': 'Ngày đến',
    'qrTicket.onetimeAmount': 'Số tiền một lượt',
    'qrTicket.detailModalTitle': 'Lịch sử sử dụng vé lượt',
    'qrTicket.disableMessage': 'Bạn có chắc chắn vô hiệu hoá vé này không?',
    'qrTicket.complete': 'Hoàn thành',
    'qrTicket.onTrain': 'Trên tàu',
    'qrTicket.canceled': 'Hủy',
    'qrTicket.completedCollection': 'Soát vé',
    'qrTicket.noUseCoupon': 'Không sử dụng vé lượt',

    // Coupon Management
    'coupon.couponCode': 'Mã phiếu mua hàng',
    'coupon.shopName': 'Tên cửa hàng',
    'coupon.shopNameJp': 'Tên cửa hàng (JP)',
    'coupon.shopNameEn': 'Tên cửa hàng (EN)',
    'coupon.shopNameVn': 'Tên cửa hàng (VN)',
    'coupon.couponName': 'Tên phiếu mua hàng',
    'coupon.couponNameJp': 'Tên phiếu mua hàng (JP)',
    'coupon.couponNameEn': 'Tên phiếu mua hàng (EN)',
    'coupon.couponNameVn': 'Tên phiếu mua hàng (VN)',
    'coupon.couponType': 'Loại phiếu mua hàng',
    'coupon.publishedDate': 'Ngày phát hành',
    'coupon.useTime': 'Thời hạn sử dụng',
    'coupon.createdDate': 'Ngày tạo',
    'coupon.useTimeStart': 'Thời hạn sử dụng (Ngày bắt đầu)',
    'coupon.useTimeEnd': 'Thời hạn sử dụng (Ngày kết thúc)',
    'coupon.nearestWaypoint': 'Điểm xuống xe gần nhất',
    'coupon.nearestWaypointJp': 'Điểm xuống xe gần nhất (JP)',
    'coupon.nearestWaypointEn': 'Điểm xuống xe gần nhất (EN)',
    'coupon.nearestWaypointVn': 'Điểm xuống xe gần nhất (VN)',
    'coupon.displayOnApp': 'Hiển thị trên ứng dụng',
    'coupon.csvExportCoupon': 'Danh sách phiếu',
    'coupon.csvExportCouponUsage': 'Tình trạng sử dụng phiếu',
    'coupon.image': 'Hình ảnh phiếu mua hàng',
    'coupon.discountContent': 'Nội dung giảm giá',
    'coupon.description': 'Mô tả',
    'coupon.oneTime': 'Một lần',
    'coupon.unlimited': 'Không giới hạn',
    'coupon.couponRegister': 'Đăng ký phiếu mua hàng',
    'coupon.couponDetail': 'Chi tiết phiếu mua hàng',
    'coupon.displayStatus': 'Trạng thái hiển thị',
    'coupon.imageNotice': 'Kích thước tiêu chuẩn của ảnh là 4x3',

    // Auto bus booking
    'auto_bus_booking.titleInformation': 'Thông tin chuyến buýt tự động',
    'auto_bus_booking.title': 'Chuyến buýt tự động',
    'auto_bus_booking.form': 'Mẫu đăng ký chuyến buýt tự động',
    'auto_bus_booking.register': 'Đăng ký chuyến buýt tự động',
    'auto_bus_booking.saleOffice': 'Sales Office/Store Name',
    'auto_bus_booking.driverCodeAndName': 'Mã tài xế - Tên tài xế',
    'auto_bus_booking.driverCode': 'Mã tài xế',
    'auto_bus_booking.driverName': 'Tên tài xế',
    'auto_bus_booking.userCode': 'Mã người dùng',
    'auto_bus_booking.pickUpWaypoint': 'Điểm đón',
    'auto_bus_booking.dropOffWaypoint': 'Điểm trả',
    'auto_bus_booking.pickupDropoffDuplicated': 'Điểm đón và điểm trả cần khác nhau',
    'auto_bus_booking.bookingInformation': 'Thông tin chuyến buýt',
    'auto_bus_booking.operationDate': 'Ngày vận hành',
    'auto_bus_booking.routeName': 'Tên tuyến',
    'auto_bus_booking.operationHours': 'Giờ vận hành',
    'auto_bus_booking.overlapedTime': 'Tài xế đã bị trùng lịch trình tuyến đường vào ngày đã chọn.',
    'auto_bus_booking.pickupTime': 'Thời gian đón',
    'auto_bus_booking.dropoffTime': 'Thời gian trả',
    'auto_bus_booking.pickUpWaypointAndTime': 'Điểm đón - thời gian đón',
    'auto_bus_booking.dropOffWaypointAndTime': 'Điểm trả - thời gian trả',
  },
};

export default vi;
